import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'

import { DropzoneArea } from 'material-ui-dropzone'

import { actionCreators } from "../../../store/Money";

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Paper,
    Typography,
    FormControl,
    Input,
    InputAdornment,
    LinearProgress,
    IconButton
} from '@material-ui/core'
import { Fragment } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import swal from 'sweetalert'

import Uploader from '../../Shared/Uploader'

const useStyles = makeStyles({
    paper: {
        padding: 10
    },
    rtlInput: {
        textAlign: 'right'
    }
});

export function Money({ fetchUIData, accountingMoney, adjustMoney }) {
    const classes = useStyles();
    const [adjusts, setAdjusts] = useState([{ name: '', amount: '' }, { name: '', amount: '' }]);
    const [remark, setRemark] = useState('');
    const [file, setFile] = useState(null)

    const handleChangeAdjust = (i, name) => (e) => {
        setAdjusts([...adjusts.slice(0, i), { ...adjusts[i], [name]: e.target.value }, ...adjusts.slice(i + 1)])
    }

    const handleChangeRemark = (e) => {
        setRemark(e.target.value)
    }

    const init = () => {
        fetchUIData()
        setAdjusts([{ name: '', amount: '' }])
        setRemark('')
        setFile(null)
    }

    useEffect(() => {
        init();
    }, [])


    const handleAddMore = () => setAdjusts([...adjusts, { name: '', amount: '' }])
    const handleDeleteRow = (i) => setAdjusts([...adjusts.slice(0, i), ...adjusts.slice(i + 1)])



    const handleSumit = async () => {
        const records = adjusts.filter(x => x.amount > 0)
        if (records.length == 0) {
            swal('','กรุณากรอกข้อมูล','warning')
            return;
        }
        let response = await adjustMoney(remark, records, file)
        if (response.data.success) {
            swal('', 'บันทึกสำเร็จ', 'success')
            init()
        }
    }

    const handleSelectFile = (e) => {
        if (e.target.files.length)
            setFile(e.target.files[0])
        else setFile(null);
    }

    const formatMoney = (x) => x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

    if (accountingMoney.conditionUIData.isLoading)
        return <LinearProgress />;


    const { yesterdayMoney, todayMoney } = accountingMoney.conditionUIData.data;

    const totalAdjust = adjusts.map(x => x.amount)
        .reduce((x, y) => (parseFloat(x) || 0) + (parseFloat(y) || 0), 0) || 0

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid sm={12} container>
                <Grid sm={6}>
                    <Paper className={classes.paper} fullWidth>
                        <Typography variant="h5">เงินสดย่อย</Typography>
                        <Grid container>
                            <Grid sm={8}>
                                ยอดยกมา
                            </Grid>
                            <Grid sm={4}>
                                <FormControl>
                                    <Input
                                        value={formatMoney(yesterdayMoney)}
                                        endAdornment={<InputAdornment position="end">บาท</InputAdornment>}
                                        disabled
                                        inputProps={{ className: classes.rtlInput }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sm={8}>
                                รับเงินโอน
                            </Grid>
                            <Grid sm={4}>
                                <Input
                                    value={formatMoney(todayMoney)}
                                    endAdornment={<InputAdornment position="end">บาท</InputAdornment>}
                                    disabled
                                    inputProps={{ className: classes.rtlInput }}
                                />
                            </Grid>
                            <Grid sm={12}>
                                ค่าใช้จ่าย
                            </Grid>
                            {adjusts.map((x, i) => (<Fragment key={i}><Grid sm={8}>
                                <Input
                                    value={x.name}
                                    onChange={handleChangeAdjust(i, 'name')}
                                    startAdornment={<InputAdornment position="start">{i + 1}.</InputAdornment>}
                                    endAdornment={adjusts.length !== 1 ?
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleDeleteRow(i)}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        : null
                                    }
                                    fullWidth
                                />
                            </Grid>
                                <Grid sm={4}>
                                    <Input
                                        value={x.amount}
                                        onChange={handleChangeAdjust(i, 'amount')}
                                        endAdornment={<InputAdornment position="end">บาท</InputAdornment>}
                                        inputProps={{ className: classes.rtlInput }}
                                        onBlur={() => { handleChangeAdjust(i, 'amount')({ target: { value: parseFloat(x.amount) || '' } }) }}
                                    />
                                </Grid></Fragment>))}
                            <Grid sm={12}>
                                <Button variant="text" onClick={handleAddMore}>+ เพิ่มรายการ</Button>
                            </Grid>
                            <Grid sm={8}>
                                เงินสดย่อยเหลือ
                            </Grid>
                            <Grid sm={4}>
                                <Input
                                    value={formatMoney(
                                        accountingMoney.conditionUIData.data.yesterdayMoney
                                        + accountingMoney.conditionUIData.data.todayMoney
                                        - totalAdjust
                                    )}
                                    endAdornment={<InputAdornment position="end">บาท</InputAdornment>}
                                    disabled
                                    inputProps={{ className: classes.rtlInput }}
                                />
                            </Grid>
                            <Grid sm={12} container>
                                <Grid sm={2}>
                                    หมายเหตุ
                                </Grid>
                                <Grid sm={10}>
                                    <Input value={remark} onChange={handleChangeRemark} fullWidth />
                                </Grid>
                            </Grid>
                            <Grid sm={12}>
                                แนบบิลซื้อ
                            </Grid>
                            <Grid sm={12}>
                                <Input type="file" onChange={handleSelectFile} fullWidth />
                            </Grid>
                            <Grid sm={12} style={{ textAlign: 'right' }}>
                                <Button color="primary" onClick={handleSumit}>Submit</Button>
                            </Grid>
                            <Grid sm={12}>
                                {accountingMoney.result.isLoading
                                    ? <LinearProgress />
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}
function mapStateToProps(state) {
    return {
        accountingMoney: state.accountingMoney
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(Money);