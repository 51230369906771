import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'

import { DropzoneArea } from 'material-ui-dropzone'


import { actionCreators } from "../../../store/Cost";

import swal from 'sweetalert'

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Paper,
    Typography,
    FormControl,
    Input,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
    LinearProgress,
    IconButton,
    CircularProgress
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
    paper: {
        padding: 10
    },
    rtlInput: {
        textAlign: 'right'
    }
});

export function Money({ fetchUIData, accountingCost, submitCost, getTodayCost, getReceiptById, clearReceipt }) {
    const classes = useStyles();
    const initForm = { type: 'cash', methodId: null }
    const [form, setForm] = useState(initForm);
    const [file, setFile] = useState(null)
    const [isEditReference, setIsEditReference] = useState(true)

    const init = async () => {
        let response = await fetchUIData()
        let newForm = initForm
        if (response.data.paymentMethods && response.data.paymentMethods.length)
            newForm = await selectMethod(newForm,response.data.paymentMethods[0].id)
        setForm(newForm)
        setFile(null)
        setIsEditReference(true);
    }

    const selectMethod = async (newForm, id) => {
        let response = await getTodayCost(id)
        return { ...newForm, methodId: id, amount: response.data.data }
    }

    useEffect(() => {
        init();
    }, [])

    const handleSubmit = async () => {
        if (!form.type || !form.methodId) {
            swal('', 'กรุณาเลือกประเภท', 'warning')
            return;
        }

        switch (form.type) {
            case 'cash':
                if (!form.amount) {
                    swal('', 'กรุณาระบุจำนวนเงิน', 'warning')
                    return;
                }
                break;
            case 'cheque':
                if (!form.referenceCode || isEditReference) {
                    swal('', 'กรุณาระบุเลขที่ใบเสร็จให้ถูกต้อง', 'warning')
                    return;
                }
                break;
        }


        let response = await submitCost(form, file)
        if (response.data.success) {
            swal('', 'บันทึกสำเร็จ', 'success')
            init()
        }
    }

    const handleChange = (name) => async (e) => {
        let newForm = form;
        if (name === 'methodId')
            newForm = await selectMethod(newForm, e.target.value)
        else newForm = { ...newForm, [name]: e.target.value }
        setForm(newForm);
    }

    const handleSelectFile = (e) => {
        if (e.target.files.length)
            setFile(e.target.files[0])
        else setFile(null);
    }

    const getReceipt = async (refCode) => {
        let response = await getReceiptById(refCode)
        if (response?.data?.success)
            setIsEditReference(false)
    }

    const handleReferenceKeydown = async (e) => {
        if (e.key.indexOf('Enter') >= 0) {
            await getReceipt(form.referenceCode)
        }
    }
    const handleReferenceButtonClick = async (e) => {
        if (isEditReference) {
            await getReceipt(form.referenceCode)
        }
        else {
            clearReceipt()
            setIsEditReference(true)
            inputEl.current.removeAttribute('disalbed')
            setTimeout(() => inputEl.current.focus(), 0)
        }
    }

    const inputEl = useRef(null);

    if (accountingCost.conditionUIData.isLoading)
        return <LinearProgress />;

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid sm={12} container>
                <Grid sm={6}>
                    <Paper className={classes.paper} fullWidth>
                        <Typography variant="h5">ค่าตรวจ</Typography>
                        <Grid container>
                            <Grid sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">ช่องทางการชำระ</FormLabel>
                                    <RadioGroup value={form.methodId} row onChange={handleChange('methodId')}>
                                        {accountingCost.conditionUIData.data.paymentMethods.map(x =>
                                            <FormControlLabel key={x.id} value={x.id} control={<Radio />} label={x.name} />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">การชำระ</FormLabel>
                                    <RadioGroup value={form.type} row onChange={handleChange('type')}>
                                        <FormControlLabel value={'cash'} control={<Radio />} label={'เงินสด'} />
                                        <FormControlLabel value={'cheque'} control={<Radio />} label={'เช็ค'} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid sm={12} container>
                                {form.type === 'cheque' ? <>
                                    <Grid sm={2}>
                                        เลขที่ใบเสร็จ
                                    </Grid>
                                    <Grid sm={4}>
                                        <Input
                                            value={form.referenceCode}
                                            onChange={handleChange('referenceCode')}
                                            onFocus={(e) => e.target.select()}
                                            inputProps={{ ref: inputEl }}
                                            disabled={!isEditReference}
                                            onKeyDown={handleReferenceKeydown}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleReferenceButtonClick}
                                                    >
                                                        {isEditReference
                                                            ? form.referenceCode
                                                                ? accountingCost.receipt.isLoading
                                                                    ? <CircularProgress />
                                                                    : <CheckIcon />
                                                                : null
                                                            : <EditIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </>
                                    : null}
                                <Grid sm={2}>ยอดเงิน</Grid>
                                <Grid sm={form.type === 'cheque' ? 4 : 10}>
                                    <FormControl>
                                        <Input
                                            value={form.type === 'cheque' ? (accountingCost.receipt.amount || '') : form.amount}
                                            onBlur={() => { handleChange('amount')({ target: { value: parseFloat(form.amount) || '' } }) }}
                                            onChange={handleChange('amount')}
                                            endAdornment={<InputAdornment position="end">บาท</InputAdornment>}
                                            disabled={form.type === 'cheque'}
                                            inputProps={{ className: classes.rtlInput }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid sm={12} container>
                                <Grid sm={2}>
                                    หมายเหตุ
                                </Grid>
                                <Grid sm={10}>
                                    <Input
                                        value={form.remark}
                                        onChange={handleChange('remark')}
                                        fullWidth />
                                </Grid>
                            </Grid>
                            <Grid sm={12}>
                                แนบใบนำฝาก
                            </Grid>
                            <Grid sm={12}>
                                <Input type="file" onChange={handleSelectFile} fullWidth />
                            </Grid>
                            <Grid sm={12} style={{ textAlign: 'right' }}>
                                <Button color="primary" onClick={handleSubmit}>Submit</Button>
                            </Grid>
                            <Grid sm={12}>
                                {accountingCost.result.isLoading
                                    ? <LinearProgress />
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}
function mapStateToProps(state) {
    return {
        accountingCost: state.accountingCost
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(Money);