import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { actionCreators } from "../store/Site";
import { withRouter } from 'react-router'
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { isWidthUp } from "@material-ui/core/withWidth";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { InputBase } from "@material-ui/core"
import NavHeaderContent from './NavHeaderContent'

import loginButton from '../resources/line/btn_login_base.png'
import loginButtonHover from '../resources/line/btn_login_hover.png'
import loginButtonPress from '../resources/line/btn_login_press.png'
import lineLogo from '../resources/line/btn_base.png'

const styles = ({ spacing, transitions, breakpoints, palette, shape }) => ({
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18
  },
  grow: {
    flexGrow: 1
  },
  search: {
    position: "relative",
    marginRight: 8,
    borderRadius: shape.borderRadius,
    background: palette.grey[200],
    "&:hover": {
      background: palette.grey[300]
    },
    marginLeft: 0,
    width: "100%",
    [breakpoints.up("sm")]: {
      marginLeft: spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    borderRadius: 4,
    paddingTop: spacing.unit,
    paddingRight: spacing.unit,
    paddingBottom: spacing.unit,
    paddingLeft: spacing.unit * 10,
    transition: transitions.create("width"),
    width: "100%",
    [breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});

const HeaderContent = ({ classes, screen, history, collapsed, user, site, section, menu }) => (
  <React.Fragment>
    <Typography noWrap color={"textSecondary"} className={classes.header} style={{ cursor: 'pointer' }} onClick={() => { history.push('/') }}>
      iPACS {menu.menu.find(x => x.permissionCode == section)?.title ? <>&nbsp;&nbsp;-&nbsp;&nbsp;</> : ''}{menu.menu.find(x => x.permissionCode == section)?.title}
    </Typography>
    <div className={classes.grow} style={{ textAlign: 'center' }}>
      {['Doctor'].indexOf(user.roleName) < 0 ? <h5 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{user.branchName || ''}</h5> : null}
    </div>
    <div>
      <Typography>
        <div>
          {user.displayName}&nbsp;
          {user.lineName
            ? <span><img src={lineLogo} /> {user.lineName}</span>
            : null
          }
        </div>
      </Typography>
      {/* <div className={classes.searchIcon}>
        <Icon>search</Icon>
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
      /> */}
    </div>
    {/* {screen === "xs" && (
      <IconButton>
        <Icon>more_vert</Icon>
      </IconButton>
    )}
    {screen === "sm" && (
      <React.Fragment>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>more_vert</Icon>
        </IconButton>
      </React.Fragment>
    )}
    {isWidthUp("md", screen) && (
      <React.Fragment>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>phone</Icon>
        </IconButton>
        <IconButton>
          <Icon>camera</Icon>
        </IconButton>
      </React.Fragment>
    )} */}
  </React.Fragment>
);

HeaderContent.propTypes = {
  // screen: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired
};
HeaderContent.defaultProps = {};

function mapStateToProps(state) {
  return {
    user: state.user,
    site: state.site,
    menu: state.menu
  }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(withStyles(styles)(HeaderContent)));
