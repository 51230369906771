import React from 'react';
import { bindActionCreators } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import { actionCreators } from "../../../store/Billing";

import BillingSearchCondition from './BillingSearchCondition'
import BillingSearchResult from './BillingSearchResult'
import BillingDetail from './BillingDetail'
import BillingHistory from './BillingHistory'

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Container,
    Paper,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles({
    paper: {
        padding: 10
    }
});

export function Billing({ billing }) {
    const classes = useStyles();

    return (<>
        <Route path='/accounting/billing/:billingId' component={BillingDetail} />

        <Grid
            style={{ height: '100%', width: '100%' }}
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="stretch"
        >
            <Grid item xs={9} style={{ height: '100%' }}>
                <Typography variant="h5">วางบิล</Typography>
                <Grid container spacing={3} style={{ height: '100%' }}>
                    <Grid item xs={12} style={{ height: 165 }}>
                        <Paper className={classes.paper}>
                            <BillingSearchCondition />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} style={{ height: 'calc(100% - 125px)' }}>
                        <Paper className={classes.paper} style={{ height: '100%' }}>
                            <BillingSearchResult />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} style={{ height: '100%' }}>
                <Paper className={classes.paper} style={{ overflowY: 'auto', height: '100%' }}>
                    <BillingHistory />
                </Paper>
            </Grid>
        </Grid>
    </>

    );
}
function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(Billing);