import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as User from './User';
import * as Records from './Records';
import * as DicomViewer from './DicomViewer';
import * as Report from './Report'
import * as Menu from './Menu'
import * as Request from './Request'
import * as Patient from './Patient'
import * as Uploader from './Uploader'
import * as Site from './Site'
import * as Billing from './Billing'
import * as Money from './Money'
import * as Cost from './Cost'

export default function configureStore (history, initialState) {
  const reducers = {
      user: User.reducer,
      records: Records.reducer,
      dicomViewer: DicomViewer.reducer,
      report: Report.reducer,
      request: Request.reducer,
      menu: Menu.reducer,
      patient: Patient.reducer,
      uploader: Uploader.reducer,
      site: Site.reducer,
      billing: Billing.reducer,
      accountingMoney: Money.reducer,
      accountingCost: Cost.reducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed.
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
