import axios from "axios";
import swal from 'sweetalert'
import { endOfDay } from "date-fns";

const requestReportList = 'REQUEST_REPORTLIST'
const receiveReportList = 'RECIEVE_REPORTLIST'
const requestExamReport = 'REQUEST_EXAMREPORT'
const receiveExamReport = 'RECIEVE_EXAMREPORT'
const changeFinishedReportType = 'REPORT_CHANGE_FINISHED_REPORT'
const requestSendNotiToGatewayType = 'REQUEST_SEND_NOTI_TO_GATEWAY'
const recieveSendNotiToGatewayType = 'RECIEVE_SEND_NOTI_TO_GATEWAY'

const initialState = {
    reportName: null,
    parameters: [], // {fieldName, type, value}
    isLoading: false,
    reportList: [],
    finishedReports: [],
    currentFinishedReportId: null,
    reportType: 'telerik',
    pingGatewayReport: {
        isLoading: false,
        success: null
    }
}
axios.interceptors.response.use(async (response) => {

    if (response.data && response.data.success == false) {
        swal("", response.data.message || "Something went wrong!", "error");
    }
    return response

},
(error) => {
    if(error.response.status == 401)
    {
        window.location.href = '/Member/LogOn?ReturnUrl=';
    }
    else if (error.response && error.response.data) {
        swal("", error.response.data.message + (error.data.errors && error.data.errors.length? '\n'+error.data.errors.map(x=>x.defaultMessage).join(', '):''), "error");
    }
});
export const actionCreators = {
    getReports: (prefix, examBranchId) => async (dispatch) => {
        dispatch({ type: requestReportList });

        let url = '/api/reports/reportlist?prefix=' + prefix;
        if (examBranchId)
            url += '&examBranchId=' + examBranchId
        let response = await axios.get(url)

        dispatch({
            type: receiveReportList,
            result: response.data
        })
        return response;
    },
    getExamReport: (reportName, examId, isIncludeFinished) => async (dispatch) => {
        dispatch({ type: requestExamReport });

        let response = await axios.get(`/api/exam/${examId}/report/${reportName}?isIncludeFinished=${isIncludeFinished ? true : false}`)

        if (response && response.data && response.data.data)
            dispatch({
                type: receiveExamReport,
                reportName: reportName,
                parameters: response.data.data.reportParameters,
                finishedReports: response.data.data.finishedReports,
                isIncludeFinished
            });
    },
    changeFinishedReport: (finishedReportId) => async (dispatch) => {
        dispatch({ type: changeFinishedReportType, currentFinishedReportId: finishedReportId });
    },
    sendNotiToGateway: (examId, reportName) => async (dispatch) => {
        dispatch({ type: requestSendNotiToGatewayType, examId });
        try {
            let response = await axios.post(`/api/exam/${examId}/report/${reportName}/ping-gateway`)

            if (response && response.data && response.data.success)
                dispatch({ type: recieveSendNotiToGatewayType, examId, ...response.data });
            return response;
        } catch {
            dispatch({ type: recieveSendNotiToGatewayType, examId, success: false });
        }
    },
    _: () => async (dispatch) => {
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case requestSendNotiToGatewayType:
            return { ...state, pingGatewayReport: { ...state.pingGatewayReport, isLoading: true, success: null } }
        case recieveSendNotiToGatewayType:
            return { ...state, pingGatewayReport: { ...state.pingGatewayReport, isLoading: false, success: action.success } }
        case requestReportList:
            return {
                ...state,
                isLoading: true
            }
        case receiveReportList:
            return {
                ...state,
                reportList: action.result,
                isLoading: false
            }
        case requestExamReport:
            return {
                ...state,
                isLoading: true,
                reportName: null,
                parameters: [],
                finishedReports: []
            }
        case receiveExamReport:
            return {
                ...state,
                isLoading: false,
                reportName: action.reportName,
                parameters: action.parameters,
                finishedReports: action.finishedReports,
                currentFinishedReportId: action.isIncludeFinished ? 'new' : ((action.finishedReports || []).filter(x => x.reportName == action.reportName || (x.reportName.indexOf('PACS_Report') > 0 && action.reportName.indexOf('PACS_Report') > 0))[0] || {}).finishedReportId
            }
        case changeFinishedReportType:
            return {
                ...state,
                currentFinishedReportId: action.currentFinishedReportId
            }
        default:
            return state;
    }
};
