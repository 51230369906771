import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { actionCreators } from '../../store/Patient'

export function PatientSearchBox({ search,changeCondition,patient }) {

    // let [condition, setCondition] = useState({ name: '', phoneNumber: '', nationalNumber: '', hn:'' });

    const handleInputChanged = (e) => {
        let newCondition = { ...patient.condition, [e.target.name]: e.target.value };
        // setCondition(newCondition);
        changeCondition(newCondition);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        search(patient.condition)
    }

    useEffect(()=>{
        search();
    },[])
    return <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
                <Typography>ค้นหาคนไข้</Typography>
            </FormLabel>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6} md={3} lg={2}>
                    <TextField variant="filled"
                        fullWidth
                        name="name"
                        label="ชื่อ"
                        value={patient.condition.name}
                        margin="normal"
                        onChange={handleInputChanged}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <TextField variant="filled"
                        fullWidth
                        name="phoneNumber"
                        label="หมายเลขโทรศัพท์"
                        value={patient.condition.phoneNumber}
                        margin="normal"
                        onChange={handleInputChanged}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <TextField variant="filled"
                        fullWidth
                        name="hn"
                        label="HN"
                        value={patient.condition.hn}
                        margin="normal"
                        onChange={handleInputChanged}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <TextField variant="filled"
                        fullWidth
                        name="nationalNumber"
                        label="บัตรประจำตัวประชาชน"
                        value={patient.condition.nationalNumber}
                        margin="normal"
                        onChange={handleInputChanged}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Button color="primary" variant="contained" type="submit">
                        ค้นหา
                </Button>
                </Grid>
            </Grid>
        </FormControl>
    </form>
}

function mapStateToProps(state) {
    return {
        patient: state.patient
    }
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(PatientSearchBox)