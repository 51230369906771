import axios from "axios";
import swal from 'sweetalert'
const requestSiteConfig = 'REQUEST_SITE_CONFIG';
const receiveSiteConfig = 'RECEIVE_SITE_CONFIG';

const initialState = {
    config:{}
}
axios.interceptors.response.use(
    async (response) => {

        if (response && response.data && response.data.success == false) {
            swal("", response.data.message || "Something went wrong!", "error");
        }

        return response
    },
    (error) => {
        if(error.response.status == 401)
        {
            window.location.href = '/Member/LogOn?ReturnUrl=';
        }
        else if (error.response && error.response.data) {
            swal("", (error.response.data.errors && error.response.data.errors.length? '\n'+ [...new Set(error.response.data.errors.map(x=>x.defaultMessage))].join('\n'):error.response.data.message), "error");
        }
    });
export const actionCreators = {
    fetchSiteConfig: () => async (dispatch) => {
        dispatch({ type: requestSiteConfig })

        let response = await axios.get('api/uidata/site')

        dispatch({ type: receiveSiteConfig, data: response.data })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type){
        case receiveSiteConfig:
            return { ...state, config: action.data };
        default:
            return state;
    }
};
