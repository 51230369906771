import { Base64 } from 'js-base64'

try {
  var jwt = document.cookie.split(';').map(x => x.split('=')).filter(x => x[0].trim() === 'access_token')[0][1];
  var token = jwt.split('.')[1];
} catch (e) {
  //eslint-disable-next-line
  window.location.href = '/Member/LogOn?ReturnUrl='+window.location.pathname;
}
const initialState = JSON.parse(Base64.decode(token));
let permissionArray = initialState.permissions.split(',');
initialState.permission = {};

for (let i = 0; i < permissionArray.length; i++)
  initialState.permission[permissionArray[i]] = true;

initialState.jwt = jwt;

export const actionCreators = {
  //increment: () => ({ type: incrementCountType }),
  //decrement: () => ({ type: decrementCountType })
  checkPermission: (permissionCode) => async (dispatch, getState) => {
    return getState.permissionList.indexOf(permissionCode) >= 0;
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  return state;
};
