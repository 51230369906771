import axios from "axios";
import { startOfDay, endOfDay } from "date-fns";

const
    requestBillingSearch = 'REQUEST_BILLING_SEARCH',
    requestUIDataType = 'REQUEST_BILLING_UIDATA',
    receiveUIDataType = 'RECIEVE_BILLING_UIDATA',
    requestSearchBillingType = 'REQUEST_BILLING_SEARCH',
    receiveSearchBillingType = 'RECIEVE_BILLING_SEARCH',
    toggleBillingRowType = 'BILLING_TOGGLE_ROW',
    toggleAllBillingRowType = 'BILLING_TOGGLE_ALL_ROW',
    toggleBillingRequestRowType = 'BILLING_REQUEST_TOGGLE_ROW',
    toggleAllBillingRequestRowType = 'BILLING_REQUEST_TOGGLE_ALL_ROW',
    requestCreateBillingType = 'REQUEST_BILLING_CREATE',
    receiveCreateBillingType = 'RECIEVE_BILLING_CREATE',
    requestHistoryType = 'REQUEST_BILLING_HISTORY',
    receiveHistoryType = 'RECIEVE_BILLING_HISTORY',
    changeBillingConditionType = 'BILLING_CHANGE_CONDITION',
    requestBillingDetailType = 'REQUEST_BILLING_DETAIL',
    receiveBillingDetailType = 'RECIEVE_BILLING_DETAIL',
    requestBillingPaidType = 'REQUEST_BILLING_PAID',
    receiveBillingPaidType = 'RECIEVE_BILLING_PAID',
    changeHighlightHistoryType = 'BILLING_HISTORY_HIGHLIGHT',
    requestPrivilageType = 'REQUEST_BILLING_PRIVILAGE',
    receivePrivilageType = 'RECEIVE_BILLING_PRIVILAGE',
    clearBillingResult = 'BILLING_CLEAR_RESULT',
    requestCreateReceiptType = 'REQUEST_BILLING_CREATE_RECEIPT',
    recieveCreateReceiptType = 'RECIEVE_BILLING_CREATE_RECEIPT'


const initialState = {
    condition: {},
    data: [],
    detail: {},
    isLoading: false,
    isLoaded: false,
    billingHistory: {
        isLoading: false,
        isLoaded: false,
        data: [

        ]
    },
    conditionUIData: {
        isLoading: false,
        hospital: [],
        privilages: []
    },
    billingDetail: {
        isLoading: false,
        isLoaded: false,
        data: {

        }
    }
}

export const actionCreators = {
    fetchUIData: () => async (dispatch) => {
        dispatch({ type: requestUIDataType })
        let response = await axios.get('api/uidata/billingCondition');
        dispatch({ type: receiveUIDataType, hospital: response.data.hospital })
    },
    fetchHistory: (page, hospitalId) => async (dispatch) => {
        dispatch({ type: requestHistoryType })
        let response = await axios.get('api/billing/history?hospitalId=' + hospitalId);
        dispatch({ type: receiveHistoryType, data: response.data.data })
    },
    fetchPrivilages: (hospitalId) => async (dispatch) => {
        dispatch({ type: requestPrivilageType })
        let response = await axios.get('api/uidata/billingCondition?hospitalId=' + hospitalId);
        dispatch({ type: receivePrivilageType, data: response.data.privilages })
    },
    fetchBillingDetail: (billingId) => async (dispatch) => {
        dispatch({ type: requestBillingDetailType })
        let response = await axios.get('api/billing/' + billingId + '/detail');
        if (response && response.data && response.data.success && response.data.data.requests)
            dispatch({ type: receiveBillingDetailType, data: { ...response.data.data, requests: response.data.data.requests.map(x => { return { ...x, checked: !x.paidBillingId } }) } })
    },
    search: (page, condition) => async (dispatch, getState) => {
        dispatch({ type: requestSearchBillingType })

        if (!condition)
            condition = getState().billing.condition;

        if (condition.startDate)
            condition.startDate = startOfDay(condition.startDate);
        if (condition.endDate)
            condition.endDate = endOfDay(condition.endDate);
        let response = await axios.post('api/request/search', condition);

        dispatch({ type: receiveSearchBillingType, data: response.data.data.map(x => { return { ...x, checked: !x.paidBillingId } }) })
    },
    toggleSelectRow: (row) => async (dispatch) => {
        dispatch({ type: toggleBillingRowType, data: row })
    },
    changeCheckedAllRow: (isCheck) => async (dispatch) => {
        dispatch({ type: toggleAllBillingRowType, data: isCheck })
    },
    createBilling: (requestIds) => async (dispatch, getState) => {
        dispatch({ type: requestCreateBillingType })
        let { startDate, endDate, hospital, privilageId } = getState().billing.condition
        let response = await axios.post('api/billing/create', { startDate, endDate, hospital, privilageId, requestIds });

        if (response && response.data)
            dispatch({ type: receiveCreateBillingType, data: response.data.data, requestIds });
        return response;
    },
    changeCondition: (condition) => async (dispatch) => {
        dispatch({ type: changeBillingConditionType, condition });
    },
    markAsPaid: (billingId, requestId) => async (dispatch) => {
        dispatch({ type: requestBillingPaidType, data: { requestId } })
        let response = await axios.post('api/billing/' + billingId + '/paid/' + requestId);
        dispatch({ type: receiveBillingPaidType, data: response.data.data })
        return response;
    },
    markAsUnPaid: (billingId, requestId) => async (dispatch) => {
        dispatch({ type: requestBillingPaidType, data: { requestId } })
        let response = await axios.post('api/billing/' + billingId + '/unpaid/' + requestId);
        dispatch({ type: receiveBillingPaidType, data: response.data.data })
        return response;
    },
    highlightHistory: (billingId, isHighlight) => async (dispatch) => {
        dispatch({ type: changeHighlightHistoryType, data: { billingId, isHighlight } });
    },
    clearResult: () => async (dispatch) => {
        dispatch({ type: clearBillingResult })
    },
    toggleRequestSelectRow: (row) => async (dispatch) => {
        dispatch({ type: toggleBillingRequestRowType, data: row })
    },
    changeRequestAllRow: (isCheck) => async (dispatch) => {
        dispatch({ type: toggleAllBillingRequestRowType, data: isCheck })
    },
    createReceipt: (billingId, requestIds, { type, bankName, amount, number }) => async (dispatch) => {
        dispatch({ type: requestCreateReceiptType, data: requestIds, billingId })
        let response = await axios.post(`api/billing/${billingId}/receipt/create`, { requestIds, type, bankName, amount, number })
        dispatch({ type: recieveCreateReceiptType, data: response.data.data, requestIds, billingId })
        return response;
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case requestCreateReceiptType:
            const remainingRequests = state.billingDetail.data.requests.filter(x => action.data.indexOf(x.requestId) < 0);
            const processingRequests = state.billingDetail.data.requests.filter(x => action.data.indexOf(x.requestId) >= 0)
                .map(x => { return { ...x, paidBillingId: null, isLoading: true } })

            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: [...processingRequests, ...remainingRequests]
                    }
                }
            }
        case recieveCreateReceiptType:
            const remainingRequests2 = state.billingDetail.data.requests.filter(x => action.requestIds.indexOf(x.requestId) < 0);
            const processingRequests2 = state.billingDetail.data.requests.filter(x => action.requestIds.indexOf(x.requestId) >= 0)
                .map(x => { return { ...x, paidBillingId: action.data.receiptId, paidDocumentId: action.data.receiptDocumentId, isLoading: false } })

            // const historyIndex = state.billingHistory.data.findIndex(x=>x.billingId==action.billingId)

            // let billingHistory = state.billingHistory;
            // if(historyIndex>=0){
            //     billingHistory = {
            //         ...state.billingHistory,
            //         data: [...state.billingHistory.data.slice(0, historyIndex),
            //         { ...state.billingHistory.data[historyIndex], receipts: [action.data,...state.billingHistory.data[historyIndex].receipts] },
            //         ...state.billingHistory.data(historyIndex + 1)]
            //     }
            // }
            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: [...processingRequests2, ...remainingRequests2]
                    }
                }
                // billingHistory: billingHistory
            }

        case clearBillingResult:
            return initialState
        case requestPrivilageType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    privilages: []
                }
            }
        case receivePrivilageType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    privilages: action.data
                }
            }
        case changeHighlightHistoryType: {
            let oData = [...state.billingHistory.data];
            let focusItem = oData.find(x => x.billingId == action.data.billingId);
            if (focusItem)
                focusItem.isHighlight = action.data.isHighlight
            return {
                ...state,
                billingHistory: {
                    ...state.billingHistory,
                    data: oData
                }
            }
        }
        case requestBillingPaidType: {
            const oRequests = [...state.billingDetail.data.requests]
            const updateRequest = oRequests.find(x => x.requestId === action.data.requestId);
            if (!updateRequest)
                return state

            const updateIndex = state.billingDetail.data.requests.indexOf(updateRequest);
            const newRequest = [
                ...state.billingDetail.data.requests.slice(0, updateIndex),
                { ...updateRequest, paidBillingId: null, isLoading: true },
                ...state.billingDetail.data.requests.slice(updateIndex + 1)
            ]

            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: newRequest
                    }
                }
            }
        }
        case receiveBillingPaidType: {
            const oRequests = [...state.billingDetail.data.requests]
            const updateRequest = oRequests.find(x => x.requestId === action.data.requestId);
            if (!updateRequest)
                return state

            const updateIndex = state.billingDetail.data.requests.indexOf(updateRequest);
            const newRequests = [
                ...state.billingDetail.data.requests.slice(0, updateIndex),
                { ...action.data, isLoading: false },
                ...state.billingDetail.data.requests.slice(updateIndex + 1)
            ]


            const rowIndex = state.data.findIndex(x => x.requestId === action.data.requestId)

            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: newRequests
                    }
                },
                data: [...state.data.slice(0, rowIndex), action.data, ...state.data.slice(rowIndex + 1)]
            }
        }
        case requestBillingDetailType: {
            return {
                ...state,
                billingDetail: {
                    isLoading: true,
                    isLoaded: false,
                    data: {}
                }
            }
        }
        case receiveBillingDetailType:
            return {
                ...state,
                billingDetail: {
                    isLoading: false,
                    isLoaded: true,
                    data: action.data
                }
            }
        case changeBillingConditionType:
            return {
                ...state,
                condition: action.condition
            }
        case requestHistoryType:
            return {
                ...state,
                billingHistory: {
                    ...state.billingHistory,
                    isLoading: true,
                    isLoaded: false,
                    data: []
                }
            }
        case receiveHistoryType:
            return {
                ...state,
                billingHistory: {
                    ...state.billingHistory,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data
                }
            }
        case requestCreateBillingType:
            return {
                ...state
            }
        case receiveCreateBillingType:
            let oRows = [...state.data]
            let updateRows = oRows.filter(x => action.requestIds.indexOf(x.requestId) >= 0)
            debugger;
            for (let i = 0; i < updateRows.length; i++) {
                updateRows[i].latestBillingId = action.data.billingId
                updateRows[i].latestBillingDocumentId = action.data.documentId
            }
            return {
                ...state,
                billingHistory: {
                    ...state.billingHistory,
                    data: [action.data, ...(state.billingHistory.data || [])]
                },
                data: oRows
            }
        case toggleBillingRowType:
            const rowIndex = state.data.indexOf(action.data);
            return {
                ...state,
                data: [...state.data.slice(0, rowIndex), { ...action.data, checked: !action.data.checked }, ...state.data.slice(rowIndex + 1)]
            }
        case toggleAllBillingRowType:
            return {
                ...state,
                data: state.data.map(x => { return { ...x, checked: !x.paidBillingId ? action.data : x.checked } })
            }
        case toggleBillingRequestRowType:
            const rowRequestIndex = state.billingDetail.data.requests.indexOf(action.data);
            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: [...state.billingDetail.data.requests.slice(0, rowRequestIndex), { ...action.data, checked: !action.data.checked }, ...state.billingDetail.data.requests.slice(rowRequestIndex + 1)]
                    }
                }
            }
        case toggleAllBillingRequestRowType:
            return {
                ...state,
                billingDetail: {
                    ...state.billingDetail,
                    data: {
                        ...state.billingDetail.data,
                        requests: state.billingDetail.data.requests.map(x => { return { ...x, checked: !x.paidBillingId ? action.data : x.checked } })
                    }
                }
            }
        case requestBillingSearch:
            return {
                ...state,
                isLoading: true
            }
        case requestUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false
                }
            }
        case receiveUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    hospital: action.hospital
                }
            };
        case requestSearchBillingType:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                data: []
            }
        case receiveSearchBillingType:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                data: action.data
            }
        default:
            return state;
    }
};
