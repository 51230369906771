import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actionCreators } from "../../store/Report";

import LinearProgress from "@material-ui/core/LinearProgress";
import ReportSelector from "./ReportSelector";
import ReportViewer from "./ReportViewer";

import FinishedReportSelector from "./finishedReportSelector";
import queryString from 'query-string'

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.selectReport = this.selectReport.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let { currentFinishedReportId } = this.props.report;
        let { reportName, examId, formId, branchMode } = nextProps.match.params;

        if (JSON.stringify({ examId, formId, branchMode }) !== JSON.stringify({ examId: this.props.match.params.examId, formId: this.props.match.params.formId, branchMode: this.props.match.params.branchMode })) {

            let prefix = ""
            if (examId)
                prefix = 'pacs';
            else if (formId)
                prefix = 'accounting';
            else if (branchMode)
                prefix = 'branch_report_' + branchMode;
            else
                prefix = 'doctor';

            this.props.getReports(prefix).then(response => {
                if (reportName !== undefined) this.selectReport(reportName);
                else if (response.data.length >= 1) this.selectReport(response.data[0]);
            });
        }else if(currentFinishedReportId!=nextProps.report.currentFinishedReportId && nextProps.report.currentFinishedReportId == 'new'){
            this.props.getExamReport(reportName, examId || formId, true);
        }
        else if (reportName) {
            if (examId) {
                if (reportName !== this.props.match.params.reportName)
                    this.props.getExamReport(reportName, examId);
            } else if (formId) {
                if (reportName !== this.props.match.params.reportName)
                    this.props.getExamReport(reportName, formId);
            }
        }
        else if (this.props.report.reportList.length >= 1)
            this.selectReport(this.props.report.reportList[0]);

    }

    componentDidMount() {
        let { reportName, examId, formId, branchMode, billingId, reportFor } = this.props.match.params;

        let prefix = ""
        if (examId)
            prefix = 'pacs';
        else if (formId)
            prefix = 'accounting';
        else if (branchMode)
            prefix = 'branch_report_' + branchMode;
        else if (billingId)
            prefix = 'billing_'
        else if (reportFor === 'doctor')
            prefix = 'doctor';
        else if (reportFor === 'center')
            prefix = 'center_report';

        const query = queryString.parse(this.props.location.search)
        this.props.getReports(prefix, query.examBranchId).then(response => {
            if (reportName !== undefined && response.data.indexOf(reportName) >= 0) this.selectReport(reportName);
            else if (response.data.length >= 1) this.selectReport(response.data[0]);
        });

        // document.getElementsByTagName('header')[0].style.display = 'none';
        document.getElementsByTagName('main')[0].style.padding = '0px';
    }

    selectReport(reportName) {
        let { finishedReports, currentFinishedReportId,isLoading } = this.props.report;
        let { examId, formId, branchMode, billingId, reportFor } = this.props.match.params;
        if (reportName) {
            let secondUrl =
                examId
                    ? '/report'
                    : formId
                        ? '/accounting_report'
                        : branchMode
                            ? '/branch_report/' + branchMode
                            : billingId
                                ? '/billing_report'
                                : reportFor
                                    ? `/${reportFor}_report`
                                    : '';
            let thridUrl = `${examId || formId || billingId || ''}`
            this.props.history.push(`${secondUrl}${thridUrl ? '/' + thridUrl : ''}/${reportName}${this.props.location.search}`);
            // if (examId)
            this.props.getExamReport(reportName, examId || formId || billingId, currentFinishedReportId === "new");
        }
    }

    render() {
        let { parameters, isLoading, reportList, finishedReports, currentFinishedReportId } = this.props.report;
        let { examId, formId, reportName, billingId } = this.props.match.params;
        return (
            <div style={{ height: "calc(100% - 66px)", zIndex: 10, background: "#fff", position: "absolute", width: "100%" }}>
                {/* <ReportViewer reportName={reportName} parameters={parameters} /> */}
                {isLoading && <LinearProgress />}
                <ReportSelector reportList={reportList} onSelectReport={this.selectReport} reportName={reportName} finishedReports={finishedReports} />
                {this.props.match.params.reportName && (
                    <div style={{ height: "calc(100% - 52px)" }}>
                        {/* <Parameters parameters={parameters} /> */}
                        {finishedReports.filter(y => 
                            y.reportName == this.props.match.params.reportName
                            || (y.reportName.indexOf('PACS_Report')>0 && this.props.match.params.reportName.indexOf('PACS_Report')>0)
                            ).length == 0 || currentFinishedReportId === "new" 
                            ? (
                            // this.props.match.params.reportName.indexOf('.html') > 0
                            //     ? <iframe title={"reportviewer"} ref={x => this.iframeElement = x} name="reportFrame"
                            //         src={'/ReportViewer/'+this.props.match.params.reportName}
                            //         frameBorder="0"
                            //         scrolling="yes"
                            //         style={{ border: 0, width: '100%', height: '100%' }}
                            //     />
                            //     :
                                <ReportViewer
                                    reportName={this.props.match.params.reportName}
                                    parameters={parameters}
                                    examId={examId || formId || billingId}
                                    currentFinishedReportId={currentFinishedReportId}
                                />
                        ) : (
                            <FinishedReportSelector />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        report: state.report
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(Reports));
