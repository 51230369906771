import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { startOfDay, endOfToday, subDays, startOfToday, sub } from "date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";

import { actionCreators } from "../../store/Request";

export function RequestSearchCondition({ request, fetchSearchConditionUIData, search, user }) {

    const [condition, setCondition] = useState({
        startDate:
            user.roleName === 'Link' || user.roleName === 'HospitalDoctor'
                ? sub(new Date(), { years: 1 })
                : user.roleName === 'Doctor'
                    ? sub(new Date(), { days: 7 })
                    : new Date(),
        endDate: new Date()
    });


    const handleSubmit = (e) => {
        e.preventDefault();
        setRefreshIn(300);
        search(1, condition)
    };

    const searchToday = (e) => {
        e.preventDefault();
        const newCondition = { ...condition, startDate: startOfToday(), endDate: endOfToday() };
        setCondition(newCondition);
        search(1, newCondition)
    }

    const searchLast7Days = (e) => {
        e.preventDefault();
        const newCondition = { ...condition, startDate: startOfDay(subDays(new Date(), 7)), endDate: endOfToday() };
        setCondition(newCondition);
        search(1, newCondition)
    }

    const inputChangeHandlerCustom = (name, value) => {
        setCondition({ ...condition, [name]: value });
    }

    const inputChangeHandler = (e) => {
        setCondition({ ...condition, [e.target.name]: e.target.value })
    }

    const [isAutoRefresh, setIsAutoRefresh] = useState(false)
    const [refreshIn, setRefreshIn] = useState(300)

    useEffect(() => {
        if (isAutoRefresh) {
            const timeout = setTimeout(() => {
                if (refreshIn - 1 <= 0) {
                    search(1, condition);
                    setRefreshIn(300);
                }
                else setRefreshIn(refreshIn - 1);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        } else setRefreshIn(300);
    }, [isAutoRefresh, refreshIn]);

    const onChangeAutoRefreshCheckbox = (e) => {
        setIsAutoRefresh(e.target.checked);
    }

    useEffect(() => {
        fetchSearchConditionUIData();
        search(1, condition)
        // setIsAutoRefresh(true);
    }, [])
    if (!user.permission['REQUEST_SEARCH'])
        return null;
    return (<Container className="Records">
        <Paper style={{ padding: 10 }}>
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" fullWidth>
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={4} md={2} lg={2}>
                                <KeyboardDatePicker
                                    inputVariant="filled"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="From Date"
                                    value={condition.startDate}
                                    onChange={(v) => inputChangeHandlerCustom("startDate", v)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoOk={true}
                                />
                            </Grid>
                            <Grid item xs={4} md={2} lg={2}>
                                <KeyboardDatePicker
                                    inputVariant="filled"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="To Date"
                                    value={condition.endDate}
                                    onChange={(v) => inputChangeHandlerCustom("endDate", v)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoOk={true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={4} md={2} lg={2}>
                            <TextField variant="filled"
                                fullWidth
                                name="accessionNumber"
                                label="Accession Number"
                                value={condition.accessionNumber}
                                margin="normal"
                                onChange={inputChangeHandler}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} md={2} lg={2}>
                            <TextField variant="filled"
                                fullWidth
                                name="hn"
                                label="HN"
                                value={condition.hn}
                                margin="normal"
                                onChange={inputChangeHandler}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} md={2} lg={2}>
                            <TextField variant="filled"
                                fullWidth
                                name="nationalId"
                                label="ID Card"
                                value={condition.nationalId}
                                margin="normal"
                                onChange={inputChangeHandler}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4} md={2} lg={2}>
                            <TextField variant="filled"
                                fullWidth
                                name="patientName"
                                label="Patient Name"
                                value={condition.patientName}
                                margin="normal"
                                onChange={inputChangeHandler}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{textAlign:'center'}}>
                            <Button color="primary" variant="contained" type="submit">Search</Button>
                        </Grid>
                        {/* <Grid item container xs={12} justify="center">
                            <Button color="secondary" variant="contained" type="button" onClick={searchToday}>Today</Button>
                            &nbsp;
                            <Button color="secondary" variant="contained" type="button" onClick={searchLast7Days}>Last 7 days</Button>
                        </Grid> */}
                    </Grid>
                </FormControl>
            </form>
            <div style={{ height: 32, textAlign: 'right' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAutoRefresh}
                            onChange={onChangeAutoRefreshCheckbox}
                            color="primary"
                        />
                    }
                    label={"Auto Refresh?" + (isAutoRefresh ? ` (${refreshIn})` : '')}
                />
            </div>
        </Paper>
    </Container>
    );
}
function mapStateToProps(state) {
    return {
        request: state.request,
        user: state.user
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(RequestSearchCondition);