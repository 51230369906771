
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Patient";
import swal from 'sweetalert'

export function ImportByHNDialog({ onSubmit, onClose, open, patient, findPatientFromGateway, getPatientUIData, getGatewayData }) {
    const [hn, setHN] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => {
        onClose();
    };

    const handleSubmitButton = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        let patient = await findPatientFromGateway(hn);
        setIsLoading(false);
        if(patient && patient.data && patient.data.success)
            onSubmit(patient.data.data);
        else{
            swal('', patient?.data?.message || 'ไม่พบข้อมูล', 'info')
        }
    }


    const onEnterHandle = async ()=>{
        setHN('')
        let response = await getPatientUIData();
        if (response?.data) {
            let gatewayResponse = await getGatewayData();
            if (!gatewayResponse?.data)
                onClose();
        }
    }

    function useHookWithRefCallback() {
        const ref = useRef(null)
        const setRef = useCallback(node => {
            if (ref.current) {
                // Make sure to cleanup any events/references added to the last instance
            }

            if (node) {
                // Check if a node is actually passed. Otherwise node would be null.
                // You can now do what you need to, addEventListeners, measure, etc.
                node.focus()
            }

            // Save a reference to the node
            ref.current = node
        }, [])
        return [setRef]
    }
    const [ref] = useHookWithRefCallback()
    return (
        <Dialog onClose={handleClose} onEnter={onEnterHandle} fullWidth open={open} disableBackdropClick>
            <DialogTitle id="">กรุณาระบุ HN</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmitButton}>
                    <TextField variant="filled"
                        fullWidth
                        name="hn"
                        label="HN"
                        value={hn}
                        margin="normal"
                        onChange={(e) => setHN(e.target.value)}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputRef={ref}
                        disabled={isLoading}
                    />
                    {isLoading ? <LinearProgress /> : null}
                    <div style={{ textAlign: 'right', padding: 8, margin: '24px -24px 0 -24px' }}>
                        <Button color="default" variant="text" type='button' onClick={handleClose}>Cancle</Button>
                        <Button color="primary" variant="contained" type='submit' disabled={isLoading}>Find</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}


function mapStateToProps(state) {
    return {
        patient: state.patient
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(ImportByHNDialog);
