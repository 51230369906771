import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router";
import { format, intervalToDuration, formatDuration, compareAsc, addMinutes } from "date-fns";
import { Check } from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress"
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhotoIcon from "@material-ui/icons/Photo";
import TableChartIcon from "@material-ui/icons/TableChart";
import AlbumIcon from '@material-ui/icons/Album';
import TableContainer from "@material-ui/core/TableContainer"
import BlockIcon from '@material-ui/icons/Block';
import HistoryIcon from '@material-ui/icons/History';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CommentIcon from '@material-ui/icons/Comment';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as RIcon } from '../../resources/R.svg';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Dialog, DialogContent, DialogContentText, Button, DialogActions, DialogTitle } from "@material-ui/core/"
import swal from 'sweetalert'
import { actionCreators } from "../../store/Records";
import UploadDocumentDialog from "./UploadDocumentDialog";
import EditDicomDialog from "./EditDicomDialog";
import AssignDialog from "./AssignDialog";
import HistoryDialog from './HistoryDialog'
import './Records.css'

class RecordResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
            page: 0,
            dialog: {
                type: undefined,
                examId: undefined
            },
            uploadDocumentDialog: {
                open: false,
                examId: "",
                remark: "",
                allergy: []
            },
            editDicomDialog: {
                open: false,
                examId: ""
            },
            assignDialog: {
                open: false,
                examId: null
            },
            acceptDialog: {
                open: false,
                answer: null,
                exam: null,
                callback: null
            },
            historyDialog: {
                open: false,
                examId: null
            },
            isLoading: false,
            openCompleteSection: false,
            cdDialog: {
                open: false,
                examId: null
            },
            radiantDialog: {
                open: false,
                examId: null
            }
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.openUploadDocumentDialog = this.openUploadDocumentDialog.bind(this);
        this.closeDocumentDialog = this.closeDocumentDialog.bind(this);
        this.openEditDicomDialog = this.openEditDicomDialog.bind(this);
        this.closeEditDicomDialog = this.closeEditDicomDialog.bind(this);
        this.openAssignDialog = this.openAssignDialog.bind(this);
        this.closeAssignDialog = this.closeAssignDialog.bind(this);
        this.handleCloseAcceptDialog = this.handleCloseAcceptDialog.bind(this);
        this.requestToAcceptCase = this.requestToAcceptCase.bind(this);
        this.rejectExam = this.rejectExam.bind(this);
        this.openHistoryDialog = this.openHistoryDialog.bind(this);
        this.closeHistoryDialog = this.closeHistoryDialog.bind(this);
        this.deleteDicom = this.deleteDicom.bind(this);
        this.deleteExam = this.deleteExam.bind(this);
    }

    handleChangePage(e, page) {
        this.setState({ page });
        this.props.searchRecoards(page + 1);
    }
    rejectExam(exam) {
        swal({
            title: "Confirm to reject this case",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await this.props.rejectExam(exam.examId);
                } else {

                }
            });
    }
    async requestToAcceptCase(exam, callback) {
        if (this.props.user.memberId === exam.reviewerId && exam.status === 'Assigned') {
            this.setState({ acceptDialog: { open: true, callback: callback, exam: exam } });
        }
        else if (callback && typeof (callback) === 'function')
            callback();
    }

    openHistoryDialog(exam) {
        this.setState({
            historyDialog: {
                open: true,
                examId: exam.examId,
                exam
            }
        });
    }

    closeHistoryDialog() {
        this.setState({
            historyDialog: {
                open: false,
                examId: "",
                exam: null
            }
        });
    }

    openDialog(type, exam, event) {
        this.requestToAcceptCase(exam, async () => {
            switch (type) {
                case "report":
                    window.open(`/${type}/${exam.examId}?examBranchId=${exam.branchId}`);
                    break;
                case "viewer":
                    // window.open(`/${type}/${exam.examId}`);
                    if (exam.zipStatus == 'Archive') {
                        function findParent(el, tag) {
                            let result = el;
                            while (result.tagName != tag) {
                                if (result.parentElement == null)
                                    break;
                                result = result.parentElement;
                            }
                            return result;
                        }
                        function showLoading(event) {
                            let btn = findParent(event.target, 'TD');
                            btn.firstChild.style.display = 'none';
                            btn.lastChild.style.display = 'inline-block'
                        }
                        function hideLoading(event) {
                            let btn = findParent(event.target, 'TD');
                            btn.lastChild.style.display = 'none';
                            btn.firstChild.style.display = 'inline-block'
                        }
                        event.persist();
                        showLoading(event);
                        await this.props.unzipExam(exam.examId);
                        hideLoading(event);
                    } 
                    if(exam.modality==='ECG')
                        window.open('https://salmon-desert-092964d00.5.azurestaticapps.net/?manifest=' + encodeURIComponent(document.location.protocol + "//" + document.location.host + "/weasis/" + exam.examId + `?token=${this.props.user.jwt}`))
                    else window.open('https://dicom-viewer.azurewebsites.net/?manifest=' + encodeURIComponent(document.location.protocol + "//" + document.location.host + "/weasis/" + exam.examId + `?token=${this.props.user.jwt}`))
                    break;
                case "cd":
                    this.props.clearCdDialog();
                    this.setState({ cdDialog: { open: true, examId: exam.examId } })
                    break;
                case "radiant":
                    this.props.clearRadiant();
                    this.setState({ radiantDialog: { open: true, examId: exam.examId } })
                    break;
            }
        });
    }

    openUploadDocumentDialog(exam, type) {
        this.setState({
            uploadDocumentDialog: {
                open: true,
                examId: exam.examId,
                type: type,
                remark: exam.remark,
                allergy: exam.allergy
            }
        });
    }

    closeDocumentDialog() {
        this.setState({
            uploadDocumentDialog: {
                open: false,
                examId: "",
                remark: '',
                allergy: []
            }
        });
    }

    openEditDicomDialog(examId) {
        this.setState({
            editDicomDialog: {
                open: true,
                examId
            }
        });
    }

    closeEditDicomDialog() {
        this.setState({
            editDicomDialog: {
                open: false,
                examId: ""
            }
        });
    }

    async openAssignDialog(x) {
        let examResponse = await this.props.getExam(x.examId);

        this.setState({
            assignDialog: {
                open: true,
                examId: x.examId,
                exam: examResponse.data
            }
        });
    }

    closeAssignDialog() {
        this.setState({
            assignDialog: {
                open: false,
                examId: "",
                exam: null
            }
        });
    }



    downloadDicom(e, x) {
        function findParent(el, tag) {
            let result = el;
            while (result.tagName != tag) {
                if (result.parentElement == null)
                    break;
                result = result.parentElement;
            }
            return result;
        }
        function showLoading(event) {
            let btn = findParent(event.target, 'TD');
            btn.firstChild.style.display = 'none';
            btn.lastChild.style.display = 'inline-block'
        }
        function hideLoading(event) {
            let btn = findParent(event.target, 'TD');
            btn.lastChild.style.display = 'none';
            btn.firstChild.style.display = 'inline-block'
        }



        this.requestToAcceptCase(x, () => {
            // this.setState({ isLoading: true });
            showLoading(e);
            e.persist()

            let busy = false;
            let checkInterval = setInterval(async () => {
                if (busy)
                    return;
                busy = true;
                let response = await this.props.checkExportExam(x.examId);
                if (response.data.success && response.data.data) {
                    clearInterval(checkInterval);
                    window.open('/dicom/export/' + x.examId, '_download', 'width=400,height=200')
                    // setTimeout(() => this.setState({ isLoading: false }), 1000);
                    hideLoading(e)
                } else if (!response.data.success) {
                    clearInterval(checkInterval);
                    // this.setState({ isLoading: false })
                    hideLoading(e)
                }
                busy = false;
            }, 1000)
        });
    }

    approveDicom(x) {
        swal({
            title: "Confirm to Approve DICOM image",
            icon: "success",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    this.props.approveDicom(x.examId)
                } else {

                }
            });
    }

    rejectDicom(x) {
        swal({
            title: "Confirm to Reject DICOM image",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    this.props.rejectDicom(x.examId)
                } else {

                }
            });
    }

    approveReport(x) {
        swal({
            title: "Confirm to Approve report",
            icon: "success",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    this.props.approveReport(x.examId)
                } else {

                }
            });
    }

    rejectReport(x) {
        swal({
            title: "Confirm to Reject report",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    this.props.rejectReport(x.examId)
                } else {

                }
            });
    }

    deleteDicom(x) {
        swal({
            title: "Confirm to delete DICOM image",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await this.props.deleteDicom(x.examId);
                } else {

                }
            });
    }

    deleteExam(x) {
        swal({
            title: "Confirm to delete this exam",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await this.props.deleteExam(x.examId);
                } else {

                }
            });
    }

    async handleCloseAcceptDialog(isAccept) {
        if (isAccept) {
            await this.props.acceptExam(this.state.acceptDialog.exam.examId);

            if (this.state.acceptDialog.callback && typeof (this.state.acceptDialog.callback) === 'function') {
                this.state.acceptDialog.callback();
            }
        }
        this.setState({ acceptDialog: { open: false } });
    }

    componentDidMount() {
        setInterval(() => { this.setState({ now: new Date() }) }, 30000)
    }




    render() {
        let { user, records } = this.props;

        const renderRecordRow = (row, isSimple) => {
            let countDown = '';
            let countDownColor = '#ffffff'
            let countDownFontColor = '#00000'
            if (row.status !== 'Completed' && row.expectedDate) {
                let duration = intervalToDuration({ start: this.state.now, end: new Date(row.expectedDate) })
                delete duration['seconds'];
                let isOverdue = compareAsc(this.state.now, new Date(row.expectedDate)) == 1 ? true : false;
                countDown = formatDuration(duration).replace('days', 'd').replace('hours', 'h').replace('minutes', 'min');

                if (isOverdue) {
                    // if (user.roleName === 'Doctor')
                    countDown += ' overdue';
                    countDownColor = 'rgb(253, 237, 237)';
                    countDownFontColor = 'rgb(95, 33, 32)';
                } else {
                    let { years, months, weeks, days, hours } = duration;
                    if (((years || 0) * 2 + (months || 0) * 2 + (weeks || 0) * 2 + (days || 0) * 2 + (hours || 0)) <= 1) {
                        countDownColor = 'rgb(255, 244, 229)';
                        countDownFontColor = 'rgb(102, 60, 0)';
                    }
                }

            }

            let noDicomAuditNeed = row.dicomAuditStatus === null || row.dicomAuditStatus === undefined;
            let dicomAuditing = row.dicomAuditStatus == 'WaitingForAudit';
            let isDicomAudited = (row.dicomAuditStatus === 'Approved' || row.dicomAuditStatus === 'Rejected')
            let hasDicom = row.hasImage
            let isDicomAuditCompleted = noDicomAuditNeed || isDicomAudited;


            let noReportAuditNeed = row.reportAuditStatus === null || row.reportAuditStatus === undefined;
            let reportAuditing = row.reportAuditStatus == 'WaitingForAudit';
            let isReportAudited = (row.reportAuditStatus === 'Approved' || row.reportAuditStatus === 'Rejected')
            let hasReport = row.reportCount > 0
            let isReportAuditCompleted = noReportAuditNeed || isReportAudited;

            return (
                <TableRow key={row.examId} style={{ backgroundColor: countDownColor, color: countDownFontColor }} hover selected={'#' + row.accessionNumber == this.props.location.hash}>
                    {!isSimple && ['Report Editor'].indexOf(user.roleName) < 0 ? <TableCell>{countDown}{row.latestMessage ? <><br /><span style={{ color: "red" }}>{row.latestMessage}</span></> : ''}</TableCell> : null}
                    {!isSimple && ['Doctor', 'Report Editor'].indexOf(user.roleName) >= 0 ? <TableCell>{row.branchName}</TableCell> : null}
                    {!isSimple && ['Doctor'].indexOf(user.roleName) >= 0 ? <TableCell>{row.age}</TableCell> : null}
                    <TableCell align="center">{row.accessionNumber}</TableCell>
                    <TableCell align="center">
                        {row.examDate && format(new Date(row.examDate), "dd-MM-yyyy")}
                    </TableCell>
                    {!isSimple && <TableCell>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', height: '1.2em' }}>
                            {row.hn}
                        </div>
                    </TableCell>
                    }
                    {!isSimple && <TableCell>
                        <div style={{ textOverflow: 'ellipsis', width: 175, overflow: 'hidden', height: '1.2em', whiteSpace: 'nowrap' }}>
                            {row.patientName}
                        </div>
                    </TableCell>}
                    <TableCell align="center">{row.modality}</TableCell>
                    <TableCell>
                        <div style={{ textOverflow: 'ellipsis', width: 175, height: '1.2em', whiteSpace: 'nowrap', overflow: 'hidden' }} title={row.description}>
                            {row.description ? row.description.replace(new RegExp('\r?\n', 'g'), ', ') : null}
                        </div>
                    </TableCell>
                    <TableCell>
                        {format(new Date(row.recieiveDate), "dd-MM-yyyy HH:mm")}
                    </TableCell>
                    {user.permission['EXAM_DOCUMENT'] ?
                        <TableCell style={{ cursor: "pointer" }} onClick={() => this.openUploadDocumentDialog(row, 'document')} align="center">
                            {row.hasNote ? <CommentIcon /> : !isSimple && user.roleName !== 'Doctor' && null}
                            {row.hasDocument ? <AttachmentIcon /> : !isSimple && user.roleName !== 'Doctor' && <CloudUploadIcon />}
                        </TableCell>
                        : null}
                    {user.permission['EXAM_DOCUMENT'] ?
                        <TableCell align="center">
                            {row.hasFile
                                ? <AttachmentIcon style={{ cursor: "pointer" }} onClick={() => this.openUploadDocumentDialog(row, 'file')} />
                                : !isSimple && user.roleName !== 'Doctor' && <CloudUploadIcon style={{ cursor: "pointer" }} onClick={() => this.openUploadDocumentDialog(row, 'file')} />
                            }
                            &nbsp;
                            {!isSimple && row.hasOldExams ? <HistoryIcon style={{ cursor: "pointer" }} onClick={() => this.openHistoryDialog(row)} /> : null}
                        </TableCell>
                        : null}
                    {user.permission['EXAM_DICOM'] && !isDicomAuditCompleted && !user.permission['EXAM_DICOM_APPROVE']
                        ? <TableCell padding="none" colSpan={records.cdDialog.enable ? 4 : 3}>
                            {row.dicomAuditStatus}
                        </TableCell>
                        : null
                    }
                    {user.permission['EXAM_DICOM_APPROVE'] || user.permission['EXAM_DICOM'] && isDicomAuditCompleted
                        ? hasDicom ?
                            <>
                                <TableCell padding="none" style={{ cursor: hasDicom ? "pointer" : "" }} onClick={async (e) => hasDicom && this.openDialog("viewer", row, e)} align="center">
                                    {hasDicom && <PhotoIcon />}
                                    <CircularProgress style={{ display: 'none' }} size={18} />
                                </TableCell>
                                {records.cdDialog.enable ?
                                    <TableCell padding="none" style={{ cursor: hasDicom ? "pointer" : "" }} onClick={() => hasDicom && this.openDialog("cd", row)} align="center">
                                        <AlbumIcon />
                                    </TableCell>
                                    : null
                                }
                                {records.radiantDialog.enable ?
                                    <TableCell padding="none" style={{ cursor: hasDicom ? "pointer" : "" }} onClick={() => hasDicom && this.openDialog("radiant", row)} align="center">
                                        <RIcon style={{ width: 22 }} />
                                    </TableCell>
                                    : null
                                }
                                <TableCell padding="none" style={{ cursor: hasDicom ? "pointer" : "" }} onClick={(e) => hasDicom && this.downloadDicom(e, row)} align="center">
                                    {hasDicom
                                        ? (!row.zipStatus
                                            || ['Uploaded', 'Error'].indexOf(row.zipStatus) >= 0
                                            || (
                                                row.zipStatusLastUpdate
                                                && compareAsc(addMinutes(new Date(row.zipStatusLastUpdate), 15), new Date()) == -1
                                            ))
                                            ? <GetAppIcon style={{
                                                color: row.zipStatus === 'Error' || (row.zipStatus !== 'Uploaded' && row.zipStatusLastUpdate
                                                    && compareAsc(addMinutes(new Date(row.zipStatusLastUpdate), 15), new Date()) == -1) ? 'rgb(255, 193, 7)' : null
                                            }}></GetAppIcon>
                                            : <HourglassEmptyIcon style={{ color: '#fda700' }} title="The file is not ready." style={{ color: '#777777' }} />
                                        : <>
                                            <a href={"ipacs-importer://ui/accessionNumber?" + row.accessionNumber}>
                                                <CloudUploadIcon />
                                            </a>
                                            <br />
                                            {row.dicomAuditStatus}
                                        </>
                                    }
                                    <CircularProgress style={{ display: 'none' }} size={18} />
                                </TableCell>
                                <TableCell padding="none">
                                    {hasDicom ?
                                        <a
                                            href={
                                                "weasis://%24dicom%3Aget%20-w%20" +
                                                encodeURIComponent(
                                                    document.location.protocol +
                                                    "//" +
                                                    document.location.host +
                                                    "/weasis/" +
                                                    row.examId + `?token=${user.jwt}`
                                                )
                                            }
                                        >
                                            <VisibilityIcon />
                                        </a>
                                        : null}
                                </TableCell>
                            </>
                            : <TableCell colSpan={3 + (records.cdDialog.enable ? 1 : 0) + (records.radiantDialog.enable ? 1 : 0)} style={{ textAlign: 'center' }}><a
                                href={
                                    "ipacs-importer://ui/accessionNumber?" + row.accessionNumber
                                }
                            >
                                <CloudUploadIcon />
                            </a>
                                <br />
                                {row.dicomAuditStatus}
                            </TableCell>
                        : null}
                    {user.permission['EXAM_DICOM']
                        ? <TableCell padding="none">
                            {row.hasImage
                                ? row.dicomAuditStatus === 'Approved'
                                    ? <VerifiedUserIcon style={{ color: '#009624' }} />
                                    : row.dicomAuditStatus === 'Rejected'
                                        ? <WarningIcon style={{ color: '#f50057' }} />
                                        : dicomAuditing && !user.permission['EXAM_REPORT_APPROVE']
                                            ? <HourglassEmptyIcon style={{ color: '#fda700' }} />
                                            : null
                                : null
                            }
                            {row.hasImage && user.permission['EXAM_DICOM_DELETE'] ?
                                <DeleteIcon style={{ cursor: "pointer", color: '#f50057' }} onClick={() => this.deleteDicom(row)} />
                                : null}
                            {row.hasImage && user.permission['EXAM_DICOM_APPROVE'] && dicomAuditing ?
                                <CheckIcon title="APPROVE" style={{ cursor: "pointer", color: '#009624' }} onClick={() => this.approveDicom(row)} />
                                : null}
                            {row.hasImage && user.permission['EXAM_DICOM_APPROVE'] && dicomAuditing ?
                                <ClearIcon style={{ cursor: "pointer", color: '#f50057' }} onClick={() => this.rejectDicom(row)} />
                                : null}
                        </TableCell>
                        : null}
                    {user.permission['EXAM_REPORT_APPROVE'] || user.permission['EXAM_REPORT'] ?
                        <TableCell padding="none" style={{ cursor: "pointer", textAlign: 'center' }}>
                            {!hasReport ||
                                (hasReport
                                    && noReportAuditNeed
                                    || row.reportAuditStatus === 'Approved'
                                    || user.permission['EXAM_REPORT_APPROVE']
                                    || row.reviewerId === this.props.user.memberId
                                    || user.permission['EXAM_REPORT_EDIT'])
                                ? <TableChartIcon
                                    style={{
                                        color: row.isRead ? row.reportCount > 0 ? '#ffc107' : 'black' : row.reportCount > 1 ? '#dc3545' : '#28a745',
                                    }}
                                    onClick={() => this.openDialog("report", row)}
                                />
                                : null
                            }
                            {hasReport
                                ? row.reportAuditStatus === 'Approved'
                                    ? <VerifiedUserIcon style={{ color: '#009624' }} />
                                    : row.reportAuditStatus === 'Rejected'
                                        ? <WarningIcon style={{ color: '#f50057' }} />
                                        : reportAuditing && !user.permission['EXAM_REPORT_APPROVE']
                                            ? <HourglassEmptyIcon style={{ color: '#fda700' }} />
                                            : null
                                : null
                            }
                            {hasReport && user.permission['EXAM_REPORT_APPROVE'] && row.reportCount > 0 && reportAuditing ?
                                <CheckIcon style={{ cursor: "pointer", color: '#009624' }} onClick={() => this.approveReport(row)} />
                                : null}
                            {hasReport && user.permission['EXAM_REPORT_APPROVE'] && row.reportCount > 0 && reportAuditing ?
                                <ClearIcon style={{ cursor: "pointer", color: '#f50057' }} onClick={() => this.rejectReport(row)} />
                                : null}
                            {
                                !isReportAuditCompleted
                                    ? <div style={{ textAlign: 'center' }}>{row.reportAuditStatus}</div>
                                    : null
                            }
                        </TableCell>
                        : null
                    }
                    {/* {user.permission['EXAM_EDIT'] ?
                    <TableCell padding="none" style={{ cursor: "pointer" }} onClick={() => this.openEditDicomDialog(row.examId)} align="center">
                        <EditIcon />
                    </TableCell>
                    : null} */}

                    <TableCell padding="none" style={{ textAlign: 'left', cursor: "pointer", color: row.status === 'Rejected' ? 'red' : '' }} onClick={() => user.permission['EXAM_ASSIGN'] && !isSimple && this.openAssignDialog(row)} align="center" title={row.reviewerName}>
                        {user.permission['EXAM_ASSIGN'] ?
                            <>{!isSimple ?
                                (row.reviewerId ? <AssignmentIndIcon /> : <AssignmentIcon />)
                                : null
                            }</>
                            : null}
                        <span className={`${row.specificReviewerId && row.reviewerId === row.specificReviewerId ? 'specificDoctor' : ''}`}> {row.reviewerName}</span>
                    </TableCell>

                    <TableCell>
                        <span> {
                            user.permission['EXAM_REPORT_EDIT']
                                ? <>
                                    {row.latestReporter != row.reviewerName
                                        ? 'Edit by ' + row.latestReporter
                                        : ''
                                    }
                                    {row.isCheckReport ? <CheckIcon /> : null}
                                </>
                                : row.status
                        }</span>
                        {!isSimple
                            ? user.roleName === 'Doctor' && row.status === 'Assigned'
                                ? <BlockIcon title="Reject" style={{ cursor: 'pointer' }} onClick={() => this.rejectExam(row)} />
                                : user.permission['EXAM_DELETE'] && !row.accessionNumber
                                    ? <DeleteForeverIcon style={{ cursor: 'pointer', color: '#f50057' }} onClick={() => this.deleteExam(row)} />
                                    : null
                            : null
                        }
                    </TableCell>

                </TableRow>
            )
        };

        const checkDownloadToCD = async (examId) => {
            let checkResponse = await this.props.checkCDDownload(examId);
            if (checkResponse.data.success) {
                if (checkResponse.data.data === null)
                    setTimeout(() => checkDownloadToCD(examId), 1000);
                // else if(checkResponse.data.data==t)
            }
        }
        const checkDownloadToRadiant = async (examId) => {
            let checkResponse = await this.props.checkRadiantProgress(examId);
            if (checkResponse.data.success) {
                if (checkResponse.data.data === null)
                    setTimeout(() => checkDownloadToRadiant(examId), 1000);
                // else if(checkResponse.data.data==t)
            }
        }

        const handleDownloadToCD = async (examId) => {
            let response = await this.props.startCDDownload(examId);
            if (response.data.success) {
                checkDownloadToCD(examId);
            }
        }
        const handleDownloadToRadiant = async (examId) => {
            let response = await this.props.startRadiantDownload(examId);
            if (response.data.success) {
                checkDownloadToRadiant(examId);
            }
        }
        return (
            <Container className="Records">
                <UploadDocumentDialog
                    open={this.state.uploadDocumentDialog.open}
                    examId={this.state.uploadDocumentDialog.examId}
                    handleClose={this.closeDocumentDialog}
                    allowUpload={user.permission['EXAM_DOCUMENT_WRITE']}
                    type={this.state.uploadDocumentDialog.type || 'document'}
                    remark={this.state.uploadDocumentDialog.remark}
                    allergy={this.state.uploadDocumentDialog.allergy}
                />
                <EditDicomDialog
                    open={this.state.editDicomDialog.open}
                    examId={this.state.editDicomDialog.examId}
                    handleClose={this.closeEditDicomDialog}
                />
                <AssignDialog
                    open={this.state.assignDialog.open}
                    examId={this.state.assignDialog.examId}
                    exam={this.state.assignDialog.exam}
                    handleClose={this.closeAssignDialog}
                />

                <HistoryDialog
                    open={this.state.historyDialog.open}
                    examId={this.state.historyDialog.examId}
                    handleClose={this.closeHistoryDialog}
                    recordRenderer={renderRecordRow}
                    exam={this.state.historyDialog.exam}
                />

                <Dialog
                    open={this.state.acceptDialog.open}
                    onClose={() => this.handleCloseAcceptDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you confirm to accept case?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCloseAcceptDialog(false)} color="primary" autoFocus>
                            No
                        </Button>
                        <Button onClick={() => this.handleCloseAcceptDialog(true)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.cdDialog.open}
                    onClose={() => this.setState({ cdDialog: { open: false, examId: null } })}
                >
                    <DialogContent>
                        <DialogTitle>
                            Download DICOM to CD Folder
                        </DialogTitle>
                        <DialogContentText>
                            <p dangerouslySetInnerHTML={{ __html: records.cdDialog.data && records.cdDialog.data.message ? records.cdDialog.data.message.replaceAll('\r\n', '<br/>') : '' }}></p>
                            {records.cdDialog.data.data == true
                                ? <h4 style={{ color: 'green' }}>COMPLETED</h4>
                                : records.cdDialog.data.data === false
                                    ? <h4 style={{ color: 'red' }}>ERROR</h4>
                                    : null
                            }
                            {records.cdDialog.data.data === null ? <LinearProgress /> : null}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        {records.cdDialog.data.data !== true
                            ? <Button disabled={records.cdDialog.isLoading} onClick={() => handleDownloadToCD(this.state.cdDialog.examId)} color="primary">
                                Download
                            </Button>
                            : null}

                        <Button onClick={(() => this.setState({ cdDialog: { open: false, examId: null } }))} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.radiantDialog.open}
                    onClose={() => this.setState({ radiantDialog: { open: false, examId: null } })}
                >
                    <DialogContent>
                        <DialogTitle>
                            Download to RadiAnt
                        </DialogTitle>
                        <DialogContentText>
                            <p dangerouslySetInnerHTML={{ __html: records.radiantDialog.data && records.radiantDialog.data.message ? records.radiantDialog.data.message.replaceAll('\r\n', '<br/>') : '' }}></p>
                            {records.radiantDialog.data.data == true
                                ? <h4 style={{ color: 'green' }}>COMPLETED</h4>
                                : records.radiantDialog.data.data === false
                                    ? <h4 style={{ color: 'red' }}>ERROR</h4>
                                    : null
                            }
                            {records.radiantDialog.data.data === null ? <LinearProgress /> : null}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        {records.radiantDialog.data.data !== true
                            ? <Button disabled={records.radiantDialog.isLoading} onClick={() => handleDownloadToRadiant(this.state.radiantDialog.examId)} color="primary">
                                Start Download
                            </Button>
                            : null}

                        <Button onClick={(() => this.setState({ radiantDialog: { open: false, examId: null } }))} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <iframe name="_download" id="_download" style={{ display: 'none' }} />
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    {this.props.records.recordResult.isLoading || this.state.isLoading ? (
                        <CircularProgress />
                    ) : !this.props.records.recordResult.isLoaded ? null : this.props.records.recordResult.records
                        .length === 0 ? (
                        <div>No result.</div>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table size="small" style={{ minWidth: 800 }}>
                                <TableHead>
                                    <TableRow>
                                        {['Report Editor'].indexOf(user.roleName) < 0 ? <TableCell align="center" style={{ maxWidth: 100 }}>Count Down</TableCell> : null}
                                        {['Doctor', 'Report Editor'].indexOf(user.roleName) >= 0 ? <TableCell>สาขา</TableCell> : null}
                                        {['Doctor'].indexOf(user.roleName) >= 0 ? <TableCell>Age</TableCell> : null}
                                        <TableCell align="center">Accession</TableCell>
                                        <TableCell align="center" style={{ minWidth: 120 }}>Exam Date</TableCell>
                                        <TableCell>HN</TableCell>
                                        <TableCell>Patient Name</TableCell>
                                        <TableCell align="center">Modality</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell style={{ minWidth: 160 }}>Recieve On</TableCell>
                                        {user.permission['EXAM_DOCUMENT'] ? <TableCell align="center">Documents</TableCell> : null}
                                        {user.permission['EXAM_DOCUMENT'] ? <TableCell align="center">Film เก่า</TableCell> : null}
                                        {user.permission['EXAM_DICOM'] ? <TableCell align="center" colSpan={4 + (records.cdDialog.enable ? 1 : 0) + (records.radiantDialog.enable ? 1 : 0)}>Image</TableCell> : null}
                                        {user.permission['EXAM_REPORT'] ? <TableCell align="center" padding="none">Report</TableCell> : null}
                                        {/* {user.permission['EXAM_EDIT'] ? <TableCell align="center" padding="none"></TableCell> : null} */}
                                        {user.permission['EXAM_ASSIGN'] ? <TableCell padding="none">Doctor</TableCell> : null}
                                        <TableCell align="center" padding="none" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                    {user.roleName === 'Doctor' ?
                                        <>
                                            {this.props.records.recordResult.records.filter(x => x.status !== 'Completed' || ['WaitingForAudit', 'Rejected'].indexOf(x.reportAuditStatus) >= 0).map(row => renderRecordRow(row))}
                                            <TableRow>
                                                <TableCell colSpan={13}>
                                                    <Button
                                                        variant="text"
                                                        color="default"
                                                        startIcon={this.state.openCompleteSection ? <ExpandLess /> : <ExpandMore />}
                                                        onClick={() => { this.setState({ openCompleteSection: !this.state.openCompleteSection }); }}
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        {this.state.openCompleteSection ? 'Hide completed records' : 'Show completed records'}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            {this.state.openCompleteSection
                                                ? this.props.records.recordResult.records.filter(x => x.status === 'Completed' && ['WaitingForAudit', 'Rejected'].indexOf(x.reportAuditStatus) < 0).map(row => renderRecordRow(row))
                                                : null
                                            }
                                        </>
                                        : this.props.records.recordResult.records.map(row => renderRecordRow(row))
                                    }

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={12}
                                            count={this.props.records.recordResult.records.length}
                                            rowsPerPage={50}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            rowsPerPageOptions={[]}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.records,
        user: state.user
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(
    withRouter(RecordResult)
);
