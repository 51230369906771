import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from "../../../store/Billing";

import {
    Container,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Typography,
    MenuItem,
    InputLabel,
    LinearProgress
} from '@material-ui/core'

import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { startOfMonth } from 'date-fns'

export function BillingSearchCondition({ search, billing, fetchUIData, changeCondition, fetchHistory, fetchPrivilages,clearResult }) {

    const [condition, setCondition] = useState({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
        hospital: null,
        privilageId: null
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        search(1, condition)
    };

    const inputChangeHandler = (e) => {
        const newCondition = { ...condition, [e.target.name]: e.target.value };
        setCondition(newCondition)
        changeCondition(newCondition)
        search(1, newCondition)
        if (e.target.name === 'hospital') {
            fetchHistory(1, e.target.value)
            fetchPrivilages(e.target.value)
        }
    }

    const inputChangeHandlerCustom = (name, value) => {
        const newCondition = { ...condition, [name]: value };
        setCondition(newCondition);
        changeCondition(newCondition)
        search(1, newCondition)
    }

    useEffect(() => {
        clearResult();
        fetchUIData();
    }, [])

    return (
        <Container className="Records">
            <form onSubmit={handleSubmit}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">
                        <Typography></Typography>
                    </FormLabel>
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel shrink variant="filled" htmlFor="hospital">
                                    โรงพยาบาล
                                </InputLabel>
                                <TextField variant="filled"
                                    select
                                    value={condition.hospital}
                                    onChange={inputChangeHandler}
                                    name="hospital"
                                    required
                                    disabled={billing.isLoading}
                                >
                                    {billing.conditionUIData.hospital &&
                                        billing.conditionUIData.hospital.map(x => (
                                            <MenuItem key={x.key} value={x.key}>
                                                {x.value}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel shrink variant="filled" htmlFor="privilageId">
                                    สิทธิ์
                                </InputLabel>
                                <TextField variant="filled"
                                    select
                                    value={condition.privilageId}
                                    onChange={inputChangeHandler}
                                    name="privilageId"
                                    disabled={billing.isLoading}
                                    required
                                >
                                    <MenuItem value={null}>
                                        ทุกสิทธิ์
                                    </MenuItem>
                                    {billing.conditionUIData.privilages &&
                                        billing.conditionUIData.privilages.map(x => (
                                            <MenuItem key={x.key} value={x.key}>
                                                {x.value}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={3}>
                                <KeyboardDatePicker
                                    inputVariant="filled"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="จากวันที่"
                                    value={condition.startDate}
                                    onChange={(v) => inputChangeHandlerCustom("startDate", v)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    disabled={billing.isLoading}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoOk={true} fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <KeyboardDatePicker
                                    inputVariant="filled"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="ถึงวันที่"
                                    value={condition.endDate}
                                    onChange={(v) => inputChangeHandlerCustom("endDate", v)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={billing.isLoading}
                                    autoOk={true} fullWidth
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                    </Grid>
                </FormControl>
            </form>
            {billing.conditionUIData.isLoading ? <LinearProgress /> : null}
        </Container>
    );
}
function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(BillingSearchCondition);