import axios from "axios";
import swal from 'sweetalert'
const requestMenuType = 'REQUEST_MENU';
const receiveMenuType = 'RECIEVE_MENU';

const initialState = {
    menu:[],
    currentMenu: ''
}
axios.interceptors.response.use(
    async (response) => {

        if (response.data && response.data.success == false) {
            swal("", response.data.message || "Something went wrong!", "error");
        }

        return response
    },
    (error) => {
        if(error.response.status == 401)
        {
            window.location.href = '/Member/LogOn?ReturnUrl='+window.location.pathname;
        }
        else if (error.response && error.response.data) {
            swal("", (error.response.data.errors && error.response.data.errors.length? '\n'+ [...new Set(error.response.data.errors.map(x=>x.defaultMessage))].join('\n'):error.response.data.message), "error");
        }
    });
export const actionCreators = {
    fetchMenu: () => async (dispatch) => {
        dispatch({ type: requestMenuType })

        let response = await axios.get('api/uidata/menu')

        dispatch({ type: receiveMenuType, data: response.data })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type){
        case receiveMenuType:
            return { ...state, menu: action.data.filter(x=>x.permissionCode.indexOf('accounting_')<0) };
        default:
            return state;
    }
};
