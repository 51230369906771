import React from 'react'
import {
    Grid,
    TextField,
} from "@material-ui/core";

export default function PatientAddressInput({ fieldName, address = {}, onAddressChange ,disabled}) {
    address = address || {};
    const editable = !address.addressId;
    const onClickBox = (e) => {
        if (address.addressId){
            onAddressChange({ target: { name: `${fieldName}.addressId`, value: null } });
        }
    }
    return <Grid
            onClick={onClickBox}
            style={{cursor:editable?'':'pointer'}}
            title={editable?'':'Click to enable editing'}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}

        >
            <Grid item lg={1}>
                <TextField variant="filled"
                    fullWidth
                    label="เลขที่"
                    name={`${fieldName}.no`}
                    value={address.no}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={1}>
                <TextField variant="filled"
                    fullWidth
                    label="หมู่ที่"
                    name={`${fieldName}.moo`}
                    value={address.moo}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="หมู่บ้าน"
                    name={`${fieldName}.village`}
                    value={address.village}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="ซอย"
                    name={`${fieldName}.alley`}
                    value={address.alley}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="ถนน"
                    name={`${fieldName}.road`}
                    value={address.road}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="ตำบล/แขวง"
                    name={`${fieldName}.subDistrict`}
                    value={address.subDistrict}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="อำเภอ/เขต"
                    name={`${fieldName}.district`}
                    value={address.district}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="จังหวัด"
                    name={`${fieldName}.province`}
                    value={address.province}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
            <Grid item lg={3}>
                <TextField variant="filled"
                    fullWidth
                    label="รหัสไปรษณีย์"
                    name={`${fieldName}.postCode`}
                    value={address.postCode}
                    margin="normal"
                    onChange={onAddressChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={disabled || !editable}
                />
            </Grid>
        </Grid>
}