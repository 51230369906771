
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Patient";
import QRCode from 'qrcode'
import swal from "sweetalert";

import useInterval from "use-interval";



export function QRDialog({ onClose, open, qrId, onSubmit, checkQr }) {

    const [qrUrl, setQrUrl] = useState('');

    const handleClose = () => {
        swal({
            title: "ยกเลิกการรับภาพจากโทรศัพท์",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    onClose();
                } else {

                }
            });
    };



    const onEnterHandle = async () => {
        setQrUrl(await QRCode.toDataURL(`${window.location.protocol}//${window.location.host}/capture/qr/${qrId}`))



    }
    let busy = false;
    useInterval(async () => {
        if (busy || !qrId)
            return;
        busy = true;
        let qr = await checkQr(qrId);
        if (qr.data.success && qr.data.data) {
            onSubmit(qr.data.data)
            onClose();
        }
        busy = false;
    }, 1000)
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onEnter={onEnterHandle}
        >
            <DialogTitle>แสกน QR Code เพื่อถ่ายภาพ</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <img src={qrUrl} />
            </DialogContent>
        </Dialog>
    );
}


function mapStateToProps(state) {
    return {
        patient: state.patient
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(QRDialog);
