import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Root, Header, Nav, Content } from '../layout';
import NavContent from './NavContent'
import HeaderContent from './HeaderContent'
import NavHeaderContent from './NavHeaderContent'
const config = {
    "navAnchor": "left",
    "navVariant": {
        "xs": "temporary",
        "sm": "temporary",
        "md": "temporary"
    },
    "navWidth": {
        "xs": 240,
        "sm": 256,
        "md": 256
    },
    "collapsible": {
        "xs": false,
        "sm": false,
        "md": true
    },
    "collapsedWidth": {
        "xs": 64,
        "sm": 64,
        "md": 64
    },
    "clipped": {
        "xs": false,
        "sm": false,
        "md": false
    },
    "headerPosition": {
        "xs": "relative",
        "sm": "relative",
        "md": "relative"
    },
    "squeezed": {
        "xs": false,
        "sm": false,
        "md": true
    },
    "footerShrink": {
        "xs": false,
        "sm": false,
        "md": true
    }
};

export default props => (
    <Root config={config} style={{ height: "100%" }}>
        <CssBaseline />
        <Header
            menuIcon={{
                inactive: <MenuIcon />,
                active: <ChevronLeftIcon />
            }}
        >
            <HeaderContent section={props.section} />
        </Header>
        <Nav
            collapsedIcon={{
                inactive: <ChevronLeftIcon />,
                active: <ChevronRightIcon />
            }}
            header={
                // you can provide fixed header inside nav
                // change null to some react element
                ctx => <NavHeaderContent />
            }
        >
            <NavContent section={props.section}/>
        </Nav>
        <Content style={{ height: 'calc(100% - 65px)', padding: 5 }}>
                {props.children}
        </Content>
        {/* <Footer><p>Footer</p></Footer> */}
  </Root>
);
