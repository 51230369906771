import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash'
import axios from 'axios'
import {
    TextField,
    Button,
    LinearProgress,
    Grid,
    Typography,
    Paper,
    ButtonGroup,
    Popper
} from '@material-ui/core';
import { format } from 'date-fns'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Attachment } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import ScannerIcon from '@material-ui/icons/Scanner';
import { actionCreators } from '../../store/Uploader';

export function Uploader({ classes, handleClose, children, uploadDocument, onUploaded,
    autoUpload, allowUpload, filenameOptions, allowScan, checkScanner, isScanning, scan,
    check, accept }) {

    const [fileModel, setFileModel] = useState({
        uploading: false,
        fileName: '',
        file: null,
        fileError: false,
        errorText: null
    });

    const [forceFileName, setForceFileName] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            let file = e.target.files[0];
            setFileModel({
                ...fileModel,
                file: file,
                fileName: file.name,
                fileError: false
            });
            if (autoUpload)
                await actualUpload(file, forceFileName ? `${forceFileName}.${file.name.split('.').pop()}` : file.name)
        }
    }

    const inhandleClose = e => {
        setFileModel({
            uploading: false,
            fileName: '',
            file: null,
            fileError: false,
            errorText: null
        });

        handleClose && handleClose();
    }

    const actualUpload = async (file, fileName) => {
        if (file != null) {
            setFileModel({ ...fileModel, file, fileName, fileError: false, uploading: true ,errorText:''});

            // let file = await fileToBase64(this.state.file);
            let success = false;
            let errorMessage = 'Unable to upload document, please try again later!';
            // file = file.replace(/^data:.*\/.*;base64,/, ''); // Remove header portion.
            try {
                let response = await uploadDocument(fileName, file);
                success = response.data && response.data.success;
                if (!success)
                    errorMessage = response.data.message;
                else
                    onUploaded && onUploaded(response.data);
            } catch (err) {
                success = false;
                if (err.response)
                    errorMessage = err.response.data.message || err.message;
            }

            setFileModel({ ...fileModel, uploading: false });

            if (fileInput.current)
                fileInput.current.value = '';

            if (success) {
                handleClose && handleClose();
                setFileModel({ ...fileModel, errorText: '' });
            } else {
                setFileModel({ ...fileModel, errorText: errorMessage });
            }

        } else {
            setFileModel({ ...fileModel, fileError: true });
        }
    }

    const handleUpload = async e => {
        if (fileModel.file != null) {
            await actualUpload(fileModel.file, forceFileName ? `${forceFileName}.${fileModel.fileName.split('.').pop()}` : fileModel.fileName);
        };
    }
    const handleClickUpload = (event) => {
        if (filenameOptions && filenameOptions.length) {
            setAnchorEl(event.currentTarget);
            setOpen(event.currentTarget != anchorEl || !open);
        }
        else fileInput.current.click();
    }


    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    const startScan = async (name) => {
        var scanId = await scan();
        let intervalId = setInterval(async () => {
            try {
                let checkResult = await check(scanId);
                if (checkResult.status === 'Completed') {
                    clearInterval(intervalId);

                    if (checkResult.data.length > 0) {
                        let contentType = 'image/jpg';
                        let b64Data = checkResult.data[0]
                        const blob = b64toBlob(b64Data, contentType);

                        actualUpload(blob, name ? `${name}.jpg` : 'Scan_' + format(new Date(), 'yyyyMMddHHmmss') + '.jpg');
                    }
                }
            } catch (e) {
                alert(e.message);
            }
        }, 2000)
    }

    const handleClickScan = async (event) => {
        if (filenameOptions && filenameOptions.length) {
            setAnchorEl(event.currentTarget);
            setOpen(event.currentTarget != anchorEl || !open);
        } else {
            await startScan();
        }
    }

    const handleClickFileLabel = (x) => {
        return async (e) => {
            setForceFileName(x);
            // setOpen(false);
            if (anchorEl.name == 'scan') {
                e.preventDefault();
                startScan(x);
                return false;
            }
            return true;
        }
    }

    const [id,setId] = useState('uploader_'+_.uniqueId())
    useEffect(() => {
        checkScanner();
    }, [])

    let fileInput = useRef();
    return <Grid
        container
        spacing={2}
        alignItems="center"
    >
        {!allowUpload ? null
            : <>
                {filenameOptions && filenameOptions.length
                    ? <Popper open={open} placement="right" anchorEl={anchorEl}>
                        <Paper>
                            <ButtonGroup
                                orientation="vertical"
                                color="primary"
                                variant="text"
                            >
                                {filenameOptions.map(x =>
                                    <Button key={x} component="label" htmlFor={id} onClick={handleClickFileLabel(x)}>{x}</Button>
                                )}
                                <Button key={'Others'} component="label" htmlFor={id} onClick={handleClickFileLabel(null)}>อื่นๆ</Button>
                            </ButtonGroup>
                        </Paper>
                    </Popper>
                    : null
                }
                <Grid item xs={5}>
                    <ButtonGroup fullWidth={true} variant="outlined" color="primary" aria-label="split button">
                        <Button
                            onClick={handleClickUpload}
                            disabled={fileModel.uploading || isScanning}
                            fullWidth={true}
                        >
                            Select file:
                        <input
                                id={id}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                ref={fileInput}
                                accept={accept||'*.*'}
                            />
                        </Button>
                        {allowScan
                            ? <Button
                                style={{ width: 20 }}
                                onClick={handleClickScan}
                                disabled={fileModel.uploading || isScanning}
                                name='scan'
                            >
                                <ScannerIcon />
                            </Button>
                            : null
                        }
                    </ButtonGroup>
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        label="File"
                        InputProps={{
                            readOnly: true
                        }}
                        fullWidth={true}
                        value={fileModel.fileName}
                        error={fileModel.fileError}
                        helperText="Please select file."
                    />
                </Grid>
                {(fileModel.uploading || isScanning) &&
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                }
                {fileModel.errorText != null &&
                    <Grid item xs={12}>
                        <Typography color="error">{fileModel.errorText}</Typography>
                    </Grid>
                }
                <Grid item xs={7}></Grid>
                <Grid item xs={2}>
                    {handleClose &&
                        <Button onClick={inhandleClose} disabled={fileModel.uploading || isScanning}>Cancel</Button>
                    }
                </Grid>
                <Grid item xs={3}>
                    {!autoUpload &&
                        <Button
                            onClick={handleUpload}
                            disabled={fileModel.uploading || isScanning}
                            color="primary"
                        >
                            Upload
                            <CloudUploadIcon color="primary" className={classes.rightIcon} />
                        </Button>
                    }
                </Grid>
            </>
        }
        {children}
    </Grid>
}

function mapStateToProps(state) {
    return {

    };
}
const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});
export default connect(state => state.uploader, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(Uploader));
