import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'

const filter = createFilterOptions();

export const ResearchProjectDialog = ({ isOpen, onClose, onSubmit, data, options }) => {
    const [form, setForm] = useState({researchProjectId:null,researchProjectSubjectId:null,researchProjectSubjectName:null,researchProjectVN:null})
    const onLoad = () => {
        setForm(data);console.log(1)
    }
    const handleClickSaveButton = () => {
        onSubmit(form);
    }
    const handleChange = (name) => (e) => {
        setForm({ ...form, [name]: e.target.value })
    }
    const handleChangeProject = (name) => (e, option) => {
        var updateState = {};
        updateState[name] = option;
        if (option) {
            if (option.id)
                updateState[name] = option.id;
            else {
                updateState[name] = undefined;
                updateState[name + '_add'] = option.inputValue;
            }
        } else {
            updateState[name] = undefined;
            updateState[name + '_add'] = undefined;
        }

        setForm({ ...form, ...updateState })
    }
    const selected = options?.find(x=>x.id==form.researchProjectId)
    return <Dialog
        open={isOpen}
        onClose={onClose}
        onEnter={onLoad}
        maxWidth="xs"
        fullWidth={true}
    >
        <DialogTitle>โปรเจควิจัย</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={4}>
                    Project
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                        value={selected || form.researchProjectId || form.researchProjectId_add || null}
                        freeSolo
                        options={options}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.name;
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (filtered.length==0 && params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `เพิ่ม "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        renderOption={(option) => option.name}
                        onChange={handleChangeProject('researchProjectId')}
                        selectOnFocus
                        clearOnBlur
                        renderInput={(params) => (
                            <TextField {...params}  />
                        )}
                    />

                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    Subject ID
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        value={form.researchProjectSubjectId}
                        onChange={handleChange('researchProjectSubjectId')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    Subject Name
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        value={form.researchProjectSubjectName}
                        onChange={handleChange('researchProjectSubjectName')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    VN
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        value={form.researchProjectVN}
                        onChange={handleChange('researchProjectVN')}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            {/* <Button onClick={onClose}>
                ปิด
            </Button> */}
            <Button
                onClick={handleClickSaveButton}
                color="primary"
            >
                บันทึก
            </Button>
        </DialogActions>
    </Dialog >
}

export default ResearchProjectDialog