import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Records';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    LinearProgress,
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    FormHelperText,
    Typography,
    Collapse,
    Input,
    InputAdornment,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

const filter = createFilterOptions();

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});

class AssignDialog extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            exam: {},
            doctors: [],
            assignTo: null,
            assignResult: {},
            showLog: false,
            isSpecific: false,
            isOnDuty: false,
            isChainCase: false,
            message: ''
        };

        this.handleEnter = this.handleEnter.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleEnter() {
        await this.props.getAssignableDoctor(this.props.exam.modality);
        await this.props.getBranchDutyDoctor(this.props.exam.branchId);
        let updateState = {
            _assignTo: null,
            assignTo: this.props.exam.reviewerId,
            isSpecific: this.props.exam.reviewerId == this.props.exam.specificReviewerId,
            isOnDuty: this.props.exam.reviewerId && this.props.dutyDoctor && this.props.dutyDoctor.data
                && this.props.dutyDoctor.data.doctorId == this.props.exam.reviewerId,
            isChainCase: this.props.exam.isChainCase
        };
        if (updateState.assignTo)
            this.setState(updateState);
        else this.setState({ _assignTo: null });
    }

    handleClose() {
        this.setState({
            exam: {},
            doctors: [],
            assignTo: null,
            assignResult: {},
            withInDay: null,
            withInHour: null,
            isSpecific: false,
            message: ''
        });
        this.props.handleClose();
    }

    async handleSave() {
        let response = await this.props.assignCase(this.props.examId, this.state.assignTo, this.state.withInDay, this.state.withInHour, this.state.isSpecific, this.state.isOnDuty, this.state.isChainCase, this.state.message);
        this.setState({ assignResult: response.data });
        if (response.data)
            this.handleClose();
    }

    handleChange(e) {
        switch (e.target.name) {
            case 'withInDay':
            case 'withInHour':
                if (parseInt(e.target.value) < 0)
                    e.target.value = 0;
                break;
            case 'isSpecific':
            case 'isOnDuty':
            case 'isChainCase':
                this.setState({ [e.target.name]: e.target.checked });
                return;
            case '_assignTo':
                this.setState({
                    [e.target.name]: e.target.value,
                    ['assignTo']: e.target.value ? e.target.value.doctorId : null,
                    isOnDuty: this.props.dutyDoctor && this.props.dutyDoctor.data && e.target.value.doctorId == this.props.dutyDoctor.data.doctorId
                });
                return;
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const {
            classes,
            open,
            examId,
            exam,
            assignableDoctor,
            dutyDoctor,
            user
        } = this.props;

        if (exam == null)
            return null;
        return (
            <Dialog
                open={open}
                onEnter={this.handleEnter}
                onClose={this.handleClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Assign Case</DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <InputLabel>Name</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            {this.props.exam.patientName}
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Modality</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            {this.props.exam.modality}
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Assign To</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                {/* <FormControl fullWidth> */}
                                <Autocomplete
                                    value={this.state._assignTo || assignableDoctor.doctors.find(x => x.doctorId == this.state.assignTo) || null}
                                    options={assignableDoctor.doctors.sort((a, b) => a.doctorName.localeCompare(b.doctorName))}
                                    getOptionLabel={(option) => {
                                        return option.doctorName;
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        return filtered;
                                    }}
                                    renderOption={(option) => option.doctorName}
                                    onChange={(e, option) => {
                                        this.handleChange({ target: { name: '_assignTo', value: option } })
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )} name="assignTo"
                                />
                                {/* </FormControl> */}
                                {/* <Select value={this.state.assignTo} name="assignTo" onChange={this.handleChange} displayEmpty className={classes.selectEmpty}>
                                    <MenuItem value={null}>
                                        <em>None</em>
                                    </MenuItem>
                                    {assignableDoctor.doctors.sort((a, b) => a.doctorName.localeCompare(b.doctorName)).map(x =>
                                        <MenuItem value={x.doctorId}>{x.doctorName}</MenuItem>)
                                    }
                                </Select> */}
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                        {this.state.assignTo ?
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox name='isSpecific' checked={this.state.isSpecific} onChange={this.handleChange} />} label="RQ" title="Specific Doctor" />
                                </FormGroup>
                            </Grid>
                            : null
                        }
                        {this.state.assignTo ?
                            <>
                                <Grid item xs={4}>
                                    <InputLabel>แพทย์เวร</InputLabel>
                                </Grid>
                                <Grid item container xs={8} spacing={2}>
                                    <FormControlLabel control={<Checkbox name='isOnDuty' checked={this.state.isOnDuty || false} onChange={this.handleChange} />} label="แพทย์เวรประจำวัน" title="แพทย์เวรประจำวัน" />
                                </Grid>
                            </>
                            : null}
                        {this.state.assignTo ?
                            <>
                                <Grid item xs={4}>
                                    <InputLabel></InputLabel>
                                </Grid>
                                <Grid item container xs={8} spacing={2}>
                                    <FormControlLabel control={<Checkbox name='isChainCase' checked={this.state.isChainCase || false} onChange={this.handleChange} />} label="เคสเครือข่าย" title="โรงพยาบาลในเครือข่ายเป็นผู้จ่ายค่าแพทย์อ่าน" />
                                </Grid>
                            </>
                            : null}
                        {this.state.assignTo ?
                            <>
                                <Grid item xs={4}>
                                    <InputLabel>Expect report in</InputLabel>
                                </Grid>
                                <Grid item container xs={8} spacing={2}>
                                    <Grid item xs={3}>
                                        <Input
                                            style={{ textAlign: 'center' }}
                                            type="number"
                                            name="withInDay"
                                            value={this.state.withInDay}
                                            onChange={this.handleChange}
                                            endAdornment={<InputAdornment position="end">Day</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input
                                            style={{ textAlign: 'center' }}
                                            type="number"
                                            name="withInHour"
                                            value={this.state.withInHour}
                                            onChange={this.handleChange}
                                            endAdornment={<InputAdornment position="end">Hr</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* ({format()}) */}
                                    </Grid>
                                </Grid>
                            </>
                            : null}
                        <Grid item xs={4}>
                            <InputLabel>Message</InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                value={this.state.message}
                                options={[]}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    return filtered;
                                }}
                                onChange={(e, option) => {
                                    this.handleChange({ target: { name: 'message', value: option } })
                                }}
                                selectOnFocus
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )} name="message"
                                freeSolo={true}
                                fullWidth
                                onInputChange={(e, option) => {
                                    if(e && e.target && e.target.value)
                                        this.handleChange({ target: { name: 'message', value: e.target.value } })
                                }}
                            />
                        </Grid>
                        {assignableDoctor.isLoading || dutyDoctor.isLoading ?
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                            : null}
                        {!this.state.assignResult.sucess ?
                            <Grid item xs={12}>
                                <Typography color="error">{this.state.assignResult.message}</Typography>
                            </Grid>
                            : null}
                        {exam.log ? <>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Button
                                    style={{ color: '#888888' }}
                                    onClick={() => { this.setState({ showLog: !this.state.showLog }) }}
                                >
                                    Log
                                    {this.state.showLog ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </Grid>
                            <Collapse in={this.state.showLog} timeout="auto" unmountOnExit>
                                {exam.log.split('\r\n').map(x => <div>{x}</div>)}
                            </Collapse>
                        </>
                            : null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    {/* {user.memberId === this.props.exam.reviewerId
                        ? <Button
                            onClick={this.handleAccept}
                            color="primary"
                        >
                            Accept
                            <SaveIcon color="primary" className={classes.rightIcon} />
                        </Button>
                        : <Button
                            onClick={this.handleSave}
                            color="primary"
                            disabled={this.state.assignTo === this.props.exam.reviewerId}

                        >
                            Assign
                        <SaveIcon color="primary" className={classes.rightIcon} />
                        </Button>
                    } */}
                    <Button
                        onClick={this.handleSave}
                        color="primary"
                        disabled={
                            (
                                this.state.assignTo === this.props.exam.reviewerId
                                && (this.state.isSpecific == (this.props.exam.reviewerId === this.props.exam.specificReviewerId))
                            )
                            && this.state.isChainCase == this.props.exam.isChainCase
                            && (this.state.isOnDuty == (this.props.exam.reviewerId && this.props.dutyDoctor && this.props.dutyDoctor.data
                                && this.props.dutyDoctor.data.doctorId == this.props.exam.reviewerId))
                            && this.state.message == ''}
                    >
                        Assign
                        <SaveIcon color="primary" className={classes.rightIcon} />
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

function mapStateToProps(state) {
    return {
        assignableDoctor: state.records.assignableDoctor,
        user: state.user,
        dutyDoctor: state.records.dutyDoctor
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(AssignDialog));
