import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import _, { object } from 'underscore'
import axios from 'axios'
import swal from 'sweetalert'
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { actionCreators } from "../../store/Request";
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert';
import thLocal from "date-fns/locale/th";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Button,
    Grid,
    DialogActions,
    Radio,
    RadioGroup,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    MenuItem,
    InputAdornment,
    Chip,
    DialogTitle,
    DialogContent,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Input,
    Select,
    LinearProgress
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save'
import HistoryIcon from '@material-ui/icons/History';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/Attachment'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { format, addYears, parse } from 'date-fns'
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { startOfDay, endOfToday, subDays } from "date-fns";

import Uploader from '../Shared/Uploader'
import ResearchProjectDialog from './ResearchProejctDialog'
import { AdditionalFieldForm } from '../Shared/AdditionalFieldForm';

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 20
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    documentBox:{
        margin:11, 
        width:'100%',
        "&>li":{
            width: '49%',
            float:'left'
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ padding: 0 }}>{children}</Box>}
        </Typography>
    );
}

export function NewRequest({ request, fetchSearchConditionUIData, uploadDocument, history, match, getPatientById,
    clearNewRequestForm, createRequest, getRequestById, getDocumentByIds, updateRequest, user, getDeleteReason, deleteRequest,
    getPrivilagePrice, changeAdditionalFieldVisibility, changePatientImage
}) {
    const classes = useStyles();


    const [requestForm, setRequestForm] = useState({
        requestId: null,
        modalities: {},
        patientId: match.params.patientId,
        scheduleDateTime: new Date(new Date().setMinutes(Math.round(new Date().getMinutes() / 10) * 10)),
        examStartTime: null,
        examEndTime: null,
        allergy: [],
        hospitalId: null,
        privilageId: null,
        paymentMethodId: null,
        hospitalDoctorId: null,
        hospitalDoctor: { id: '', name: '' },
        hospitalBuildingId: null,
        hospitalBuilding: { id: '', name: '' },
        uploadDocuments: {},//{'CT': [documentId]}
        documentIds: [],
        productQuantity: {},
        productDose: {},
        forceAcceesionNumber: '',
        researchProjectId: null,
        researchProjectSubjectId: '',
        researchProjectSubjectName: '',
        researchProjectVN: '',
        remark: '',
        additionalFields: {},
        imageList: []
    })

    const [isNshoEnable, setIsNshoEnable] = useState(false)
    const nshoServiceUrl = 'http://localhost:8189';
    useEffect(() => {
        checkAgent()
    }, [])

    const checkAgent = async () => {
        let checkNhsoAgent = await axios.get(nshoServiceUrl + '/api/environment')
        if (checkNhsoAgent && checkNhsoAgent.data && checkNhsoAgent.data.serviceUrl) {
            setIsNshoEnable(true)
        }
    }

    const [notifyMessage, setNotifyMessage] = useState(null)
    useEffect(() => {
        if (notifyMessage) {
            setTimeout(() => { setNotifyMessage(null) }, 10000)
        }
    }, [notifyMessage])

    const [isSchedule, setIsSchedule] = useState(false)
    const [branchId, setBranchId] = useState(null);
    const handleEnter = async () => {
        // if (!request.conditionUIData.isLoaded)
        let conditionUIDataResponse = fetchSearchConditionUIData();
        if (match.params.patientId){
            setBranchId(user.branchId);
            getPatientById(match.params.patientId).then(response => {
                if (response.data.success) {
                    setRequestForm({ ...requestForm, allergy: response.data.data.allergy })
                }
            });
        }
        else if (match.params.requestId && match.params.requestId !== 'new') {
            getRequestById(match.params.requestId).then(async response => {

                if (response.data.success) {
                    const editInfo = { ...response.data.data.requestInfo };
                    editInfo.uploadDocuments = {};
                    editInfo.modalities = {};
                    setIsSchedule(true);
                    setBranchId(editInfo.branchId);
                    editInfo.scheduleDateTime = editInfo.appointmentDate;
                    editInfo.examStartTime = editInfo.examStartTime ? parse(editInfo.examStartTime, 'HH:mm:ss', new Date()) : null;
                    editInfo.examEndTime = editInfo.examEndTime ? parse(editInfo.examEndTime, 'HH:mm:ss', new Date()) : null;

                    if (editInfo.hospitalId && editInfo.privilageId) {
                        getPrivilagePrice(editInfo.hospitalId, editInfo.privilageId);
                    }

                    if (response.data.data.requestInfo.documentIds.length) {
                        let fileResponse = await getDocumentByIds(response.data.data.requestInfo.documentIds);
                        editInfo.documentIds = fileResponse.data.data;
                    }

                    for (let p in response.data.data.requestInfo.modalities)
                        if (response.data.data.requestInfo.modalities.hasOwnProperty(p)) {
                            editInfo.modalities[p.toUpperCase()] = response.data.data.requestInfo.modalities[p];
                        }

                    for (let quan in response.data.data.requestInfo.productQuantity) {
                        if (response.data.data.requestInfo.productQuantity.hasOwnProperty(quan)) {
                            for (let m in editInfo.modalities)
                                if (editInfo.modalities.hasOwnProperty(m))
                                    for (let i = 0; i < editInfo.modalities[m].length; i++)
                                        if (editInfo.modalities[m][i].toUpperCase() == quan.toUpperCase() && editInfo.modalities[m][i] != quan) {
                                            editInfo.productQuantity[editInfo.modalities[m][i]] = editInfo.productQuantity[quan];
                                            delete editInfo.productQuantity[quan];


                                        }
                        }
                    }

                    for (let dose in response.data.data.requestInfo.productDose) {
                        if (response.data.data.requestInfo.productDose.hasOwnProperty(dose)) {
                            for (let m in editInfo.modalities)
                                if (editInfo.modalities.hasOwnProperty(m))
                                    for (let i = 0; i < editInfo.modalities[m].length; i++)
                                        if (editInfo.modalities[m][i].toUpperCase() == dose.toUpperCase() && editInfo.modalities[m][i] != dose) {
                                            editInfo.productDose[editInfo.modalities[m][i]] = editInfo.productDose[dose];
                                            delete editInfo.productDose[dose];
                                        }
                        }
                    }

                    for (let field in response.data.data.requestInfo.additionalFields) {
                        if (response.data.data.requestInfo.additionalFields.hasOwnProperty(field)) {
                            for (let m in editInfo.modalities) {
                                let mProducts = (await conditionUIDataResponse).data.modalityProducts.find(x => x.modalityCode == m)
                                if (editInfo.modalities.hasOwnProperty(m) && mProducts)
                                    for (let i = 0; i < editInfo.modalities[m].length; i++) {

                                        let p = mProducts.products.find(x => x.productId == editInfo.modalities[m][i])
                                        if (p && p.additionalFields && p.additionalFields.length) {

                                            for (let j = 0; j < p.additionalFields.length; j++) {
                                                let af = p.additionalFields[j];
                                                if (af.id === field.toUpperCase() && af.id !== field) {
                                                    editInfo.additionalFields[af.id] = editInfo.additionalFields[field];
                                                    delete editInfo.additionalFields[field];
                                                }
                                            }
                                        }
                                    }
                            }
                        }
                    }

                    for (let p in response.data.data.requestInfo.uploadDocuments)
                        if (response.data.data.requestInfo.uploadDocuments.hasOwnProperty(p)) {
                            let x = response.data.data.requestInfo.uploadDocuments[p];
                            if (x.documentId.length)
                                getDocumentByIds(x.documentId).then(docsResponse => {
                                    editInfo.uploadDocuments[x.modality] = docsResponse.data.data;
                                    setRequestForm(editInfo);
                                })
                            else
                                setRequestForm(editInfo);
                        }

                }
            })

        }
    }

    const handleClose = () => {
        clearNewRequestForm();
        history.push('/request')
    }

    const additionalFields = request.conditionUIData.modalityProducts
        .filter(x => requestForm['modalities'][x.modalityCode] && requestForm['modalities'][x.modalityCode].length > 0)
        .map(x =>
            requestForm['modalities'][x.modalityCode]
                .map(id => {
                    let product = x.products.find(x => x.productId == id)
                    if (product.additionalFields && product.additionalFields.length)
                        return product.additionalFields;
                    else return null;
                })
        ).flatMap(x => x.filter(y => y).flatMap(z => z))
        .filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.id === obj.id && t.name === obj.name
            ))
        ).sort((x, y) => x.orderNumber > y.orderNumber ? 1 : -1)

    const handleCheckboxChange = (fieldName, key) => e => {
        let newList = { ...requestForm[fieldName] };
        if (e.target.checked)
            newList = { ...requestForm[fieldName], [key]: [...requestForm[fieldName][key] || [], e.target.value] };
        else {
            newList = { ...requestForm[fieldName], [key]: [...(requestForm[fieldName][key] || []).filter(x => x != e.target.value)] }
        }

        handleChangeFormValue(fieldName, newList);
    }

    const handleChangeFormValue = (fieldName, value) => {
        switch (fieldName) {
            case 'hospitalId':
                setRequestForm({ ...requestForm, [fieldName]: value, privilageId: null, hospitalBuilding: null, hospitalBuildingId: null })
                break;
            case 'modalities':

                const selectedProducts = _.flatten(_.values(value));

                const customProductPrice = requestForm.customProductPrice || {};
                const oCustomPriceList = (customProductPrice.items || []);
                const newCustomPriceList = oCustomPriceList.filter(x => selectedProducts.indexOf(x.productId) >= 0)

                const reserveFieldId = ['height', 'weight', 'bun', 'creatinine', 'egfr', 'blood_test_date_bun', 'blood_test_date_creatinine', 'blood_test_date_egfr', 'nhso_auth_code', 'send_doctor_work_group']
                let additionalFields = requestForm.additionalFields || {};
                for (let p in additionalFields) {
                    if (reserveFieldId.indexOf(p) < 0 && additionalFields[p])
                        delete additionalFields[p]
                }
                setRequestForm({
                    ...requestForm,
                    [fieldName]: value,
                    customProductPrice: {
                        ...customProductPrice,
                        items: newCustomPriceList,
                        customPriceId: oCustomPriceList.length != newCustomPriceList.length ? null : customProductPrice.customPriceId,
                    },
                    // productQuantity:productQuantity
                })
                break;
            case 'additionalFields':
                // calculateAdditionFields(value)
                setRequestForm({ ...requestForm, [fieldName]: value })

                break;
            default:
                setRequestForm({ ...requestForm, [fieldName]: value })
                break;
        }
    }

    const handleChangeInput = (fieldName) => e => {

        switch (fieldName) {
            case 'privilageId':
                if (e.target.value)
                    getPrivilagePrice(requestForm.hospitalId, e.target.value);
                break;
        }

        handleChangeFormValue(fieldName, e.target.value);
    }

    const handlehospitalDoctorInput = (fieldName) => (e, option) => {
        var updateState = {};
        updateState.hospitalDoctor = option;
        if (option) {
            if (option.id){
                updateState[fieldName] = option.id;
                let workingGroup = (request.conditionUIData.doctorSendWorkingGroups||[]).find(x=>x.id==option.id);
                if(workingGroup){
                    updateState['additionalFields'] = { ...requestForm.additionalFields, send_doctor_work_group: workingGroup.name }
                }
            }
            else {
                updateState[fieldName] = undefined;
                updateState[fieldName + '_add'] = option.inputValue;
            }
        } else {
            updateState[fieldName] = undefined;
            updateState[fieldName + '_add'] = undefined;
        }

        setRequestForm({ ...requestForm, ...updateState })
    }

    const handlehospitalBuildingInput = (fieldName) => (e, option) => {
        var updateState = {};
        updateState.hospitalBuilding = option;
        if (option) {
            if (option.id)
                updateState[fieldName] = option.id;
            else {
                updateState[fieldName] = undefined;
                updateState[fieldName + '_add'] = option.inputValue;
            }
        } else {
            updateState[fieldName] = undefined;
            updateState[fieldName + '_add'] = undefined;
        }

        setRequestForm({ ...requestForm, ...updateState })
    }

    const handleChangeCustomPrice = (productId, value) => {

        let oItems = requestForm.customProductPrice
            ? requestForm.customProductPrice.items || []
            : [];

        if (value == null)
            oItems = oItems.filter(x => x.productId != productId);
        else {
            let p = oItems.find(x => x.productId == productId)
            if (p != null) {
                p.price = value;
            }
            else {
                oItems.push({ productId: productId, price: value });
            }
        }

        setRequestForm({
            ...requestForm,
            customProductPrice: {
                ...requestForm.customProductPrice,
                customPriceId: null,
                items: oItems
            }
        });
    }

    const onUploaded = (modality, result) => {
        if (result && result.data)
            setRequestForm({ ...requestForm, uploadDocuments: { ...requestForm.uploadDocuments, [modality]: [...(requestForm.uploadDocuments[modality] || []), result.data] } })
    }

    const removeDocument = (modality, documentId) => {
        setRequestForm({ ...requestForm, uploadDocuments: { ...requestForm.uploadDocuments, [modality]: [...(requestForm.uploadDocuments[modality] || []).filter(x => x.documentId != documentId)] } })
    }

    const onUploadedRequestDocument = (result) => {
        if (result && result.data)
            setRequestForm({ ...requestForm, documentIds: [...(requestForm.documentIds || []), result.data] });
    }

    const onRemoveRequestDocument = (documentId) => {
        setRequestForm({ ...requestForm, documentIds: [...(requestForm.documentIds || []).filter(x => x.documentId != documentId)] });
    }

    const patientInfo = request.newRequest.patientInfo || {};
    const dob = patientInfo.birthDate ? new Date(patientInfo.birthDate) : null;
    const simpleAge = dob == null ? null : new Date().getFullYear() - dob.getFullYear();
    const age = dob == null ? null : addYears(dob, simpleAge) > new Date() ? simpleAge - 1 : simpleAge
    let fullAddress = '';
    if (patientInfo.address) {
        let { no, building, moo, village, alley, road, subDistrict, district, province, postCode, country } = patientInfo.address;
        fullAddress = [no, village, moo ? 'หมู่ ' + moo : '', alley, road, subDistrict, district, province, postCode].filter(x => x).join(' ');
    }

    const submitForm = async () => {
        let body = {
            ...requestForm,
            isSchedule,
            uploadDocuments: Object.entries(requestForm.uploadDocuments)
                .map(x => { return { modality: x[0], documentId: x[1].map(y => y.documentId) } }),
            documentIds: requestForm.documentIds.map(x => x.documentId),
            examStartTime: requestForm.examStartTime ? format(requestForm.examStartTime, "HH:mm") : '',
            examEndTime: requestForm.examEndTime ? format(requestForm.examEndTime, "HH:mm") : ''
        };


        let response = {};
        if (requestForm.requestId)
            response = await updateRequest(requestForm.requestId, body);
        else
            response = await createRequest(body);
        
        if (response && response.data)
            if (response.data.success) {
                if (response.data.message) {
                    setNotifyMessage(response.data.message)
                    setTimeout(handleClose, 3000);
                }
                else handleClose();
            }
    }

    const [productTab, setProductTab] = useState(0);
    const [productGroupTab, setProductGroupTab] = useState(0);

    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const [deleteReason, setDeleteReason] = useState('')
    const onOpenDeleteDialog = async () => {
        await getDeleteReason(match.params.requestId);
    }
    const onDeleteRequest = async () => {
        let deleteResult = await deleteRequest(match.params.requestId, deleteReason);
        if (deleteResult) {
            setIsOpenDeleteDialog(false)
            handleClose()
        }
    }

    let [productGroupOpenCollapse, setProductGroupOpenCollapse] = useState({})
    const handleClickProductGroup = (group) => {
        setProductGroupOpenCollapse({
            ...productGroupOpenCollapse,
            [group]: !productGroupOpenCollapse[group]
        })
    }

    let [productSearchText, setProductSearchText] = useState({})
    const onSearchProduct = (modalityCode) => (e) => {
        setProductSearchText({
            ...productSearchText,
            [modalityCode]: e.target.value
        })

    }

    const doctorSend = request.conditionUIData.hospitalDoctors.find(x => x.id == requestForm.hospitalDoctorId);
    const hospitalBuilding = request.conditionUIData.hospitalBuildingNames.find(x => x.id == requestForm.hospitalId)?.children.find(x => x.id == requestForm.hospitalBuildingId);
    const hospitalBuildings = request.conditionUIData.hospitalBuildingNames.find(x => x.id == requestForm.hospitalId)?.children

    let productGroupTabPanelIndex = 0;

    const StyledTabs = withStyles({
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            opacity: 0.1
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


    const [isOpenResearchProjectDialog, setIsOpenResearchProject] = useState(false)
    const handleClickResearchProjectButton = () => {
        setIsOpenResearchProject(true);
    }
    const handleSaveResearchProejct = (data) => {
        setRequestForm({ ...requestForm, ...data })
        setIsOpenResearchProject(false);
    }
    const handleCloseResearchProjectWithoutSave = () => {
        setIsOpenResearchProject(false);
    }

    const [showNhsoClaimDialog, setShowNhsoDialog] = useState(false);
    const [nshoPatientData, setNshoPatientData] = useState(null);
    const handleClickGetNhsoCode = async (idcard) => {
        var nshoPatientDataResponse = await axios.get(nshoServiceUrl + '/api/smartcard/read?readImageFlag=true');
        if (nshoPatientDataResponse && nshoPatientDataResponse.data) {
            if (idcard == nshoPatientDataResponse.data.pid) {
                setShowNhsoDialog(true);
                setNshoPatientData(nshoPatientDataResponse.data)
            } else swal('หมายเลขบัตรประชาชนไม่ตรงกับในระบบ');
        }
    }

    const handleClickHistoryNsho = async (idcard) => {
        var lastAuthenCodeResponse = await axios.get(nshoServiceUrl + '/api/nhso-service/latest-authen-code/' + idcard);
        if (lastAuthenCodeResponse && lastAuthenCodeResponse.data) {
            var el = <div><b>AuthenCode ล่าสุดคือ:</b> <span>{lastAuthenCodeResponse.data.claimCode}</span><br /><b>ใช้สิทธิ์เมื่อวันที่:</b> <span>{lastAuthenCodeResponse.data.claimDateTime.replace('T', ' ')} น.</span></div>;
            var a = document.createElement('div')
            ReactDOM.render(el, a);
            swal(a)
        }
    }

    const [isNshoLoading, setIsNshoLoading] = useState(false);
    const selectClaimType = async (pid, claimType, mobile, correlationId, hn, hcode) => {
        swal({
            title: "ยืนยันการใช้สิทธิ์",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (isConfirm) => {
                if (isConfirm) {
                    setIsNshoLoading(true);
                    var confirmResponse = await axios.post(nshoServiceUrl + '/api/nhso-service/confirm-save/', {
                        pid: pid,
                        claimType: claimType,
                        mobile: mobile,
                        correlationId: correlationId,
                        hn: hn,
                        hcode: hcode
                    });
                    if (confirmResponse && confirmResponse.data && confirmResponse.data.claimCode) {
                        handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, nhso_auth_code: confirmResponse.data.claimCode })
                        setShowNhsoDialog(false);
                        setNshoPatientData(null);
                        await changePatientImage(nshoPatientData.image)
                    }
                    setIsNshoLoading(false);
                }
            });
    }

    const isEnableDoctorSendWorkGroup = ['lQNvTOhZUkaP2b0MbDjpUw','J2ydZSl1ukWxnSGZKThDlw'].indexOf(branchId) >= 0
    return (
        <Dialog
            open={true}
            onEnter={handleEnter}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth={true}
            scroll="paper"
        >
            <DialogContent>
                {/*NSHO Dialog*/}
                <Dialog
                    open={showNhsoClaimDialog}
                    onClose={() => { setShowNhsoDialog(false); setNshoPatientData(null) }}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle>ใช้สิทธิ์ประกันสุขภาพ</DialogTitle>
                    <DialogContent>
                        {isNshoLoading ? <LinearProgress /> : null}
                        {nshoPatientData?.image ? <div style={{textAlign:'center'}}>
                            <img src={`data:image/png;base64,${nshoPatientData.image}`} />
                        </div> : null}
                        <b>ผู้ใช้สิทธิ์: </b><span>{nshoPatientData?.fname} {nshoPatientData?.lname} ({nshoPatientData?.age})</span><br />
                        <b>สิทธิ์: </b><span>{nshoPatientData?.mainInscl} - {nshoPatientData?.subInscl}</span><br />
                        <b>โรงพยาบาลที่ใช้สิทธิ์: </b><span>{nshoPatientData?.hospMain?.hname}</span><br /><br />
                        <h4 style={{ textAlign: 'center' }}>เลือกสิทธิ์ที่ต้องการใช้</h4><br />
                        {nshoPatientData?.claimTypes.map(x => <div key={x.claimType} style={{ textAlign: 'center' }}>
                            <Button disabled={isNshoLoading} color='primary' variant='outlined' style={{ margin: 5 }} 
                            onClick={() => { selectClaimType(patientInfo?.nationalNumber, x.claimType, patientInfo?.phoneNumber, nshoPatientData.correlationId, nshoPatientData?.hospMain?.hcode) }}>
                                {x.claimTypeName}
                            </Button>
                        </div>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowNhsoDialog(false)}>
                            ปิด
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Loading Dialog */}
                <Dialog
                    open={request.newRequest.isLoading}
                    maxWidth="sm"
                >
                    <div style={{ width: 90, height: 75, textAlign: 'center', verticalAlign: 'middle' }}>
                        <CircularProgress size={60} />
                    </div>
                </Dialog>
                {/* Delete Dialog */}
                <Dialog
                    open={isOpenDeleteDialog}
                    onEnter={onOpenDeleteDialog}
                    onClose={() => setIsOpenDeleteDialog(false)}
                    maxWidth="xs"
                    fullWidth={true}
                >
                    <DialogTitle>ระบุเหตุผลที่ยกเลิก</DialogTitle>
                    <DialogContent>

                        <Grid container alignItems="center" style={{ textAlign: 'center' }}>
                            <Grid item xs={4}>
                                <DeleteIcon style={{ fontSize: 80 }} />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth={true}>
                                    <Autocomplete
                                        fullWidth={true}
                                        freeSolo
                                        options={request.deleteDialog.reasonOptions}
                                        onChange={(e, v) => setDeleteReason(v)}
                                        renderInput={(params) => (
                                            <TextField
                                                onChange={(e) => setDeleteReason(e.target.value)} fullWidth={true} {...params} label="เหตุผลการลบ" variant="filled" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpenDeleteDialog(false)}>
                            ปิด
                        </Button>
                        <Button
                            onClick={onDeleteRequest}
                            color="primary"
                            disabled={deleteReason == null || deleteReason == ''}
                        >
                            ยืนยัน
                            <DeleteIcon color="primary" className={classes.rightIcon} />
                        </Button>
                    </DialogActions>
                </Dialog>

                <ResearchProjectDialog
                    isOpen={isOpenResearchProjectDialog}
                    onClose={handleCloseResearchProjectWithoutSave}
                    onSubmit={handleSaveResearchProejct}
                    data={{
                        researchProjectId_add: requestForm.researchProjectId_add,
                        researchProjectId: requestForm.researchProjectId,
                        researchProjectSubjectId: requestForm.researchProjectSubjectId,
                        researchProjectSubjectName: requestForm.researchProjectSubjectName,
                        researchProjectVN: requestForm.researchProjectVN
                    }}
                    options={request.conditionUIData.researchProjects}
                />

                <div className={classes.container}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Patient</FormLabel>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={2} style={{ textAlign: 'center' }}>
                                {patientInfo.patientId && <img src={`/api/patient/image/${patientInfo.patientId}`} style={{ maxHeight: 100, maxWidth: 100 }} />}
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled margin="normal" label="Name" value={patientInfo.name} InputLabelProps={{ readOnly: true, shrink: true }} fullWidth></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled margin="normal" label="Gender" value={patientInfo.gender} InputLabelProps={{ readOnly: true, shrink: true }} fullWidth></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled margin="normal" label="Age" value={age} InputLabelProps={{ readOnly: true, shrink: true }} fullWidth></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel>โปรเจควิจัย</FormLabel>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        endIcon={requestForm.researchProjectId || requestForm.researchProjectId_add ? <EditIcon /> : null}
                                        onClick={handleClickResearchProjectButton}
                                    >{!requestForm.researchProjectId && !requestForm.researchProjectId_add
                                        ? 'เลือกโปรเจควิจัย'
                                        : requestForm.researchProjectId && request.conditionUIData.researchProjects && request.conditionUIData.researchProjects.find(x => x.id == requestForm.researchProjectId)
                                            ? request.conditionUIData.researchProjects.find(x => x.id == requestForm.researchProjectId).name
                                            : requestForm.researchProjectId_add
                                                ? requestForm.researchProjectId_add
                                                : 'ไม่พบโปรเจควิจัย'}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} style={{ textAlign: 'right' }}>
                                {!match.params.patientId
                                    ? <IconButton edge="end" aria-label="delete" onClick={() => setIsOpenDeleteDialog(true)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    : null
                                }
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField disabled fullWidth multiline={true} margin="normal" label="Address" value={fullAddress} InputLabelProps={{ readOnly: true, shrink: true }}></TextField>
                            </Grid> */}

                            <Grid item xs={2}>
                                <TextField margin="normal" label="น้ำหนัก" InputLabelProps={{ shrink: true }} fullWidth
                                    value={requestForm['additionalFields'] ? requestForm['additionalFields']['weight'] : ''}
                                    onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, weight: e.target.value })}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField margin="normal" label="ส่วนสูง" InputLabelProps={{ shrink: true }} fullWidth
                                    value={requestForm['additionalFields'] ? requestForm['additionalFields']['height'] : ''}
                                    onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, height: e.target.value })}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField margin="normal" label="BUN" InputLabelProps={{ shrink: true }} fullWidth
                                    value={requestForm['additionalFields'] ? requestForm['additionalFields']['bun'] : ''}
                                    onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, bun: e.target.value })}></TextField>

                            </Grid>
                            <Grid item xs={2}>
                                <TextField margin="normal" label="Creatinine" InputLabelProps={{ shrink: true }} fullWidth
                                    value={requestForm['additionalFields'] ? requestForm['additionalFields']['creatinine'] : ''}
                                    onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, creatinine: e.target.value })}></TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField margin="normal" label="eGFR" InputLabelProps={{ shrink: true }} fullWidth
                                    value={requestForm['additionalFields'] ? requestForm['additionalFields']['egfr'] : ''}
                                    onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, egfr: e.target.value })}></TextField>
                            </Grid>
                        </Grid>
                    </FormControl>

                    {match.params.page === 'upload'
                        ? <FormControl component="fieldset" fullWidth variant="filled" style={{ marginBottom: 15 }}>
                            <AppBar position="static" color="default" style={{ padding: 10 }}>
                                <FormLabel component="legend">เอกสาร</FormLabel>
                                <Uploader
                                    filenameOptions={(request.conditionUIData.requestDocuments || []).map(x => x.id)}
                                    allowUpload={true}
                                    autoUpload={true}
                                    uploadDocument={(filename, file) => uploadDocument(filename, file)}
                                    onUploaded={(result) => onUploadedRequestDocument(result)}
                                    accept={'.jpg,.tiff,.pdf,.png,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpeg,.txt'}
                                >
                                    {requestForm.documentIds && requestForm.documentIds.length ?
                                        <List dense={false}>
                                            {requestForm.documentIds.map(z => <ListItem key={z.documentId}>
                                                <ListItemIcon>
                                                    <AttachmentIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    onClick={(e) => { e.preventDefault(); window.open(`/api/exam/${match.params.requestId || 'new'}/document/${z.documentId}`) }}
                                                    style={{ cursor: 'pointer' }}
                                                    primary={z.fileName}
                                                    secondary={`Upload by ${z.uploadBy} on ${format(new Date(z.uploadDate), 'dd-MM-yyyy HH:mm')}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete" onClick={e => { e.preventDefault(); onRemoveRequestDocument(z.documentId); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            )
                                            }
                                        </List> : null
                                    }
                                    <Divider variant="middle" fullWidth />
                                </Uploader>
                            </AppBar>
                        </FormControl>
                        : null
                    }

                    {['edit', 'new'].indexOf(match.params.page) >= 0
                        ? <>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">นัดตรวจ</FormLabel>
                                        <RadioGroup row value={false} aria-label="gender" name="customized-radios" onChange={(event) => { setIsSchedule(event.target.value == 'true'); }}>
                                            <FormControlLabel value={false} control={<Radio checked={!isSchedule} />} label="ตอนนี้" />
                                            <FormControlLabel value={true} control={<Radio checked={isSchedule} />} label="วันที่" />
                                        </RadioGroup>
                                    </FormControl>
                                    {!isSchedule ? null :
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">วันที่นัดตรวจ</FormLabel>
                                            <FormGroup row>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                                    <KeyboardDateTimePicker
                                                        format="dd/MM/yyyy HH:mm"
                                                        margin="dense"
                                                        label=""
                                                        value={requestForm.scheduleDateTime}
                                                        onChange={(v) => { handleChangeFormValue('scheduleDateTime', v) }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change schedule datetime",
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        minutesStep={10}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </FormGroup>
                                        </FormControl>
                                    }
                                </Grid>

                                <Grid item xs={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            margin="dense"
                                            label="วันที่ตรวจเลือด"
                                            autoOk={true}
                                            value={requestForm['additionalFields'] ? requestForm['additionalFields']['blood_test_date_bun'] ?? null : null}
                                            onChange={(v) => {
                                                handleChangeFormValue('additionalFields', {
                                                    ...requestForm.additionalFields,
                                                    blood_test_date_bun: v,
                                                    blood_test_date_creatinine: requestForm['additionalFields']['blood_test_date_creatinine'] ?? v,
                                                    blood_test_date_egfr: requestForm['additionalFields']['blood_test_date_egfr'] ?? v
                                                })
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            openTo="date"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            margin="dense"
                                            label="วันที่ตรวจเลือด"
                                            autoOk={true}
                                            value={requestForm['additionalFields'] ? requestForm['additionalFields']['blood_test_date_creatinine'] ?? null : null}
                                            onChange={(v) => {
                                                handleChangeFormValue('additionalFields', {
                                                    ...requestForm.additionalFields,
                                                    blood_test_date_bun: requestForm['additionalFields']['blood_test_date_bun'] ?? v,
                                                    blood_test_date_creatinine: v,
                                                    blood_test_date_egfr: requestForm['additionalFields']['blood_test_date_egfr'] ?? v
                                                })
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            openTo="date"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            margin="dense"
                                            label="วันที่ตรวจเลือด"
                                            autoOk={true}
                                            value={requestForm['additionalFields'] ? requestForm['additionalFields']['blood_test_date_egfr'] ?? null : null}
                                            onChange={(v) => {
                                                handleChangeFormValue('additionalFields', {
                                                    ...requestForm.additionalFields,
                                                    blood_test_date_bun: requestForm['additionalFields']['blood_test_date_bun'] ?? v,
                                                    blood_test_date_creatinine: requestForm['additionalFields']['blood_test_date_creatinine'] ?? v,
                                                    blood_test_date_egfr: v
                                                })
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            openTo="date"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={2}>
                                    {match.params.patientId
                                        ? <TextField
                                            margin="dense"
                                            InputLabelProps={{ shrink: true }}
                                            label="AccessionNumber"
                                            value={requestForm.forceAcceesionNumber}
                                            fullWidth
                                            onChange={handleChangeInput('forceAcceesionNumber')}
                                        >
                                        </TextField>
                                        : null
                                    }
                                </Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={2}>
                                    {['edit'].indexOf(match.params.page) >= 0 && isNshoEnable
                                        ? <>
                                            <TextField
                                                margin='dense'
                                                InputLabelProps={{ shrink: true }}
                                                label="สปสช Authen Code"
                                                value={requestForm['additionalFields'] ? requestForm['additionalFields']['nhso_auth_code'] : ''}
                                                onChange={(e) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, nhso_auth_code: e.target.value })}
                                            ></TextField>
                                            {!patientInfo?.nationalNumber
                                                ? <span><Button disabled>ไม่พบหมายเลขบัตรประชาชน</Button></span>
                                                : !patientInfo?.phoneNumber
                                                    ? <span><Button disabled>ไม่พบหมายเลขโทรศัพท์</Button></span>
                                                    : <>
                                                        <Button color="secondary" variant='outlined' onClick={() => handleClickGetNhsoCode(patientInfo.nationalNumber)}>Get Code</Button>
                                                        <Button onClick={() => { handleClickHistoryNsho(patientInfo.nationalNumber) }}><HistoryIcon /></Button>
                                                    </>
                                            }
                                        </>
                                        : null}
                                </Grid>

                            </Grid>


                            <Divider variant="fullWidth" />
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">โรงพยาบาล & สิทธิ์</FormLabel>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={isEnableDoctorSendWorkGroup ? 4 : 8}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={doctorSend || requestForm.hospitalDoctor}
                                                freeSolo
                                                options={request.conditionUIData.hospitalDoctors.sort((a, b) => a.name.localeCompare(b.name))}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    // Regular option
                                                    return option.name;
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== '') {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            name: `เพิ่ม "${params.inputValue}"`,
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                renderOption={(option) => option.name}
                                                onChange={handlehospitalDoctorInput('hospitalDoctorId')}
                                                selectOnFocus
                                                clearOnBlur
                                                renderInput={(params) => (
                                                    <TextField {...params} label="แพทย์ส่ง" variant="filled" />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {!isEnableDoctorSendWorkGroup
                                        ? null
                                        : <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    value={requestForm['additionalFields']['send_doctor_work_group'] || ''}
                                                    freeSolo
                                                    options={['กลุ่มงานอายุรกรรม',
                                                        'กลุ่มงานศัลยกรรม',
                                                        'กลุ่มงานศัลยกรรมออร์โธปิดิกส์',
                                                        'กลุ่มงานโสต ศอ นาสิก',
                                                        'กลุ่มงานกุมารเวชกรรม',
                                                        'กลุ่มงานสูติ-นรีเวชกรรม',
                                                        'กลุ่มงานจักษุวิทยา',
                                                        'กลุ่มงานรังสีวิทยา',
                                                        'กลุ่มภารกิจด้านพัฒนาระบบบริการและสนับสนุนบริการ',
                                                        'กลุ่มงานเวชกรรมสังคม',
                                                        'กลุ่มงานเวชศาสตร์ฉุกเฉิน',
                                                        'กลุ่มงานจิตเวชและยาเสพติด',
                                                        'กลุ่มงานวิสัญญีวิทยา',
                                                        'กลุ่มงานเวชกรรมฟื้นฟู',
                                                        'กลุ่มงานอื่น ๆ']}
                                                    renderOption={(option) => option}
                                                    onChange={(e, item) => handleChangeFormValue('additionalFields', { ...requestForm.additionalFields, send_doctor_work_group: item })}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="กลุ่มงาน" variant="filled" />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={2}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">เวลาเข้าห้องตรวจ</FormLabel>
                                            <FormGroup row>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                                    <KeyboardTimePicker
                                                        margin="dense"
                                                        label=""
                                                        minutesStep={5}
                                                        ampm={false}
                                                        value={requestForm.examStartTime}
                                                        onChange={(v) => { handleChangeFormValue('examStartTime', v) }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change time",
                                                        }}
                                                        autoOk={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">เวลาออกห้องตรวจ</FormLabel>
                                            <FormGroup row>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                                                    <KeyboardTimePicker
                                                        margin="dense"
                                                        label=""
                                                        minutesStep={5}
                                                        ampm={false}
                                                        value={requestForm.examEndTime}
                                                        onChange={(v) => { handleChangeFormValue('examEndTime', v) }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change time",
                                                        }}
                                                        autoOk={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>

                                            <TextField
                                                select
                                                label="โรงพยาบาล"
                                                value={requestForm.hospitalId || ''}
                                                onChange={handleChangeInput('hospitalId')}
                                                variant="filled"
                                            >
                                                <MenuItem value={null}>
                                                    <em>ไม่ระบุ</em>
                                                </MenuItem>
                                                {
                                                    (request.conditionUIData.hospitalPrivilages || []).sort((a, b) => a.name.localeCompare(b.name)).map(x =>
                                                        <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                                    )
                                                }
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            {requestForm.hospitalId == null ? null :
                                                <TextField select
                                                    label="สิทธิ์"
                                                    value={requestForm.privilageId}
                                                    displayEmpty
                                                    onChange={handleChangeInput('privilageId')}
                                                    variant="filled"
                                                >
                                                    <MenuItem value={null}>
                                                        <em>ไม่ใช้สิทธิ์</em>
                                                    </MenuItem>
                                                    {request.conditionUIData.hospitalPrivilages.filter(x => x.id == requestForm.hospitalId).map(x =>
                                                        x.children.sort((a, b) => a.name.localeCompare(b.name)).map(y =>
                                                            <MenuItem key={y.id} value={y.id}>{y.name}</MenuItem>
                                                        )
                                                    )
                                                    }
                                                </TextField>
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField select
                                                label="ช่องทางการชำระเงิน"
                                                value={requestForm.paymentMethodId || ''}
                                                displayEmpty
                                                onChange={handleChangeInput('paymentMethodId')}
                                                variant="filled"
                                            >
                                                <MenuItem value={null}>
                                                    <em>ไม่ระบุ</em>
                                                </MenuItem>
                                                {(request.conditionUIData.paymentMethods || []).map(x =>
                                                    <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                                )}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend"></FormLabel>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={requestForm.allergy || []}
                                                onChange={(e, val) => handleChangeFormValue('allergy', val)}
                                                multiple
                                                options={request.conditionUIData.allergies.map(x => x.id)}
                                                freeSolo
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </React.Fragment>
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={params => (
                                                    <TextField variant="filled"
                                                        {...params}
                                                        placeholder="ระบุเพิ่มเติมแล้วกด Enter"
                                                        label={requestForm.allergy && requestForm.allergy.length ? <b style={{ color: 'rgb(255 0 0)' }}>ประวัติการแพ้</b> : 'ประวัติการแพ้'}
                                                        fullWidth
                                                        hiddenLabel
                                                    />
                                                )}
                                            />

                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <Grid container xs={12} spacing={2} fullWidth style={{ alignItems: 'end' }}>
                                {requestForm.hospitalId
                                    ? <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={hospitalBuilding || requestForm.hospitalBuilding || { id: '', name: '' }}
                                                freeSolo
                                                options={
                                                    (hospitalBuildings?.sort((a, b) => a.name.localeCompare(b.name)) || [])}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    // Regular option
                                                    return option.name;
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== '') {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            name: `เพิ่ม "${params.inputValue}"`,
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                renderOption={(option) => option.name}
                                                onChange={handlehospitalBuildingInput('hospitalBuildingId')}
                                                selectOnFocus
                                                clearOnBlur
                                                renderInput={(params) => (
                                                    <TextField {...params} label="อาคาร" variant="filled" />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : null
                                }

                                <Grid item xs={requestForm.hospitalId ? 8 : 12}>
                                    <FormControl component="fieldset" fullWidth >
                                        <FormLabel component="legend"></FormLabel>
                                        <TextField value={requestForm.remark}
                                            multiline color="warning"
                                            label="Note"
                                            variant="filled"
                                            onChange={handleChangeInput('remark')}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" />
                        </>
                        : null
                    }
                    {true
                        ? <>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">รายการตรวจ</FormLabel>
                                <FormGroup row>
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            value={productTab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            onChange={(e, i) => setProductTab(i)}
                                        >
                                            {request.conditionUIData.modalityProducts.map((x, i) =>
                                                <Tab key={x.modalityCode} label={x.modalityName} />
                                            )}
                                        </Tabs>

                                        <Divider />
                                        {request.conditionUIData.modalityProducts.map((x, i) =>
                                            <TabPanel key={x.modalityCode} value={productTab} index={i}>
                                                {['edit', 'new'].indexOf(match.params.page) >= 0
                                                    ? <>
                                                        {_.map(_.groupBy(x.products, 'productGroup')).length == 1
                                                            ? <Grid container>
                                                                <Grid item xs={10}>
                                                                    <StyledTabs
                                                                        value={productGroupTab}
                                                                        indicatorColor="primary"
                                                                        textColor="primary"
                                                                        variant="scrollable"
                                                                        scrollButtons="auto"
                                                                        onChange={(e, i) => setProductGroupTab(i)}
                                                                    >
                                                                        <Tab
                                                                            key={'all'}
                                                                            label={(x.products[0]?.productGroup || 'All') + ((requestForm['modalities'][x.modalityCode] || []).length > 0 ? ` (${(requestForm['modalities'][x.modalityCode] || []).length})` : '')}
                                                                            style={{ opacity: 0.7 }}
                                                                        />
                                                                    </StyledTabs>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <TextField style={{ right: 0, position: 'absolute' }}
                                                                        label={`Search ${x.modalityCode}`}
                                                                        value={productSearchText[x.modalityCode]}
                                                                        onChange={onSearchProduct(x.modalityCode)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">
                                                                                {productSearchText[x.modalityCode]
                                                                                    && <IconButton
                                                                                        onClick={() => onSearchProduct(x.modalityCode)({ target: { value: '' } })}
                                                                                    >
                                                                                        <ClearIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            : <Grid container>
                                                                <Grid item xs={10}>

                                                                    <StyledTabs
                                                                        value={productGroupTab}
                                                                        indicatorColor="primary"
                                                                        textColor="primary"
                                                                        variant="scrollable"
                                                                        scrollButtons="auto"
                                                                        onChange={(e, i) => setProductGroupTab(i)}
                                                                    >
                                                                        {_.map(_.groupBy(x.products, 'productGroup'), (products, group) => {
                                                                            let modalityListSelected = (requestForm['modalities'][x.modalityCode] || [])
                                                                            let selectedCount = _.intersection(products.map(z => z.productId), modalityListSelected).length
                                                                            return <Tab
                                                                                key={group}
                                                                                label={(group == 'null' ? 'Others' : group) + (selectedCount != 0 ? ` (${selectedCount})` : '')}
                                                                                style={{ opacity: 0.7 }}
                                                                            />
                                                                        })}
                                                                    </StyledTabs>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <TextField style={{ right: 0, position: 'absolute' }}
                                                                        label={`Search ${x.modalityCode}`}
                                                                        value={productSearchText[x.modalityCode]}
                                                                        onChange={onSearchProduct(x.modalityCode)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">
                                                                                {productSearchText[x.modalityCode]
                                                                                    && <IconButton
                                                                                        aria-label="clear text"
                                                                                        onClick={() => onSearchProduct(x.modalityCode)({ target: { value: '' } })}
                                                                                    >
                                                                                        <ClearIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Divider />
                                                        <div style={{ maxHeight: 350, overflowY: 'auto', overflowX: 'hidden', padding: 5, productGroupTabPanelIndex: productGroupTabPanelIndex = 0 }}>
                                                            {_.map(_.groupBy(x.products, 'productGroup'), (products, group) => {
                                                                return <TabPanel key={group} value={productGroupTab} index={productGroupTabPanelIndex++}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                        {products.filter(y => !productSearchText[x.modalityCode] || y.name.toUpperCase().indexOf((productSearchText[x.modalityCode] || '').toUpperCase()) >= 0).map(y => {
                                                                            let isChecked = (requestForm['modalities'][x.modalityCode] || []).indexOf(y.productId) >= 0;
                                                                            return <Grid key={y.productId} item xs={4}>
                                                                                <FormControlLabel value={y.productId} control={<Checkbox checked={isChecked} onChange={handleCheckboxChange('modalities', x.modalityCode)} />} label={y.name} />
                                                                                {isChecked ?
                                                                                    <>
                                                                                        <TextField
                                                                                            label=""
                                                                                            style={{ width: 46 }}
                                                                                            type="number"
                                                                                            onChange={(e) => e.target.value > 0 && handleChangeFormValue('productQuantity', { ...requestForm.productQuantity, [y.productId]: e.target.value })}
                                                                                            value={requestForm.productQuantity && (requestForm.productQuantity[y.productId] ?? 1)}
                                                                                            InputProps={{
                                                                                                startAdornment: <InputAdornment position="start">x</InputAdornment>,
                                                                                            }}
                                                                                        />
                                                                                        {group == 'เวชภัณฑ์' ?
                                                                                            <TextField
                                                                                                label=""
                                                                                                style={{ width: 80, paddingLeft: 3 }}
                                                                                                type="text"
                                                                                                onChange={(e) => handleChangeFormValue('productDose', { ...requestForm.productDose, [y.productId]: e.target.value })}
                                                                                                value={requestForm.productDose && (requestForm.productDose[y.productId])}
                                                                                                InputProps={{
                                                                                                    endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                                                                                                    style: { textAlign: 'center' }
                                                                                                }}
                                                                                            />
                                                                                            : y.name.toUpperCase() == 'FILM'
                                                                                                ? <TextField
                                                                                                    label=""
                                                                                                    style={{ width: 80, paddingLeft: 3 }}
                                                                                                    type="text"
                                                                                                    onChange={(e) => handleChangeFormValue('productDose', { ...requestForm.productDose, [y.productId]: e.target.value })}
                                                                                                    value={requestForm.productDose && (requestForm.productDose[y.productId])}
                                                                                                    InputProps={{
                                                                                                        endAdornment: <InputAdornment position="end">แผ่น</InputAdornment>,
                                                                                                        style: { textAlign: 'center' }
                                                                                                    }}
                                                                                                />
                                                                                                : null}
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        )}
                                                                    </Grid>
                                                                </TabPanel>
                                                            })}
                                                        </div>
                                                    </>
                                                    : null
                                                }
                                                <Grid style={{ marginTop: 15 }}>
                                                    <FormControl component="fieldset" fullWidth variant="filled">
                                                        <FormLabel component="legend">{`ใบ Request ${x.modalityCode}`}</FormLabel>
                                                        <Uploader
                                                            allowUpload={true}
                                                            autoUpload={true}
                                                            uploadDocument={(filename, file) => uploadDocument(filename, file)}
                                                            onUploaded={(result) => onUploaded(x.modalityCode, result)}
                                                            accept={'.jpg,.tiff,.pdf,.png,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpeg,.txt'}
                                                        >
                                                            {requestForm.uploadDocuments[x.modalityCode] && requestForm.uploadDocuments[x.modalityCode].length ?
                                                                <List dense={false} className={classes.documentBox}>
                                                                    {requestForm.uploadDocuments[x.modalityCode].map(z => <ListItem key={z.documentId}>
                                                                        <ListItemIcon>
                                                                            <AttachmentIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            onClick={(e) => { e.preventDefault(); window.open(`/api/exam/${match.params.requestId || 'new'}/document/${z.documentId}`) }}
                                                                            style={{ cursor: 'pointer' }}
                                                                            primary={z.fileName}
                                                                            secondary={`Upload by ${z.uploadBy} on ${format(new Date(z.uploadDate), 'dd-MM-yyyy HH:mm')}`}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" aria-label="delete" onClick={e => { e.preventDefault(); removeDocument(x.modalityCode, z.documentId); }}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    )
                                                                    }
                                                                </List> : null
                                                            }
                                                            <Divider variant="middle" fullWidth />
                                                        </Uploader>
                                                    </FormControl>
                                                </Grid>
                                            </TabPanel>
                                        )}

                                    </AppBar>
                                </FormGroup>
                            </FormControl>
                        </>
                        : null
                    }
                    {['edit', 'new'].indexOf(match.params.page) >= 0 && additionalFields.length
                        ? <Grid container size="small" style={{ margin: '20px 0', padding: 7, border: '1px solid #bcbcbc' }} spacing={3}>
                            <Grid xs={12}>
                                Additional Fields
                            </Grid>
                            <AdditionalFieldForm
                                requestId={requestForm.requestId}
                                additionalFieldValue={requestForm['additionalFields']}
                                additionalFields={additionalFields}
                                handleChangeFormValue={handleChangeFormValue}
                                changeAdditionalFieldVisibility={changeAdditionalFieldVisibility}
                                imageList={requestForm.imageList || []}
                            />
                        </Grid>
                        : null
                    }
                    <Divider light />
                    {['edit', 'new'].indexOf(match.params.page) >= 0
                        ? <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>รายการตรวจ</TableCell>
                                    <TableCell>ราคา</TableCell>
                                    <TableCell>ราคาตามสิทธิ์</TableCell>
                                    <TableCell>ราคากำหนดเอง</TableCell>
                                    <TableCell>จำนวน</TableCell>
                                    <TableCell>ราคารวม</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {request.conditionUIData.modalityProducts
                                    .map((x, i) => {
                                        const privilages = !request.newRequest.privilagePrices
                                            ? []
                                            : request.newRequest.privilagePrices[requestForm.privilageId] || []
                                        const customPrices = requestForm.customProductPrice != null && requestForm.customProductPrice.items;
                                        return requestForm['modalities'][x.modalityCode] && requestForm['modalities'][x.modalityCode].length > 0 ?
                                            <>
                                                {/* <TableRow>
                                                <Typography>{x.modalityCode}</Typography>
                                            </TableRow> */}
                                                {(requestForm['modalities'][x.modalityCode] || []).map(id => {
                                                    const product = x.products.find(x => x.productId == id)
                                                    const amount = requestForm.productQuantity[id] ?? 1;

                                                    const priceTable = privilages.find(x => x.productId == id);
                                                    const customPrice = customPrices && customPrices.find(x => x.productId == id)

                                                    const price = customPrice ? customPrice.price : priceTable ? priceTable.price : product.defaultPrice;
                                                    return <TableRow key={id}>
                                                        <TableCell component="th" scope="row">
                                                            {product.name}
                                                        </TableCell>
                                                        <TableCell style={{ textDecoration: priceTable || customPrice ? 'line-through' : '' }}>{product.defaultPrice}</TableCell>
                                                        <TableCell style={{ textDecoration: customPrice ? 'line-through' : '' }}>{priceTable ? priceTable.price : '-'}</TableCell>
                                                        <TableCell>
                                                            <Input
                                                                type="number"
                                                                style={{ width: 150 }}
                                                                value={customPrice ? customPrice.price : ''}
                                                                onChange={(e) => handleChangeCustomPrice(id, e.target.value)}
                                                                disabled={!customPrice}
                                                                onFocus={(e) => e.target.select()}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={(e) => { !customPrice ? handleChangeCustomPrice(id, 0) : handleChangeCustomPrice(id, null) }}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        >
                                                                            {!customPrice ? <EditIcon /> : <ClearIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label=""
                                                                style={{ width: 46 }}
                                                                type="number"
                                                                onChange={(e) => e.target.value > 0 && handleChangeFormValue('productQuantity', { ...requestForm.productQuantity, [id]: e.target.value })}
                                                                value={requestForm.productQuantity && (requestForm.productQuantity[id] ?? 1)}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">x</InputAdornment>,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{price * amount}</TableCell>
                                                    </TableRow>
                                                })
                                                }
                                            </>
                                            : null
                                    })}
                            </TableBody>
                        </Table>
                        : null
                    }

                    <Snackbar
                        open={!!notifyMessage}
                        key={notifyMessage}
                    >
                        <Alert severity="warning">
                            {notifyMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={submitForm} >{requestForm.requestId ? 'Edit request' : 'Request for exam'}</Button>
            </DialogActions>
        </Dialog >
    );
}
function mapStateToProps(state) {
    return {
        request: state.request,
        user: state.user
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(NewRequest);
