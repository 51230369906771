import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { actionCreators } from '../../store/Report';

import LinearProgress from '@material-ui/core/LinearProgress';

class ReportViewer extends React.Component {

    componentDidMount() {
        let form = document.getElementById('hiddenForm');
        if (!form) {
            form = document.createElement('form');
            form.name = form.id = 'hiddenForm';
            document.body.appendChild(form);
        }

        let { parameters, currentFinishedReportId } = this.props.report;
        let { reportName } = this.props.match.params;
        this.loadingBar.style.display = 'block';
        form.action = '/ReportViewer/' + reportName;
        form.target = 'reportFrame';
        form.method = 'POST';

        //empty form
        while (form.firstChild) {
            form.removeChild(form.firstChild);
        }

        let customParameter = [...parameters]
        for (var i = 0; i < customParameter.length; i++) {
            let param = customParameter[i];
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = param.parameterName;
            input.value = (param.value !== null && param.value !== undefined) ? encodeURIComponent(param.value) : '';
            form.appendChild(input);
        }
        let dummy = document.createElement('input');
        dummy.name = currentFinishedReportId;
        dummy.type = 'hidden';
        form.appendChild(dummy)
        form.submit();
    }
    componentDidUpdate(prevProp) {

        let form = document.getElementById('hiddenForm');
        if (!form) {
            form = document.createElement('form');
            form.name = form.id = 'hiddenForm';
            document.body.appendChild(form);
        }

        let { parameters, currentFinishedReportId } = this.props.report;
        let { examId, reportName, billingId } = this.props.match.params;

        if (
            JSON.stringify({ p: prevProp.report.parameters, e: prevProp.match.params.examId, n: prevProp.match.params.reportName, f: prevProp.report.currentFinishedReportId, b: prevProp.match.params.billingId })
            !=
            JSON.stringify({ p: this.props.report.parameters, e: examId, n: reportName, f: currentFinishedReportId, b: billingId })
        ) {
            this.loadingBar.style.display = 'block';
            form.action = '/ReportViewer/' + reportName;
            form.target = 'reportFrame';
            form.method = 'POST';

            //empty form
            while (form.firstChild) {
                form.removeChild(form.firstChild);
            }

            for (let p of parameters.filter(x => ['EXAMID', 'BILLINGID'].indexOf(x.parameterName.toUpperCase()) >= 0)) {
                if (p.parameterName === 'ExamId' && (!p.value||p.value==='undefined'))
                    p.value = examId||billingId;
                else if (p.parameterName === 'BillingId' && (!p.value||p.value==='undefined'))
                    p.value = billingId;
            }
            let customParameter = [...parameters]
            for (var i = 0; i < customParameter.length; i++) {
                let param = customParameter[i];
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = param.parameterName;
                input.value = (param.value !== null && param.value !== undefined) ? encodeURIComponent(param.value) : '';
                form.appendChild(input);
            }
            let dummy = document.createElement('input');
            dummy.name = currentFinishedReportId;
            dummy.type = 'hidden';
            form.appendChild(dummy)
            form.submit();
        }
    }
    render() {
        let { reportName } = this.props;
        if (!reportName)
            return <b><LinearProgress /></b>;
        return <>
            <LinearProgress ref={x => this.loadingBar = x} id="loading" />
            <iframe title={"reportviewer"} ref={x => this.iframeElement = x} name="reportFrame"
                src={'about:blank'}
                frameBorder="0"
                scrolling={reportName.indexOf('.html')>0 ? 'yes' : 'no'}
                style={{ border: 0, width: '100%', height: '100%' }}
            />

        </>
    }
}

function mapStateToProps(state) {
    return {
        report: state.report
    }
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(ReportViewer));