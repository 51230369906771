import axios from "axios";
const requestDocumentType = 'REQUEST_Document';
const receiveDocumentType = 'RECIEVE_Document';

const initialState = {
    DocumentList:[]
}

export const actionCreators = {
    getDocument: (condition) => async (dispatch) => {
        dispatch({ type: requestDocumentType })

        // let response = await axios.get('api/Document/')

        dispatch({ type: receiveDocumentType, data: [] })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type){
        case receiveDocumentType:
            return { ...state, DocumentList: action.data };
        default:
            return state;
    }
};
