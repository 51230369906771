import axios, { CancelToken} from 'axios'

const requestSeriesType = 'REQUEST_SERIES'
const receiveSeriesType = 'RECIEVE_SERIES'
const selectSeries = 'SELECT_SERIES'
const branchCacheType = 'BRANCH_CACHE'
const initialState = { isLoading: false, isLoaded: false, series: [], currentSeries: {}, branchCacheUrl: '/' }

export const actionCreators = {
    getSeries: (examId) => async (dispatch, getState) => {
        dispatch({ type: requestSeriesType })
        let response = await axios.get(`api/exam/${examId}/series/`)
        dispatch({ type: receiveSeriesType, series: response.data })

        if (response.data && response.data.length) {
            const selected = response.data[0];

            if (selected.branchCacheUrl) {
                let url = selected.branchCacheUrl;
                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                  }, 777);
                let gatewayCheckResponse = await axios.get(url + 'features',{cancelToken: source.token})
                url = ['/', '\\'].indexOf(url[url.length - 1]) >= 0 ? url : url + '/';
                try {
                    if (gatewayCheckResponse && gatewayCheckResponse.data && gatewayCheckResponse.data.indexOf('BRANCH_CACHE')>=0) {
                        let response = await axios.get(`${url}check/${selected.studyId}/${selected.seriesId}/${selected.imageOrientationPatient}`)
                        if (response.data && response.data != 0) {
                            dispatch({ type: branchCacheType, branchCacheUrl: url });
                        }
                    }
                } catch {

                }

            }
        }

        return response;
    },
    selectSeries: (seriesId) => async (dispatch, getState) => {
        const selected = getState().series.filter(x => x.seriesId === seriesId);

        dispatch({ type: selectSeries, series: selected })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case requestSeriesType:
            return { ...state, isLoading: true }
        case receiveSeriesType:
            return { ...state, isLoading: false, isLoaded: true, series: action.series }
        case selectSeries:
            return { ...state, currentSeries: action.series }
        case branchCacheType:
            return { ...state, branchCacheUrl: action.branchCacheUrl }
        default:
            return state;
    }
};
