import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { actionCreators } from "../../../store/Billing";
import _ from 'underscore'
import swal from 'sweetalert'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    LinearProgress,
    Paper,
    Grid,
    TextField,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    CircularProgress,
    Link,
    Snackbar,
    Tab,
    Tabs,
    AppBar,
    Checkbox,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Input
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { Done } from '@material-ui/icons'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
    receiptForm: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    rtlInput: {
        textAlign: 'right'
    }
}));

export function BillingDetail({ billing, history, match, fetchBillingDetail, markAsPaid, markAsUnPaid, toggleRequestSelectRow, changeRequestAllRow, createReceipt }) {
    const classes = useStyles()

    const handleClose = () => {
        history.replace('/accounting/billing/')
    }

    const handlePrintButton = (newBill) => {
        window.open('/billing_report/' + match.params.billingId + (newBill ? '?new=true' : ''));
    }

    useEffect(() => {
        fetchBillingDetail(match.params.billingId)
    }, [match.params.billingId])

    const markAsPaidButtonHandle = async (request) => {
        let response = await markAsPaid(match.params.billingId, request.requestId)
        if (response && response.data && response.data.success)
            setNotifyMessage('Done')
    }

    const markAsUnPaidButtonHandle = (request) => {
        swal({
            title: `ยืนยันการยกเลิกการจ่ายเงินของ ${request.patientName} ในใบวางบิลเลขที่ ${request.paidDocumentId}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (confirm) => {
                if (confirm) {
                    let response = await markAsUnPaid(match.params.billingId, request.requestId)
                    if (response && response.data && response.data.success)
                        setNotifyMessage('Done')
                } else {

                }
            });
    }

    const handlePrintReceipt = async () => {
        let requestForReceipt = billing.billingDetail?.data?.requests?.filter(x => x.paidBillingId == null && x.checked)
        if (requestForReceipt.length > 0) {
            if (receiptForm.bankName && receiptForm.number) {
                if(!receiptForm.amount)
                    receiptForm.amount = checkedPrice;
                let response = await createReceipt(billing.billingDetail.data.billingId, requestForReceipt.map(x => x.requestId), receiptForm);
                if (!response.data.success)
                    swal('', response.data.message, 'error')
                else {
                    setIsRecieveMode(false);
                    setReceiptForm({ type: 'transfer', bankName: '', number: '', amount: '' });
                    openReceipt(response.data.data.receiptId)
                }
            } else swal('', 'กรุณาระบุข้อมูลการชำระเงิน', 'warning')
        }
        else swal('', 'กรุณาเลือกรายการที่ต้องการออกใบเสร็จ', 'warning')
    }

    const [receiptForm, setReceiptForm] = useState({ type: 'transfer', bankName: '', number: '', amount: '' });
    const handleChangeReceiptForm = (name) => (e) => {
        setReceiptForm({ ...receiptForm, [name]: e.target.value });
    }


    const openReceipt = (receiptId) => {
        window.open(`/api/billing/${match.params.billingId}/receipt/${receiptId}/file`)
    }

    const [notifyMessage, setNotifyMessage] = useState(null)
    useEffect(() => {
        if (notifyMessage) {
            setTimeout(() => { setNotifyMessage(null) }, 1000)
        }
    }, [notifyMessage])

    const formatMoney = (x) => x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

    const renderBillingResultRow = (data, filter) => {
        return <>
            {data.map(x => <TableRow key={x.requestId + x.paidDocumentId}>
                {!x.paidBillingId && isRecieveMode
                    ? <TableCell>
                        <Checkbox
                            size="small"
                            checked={x.checked}
                            onChange={() => toggleRequestSelectRow(x)}
                        />
                    </TableCell>
                    : null
                }
                <TableCell>
                    {x.appointmentDate ? format(new Date(x.appointmentDate), 'dd/MM/yyyy HH:mm') : ''}
                </TableCell>
                <TableCell>
                    {x.hn}
                </TableCell>
                <TableCell>
                    {x.patientName}
                </TableCell>
                <TableCell className={classes.rtlInput}>
                    {x.paidBillingId
                        ? <>
                            <Done color="primary" />
                            <Link href="javascript:void()" onClick={() => openReceipt(x.paidBillingId)}>{x.paidDocumentId}</Link>
                        </>
                        : x.isLoading
                            ? <CircularProgress size={28} />
                            : <div>{formatMoney(x.totalPrice)}</div>
                    }
                </TableCell>
            </TableRow>
            )
            }
        </>
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    }

    const paidRecords = billing.billingDetail?.data?.requests?.filter(x => x.paidBillingId)
    const unpaidRecords = billing.billingDetail?.data?.requests?.filter(x => x.paidBillingId == null)

    const checkedPrice = unpaidRecords?.filter(x => x.checked)?.map(x => x.totalPrice)?.reduce((x, y) => x + y, 0);

    const [isRecieveMode, setIsRecieveMode] = useState(false)

    const [tab, setTab] = useState(0);
    const changeTab = (e, i) => {
        setTab(i);
    }
    return (
        <Dialog
            scroll={'paper'}
            maxWidth={'lg'}
            open={true}
            onClose={handleClose}
            minWidth={'lg'}
        >
            <DialogTitle>ใบวางบิล #{billing.billingDetail.data.documentId || match.params.billingId}</DialogTitle>
            <DialogContent>
                {billing.billingDetail.isLoading
                    ? <LinearProgress />
                    : billing.billingDetail.isLoaded
                        ? <div>
                            <Paper style={{ margin: 10, padding: 10 }} elevation={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField label="โรงพยาบาล" defaultValue={billing.billingDetail.data.hospitalName}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField label="วันที่" defaultValue={format(new Date(billing.billingDetail.data.startDate), 'dd/MM/yyyy') + ' - ' + format(new Date(billing.billingDetail.data.endDate), 'dd/MM/yyyy')}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={tab}
                                    onChange={changeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    {unpaidRecords.length > 0 ? <Tab label="ยังไม่ได้จ่าย" /> : null}
                                    {paidRecords.length > 0 ? <Tab label="จ่ายแล้ว" disabled={isRecieveMode} /> : null}

                                    {/* <Tab label="ยังไม่ได้จ่าย" disabled={unpaidRecords.length == 0} />
                                     <Tab label="จ่ายแล้ว" disabled={paidRecords.length == 0} /> */}
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tab} index={unpaidRecords.length > 0 ? 0 : 2}>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table stickyHeader size="small" style={{ minWidth: 800 }}>
                                        <TableHead>
                                            <TableRow>
                                                {isRecieveMode ?
                                                    <TableCell>
                                                        <Checkbox
                                                            size="small"
                                                            checked={_.all(unpaidRecords, x => x.checked)} onChange={(e) => changeRequestAllRow(e.target.checked)}
                                                        />
                                                    </TableCell>
                                                    : null}
                                                <TableCell width={220}>วันที่</TableCell>
                                                <TableCell width={150} style={{ textAlign: 'center' }}>HN</TableCell>
                                                <TableCell>ชื่อ - นามสกุล</TableCell>
                                                <TableCell>จำนวนเงิน</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderBillingResultRow(unpaidRecords)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={tab} index={unpaidRecords.length > 0 ? 1 : paidRecords.length > 0 ? 0 : 2}>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table stickyHeader size="small" style={{ minWidth: 800 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={220}>วันที่</TableCell>
                                                <TableCell width={150}>HN</TableCell>
                                                <TableCell>ชื่อ - นามสกุล</TableCell>
                                                <TableCell width={200} style={{ textAlign: 'center' }}>เลขที่ใบเสร็จ</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderBillingResultRow(paidRecords)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>

                        </div>
                        : null
                }
                <Snackbar
                    open={!!notifyMessage}
                    key={notifyMessage}
                >
                    <Alert severity="success">
                        {notifyMessage}
                    </Alert>
                </Snackbar>
            </DialogContent>
            {!billing.billingDetail.isLoading && billing.billingDetail.isLoaded
                ? <DialogActions>
                    {(unpaidRecords || []).length > 0 && tab == 0
                        ? isRecieveMode
                            ? <form className={classes.receiptForm} noValidate autoComplete="off">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">ชำระโดย</FormLabel>
                                    <RadioGroup aria-label="gender" row value={receiptForm.type} onChange={handleChangeReceiptForm('type')}>
                                        <FormControlLabel value="transfer" control={<Radio />} label="โอน" />
                                        <FormControlLabel value="cheque" control={<Radio />} label="เช็ค" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">ธนาคาร</FormLabel>
                                    <Input defaultValue="" value={receiptForm.bankName} onChange={handleChangeReceiptForm('bankName')} />
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">เลขที่{receiptForm.type === 'transfer' ? 'บัญชี' : 'เช็ค'}</FormLabel>
                                    <Input defaultValue="" value={receiptForm.number} onChange={handleChangeReceiptForm('number')} />
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">จำนวนเงิน</FormLabel>
                                    <Input
                                        className={classes.rtlInput}
                                        value={receiptForm.amount || checkedPrice}
                                        onBlur={() => { handleChangeReceiptForm('amount')({ target: { value: parseFloat(receiptForm.amount) || '' } }) }}
                                        onFocus={(e) => e.target.select()}
                                        onChange={handleChangeReceiptForm('amount')} />
                                </FormControl>
                            </form>
                            : <Button onClick={() => setIsRecieveMode(true)} variant="outlined" color="secondary">
                                ออกใบเสร็จ
                            </Button>
                        : null
                    }
                    <div style={{ flex: '1 0 0' }} />
                    {isRecieveMode ?
                        <>
                            <Button onClick={handlePrintReceipt} variant="outlined" color="secondary">
                                พิมพ์ใบเสร็จรายการที่เลือก
                            </Button>
                            <Button onClick={() => setIsRecieveMode(false)} variant="text" color="default">
                                กลับ
                            </Button>
                        </>
                        : <>
                            {billing.billingDetail.data.isPrinted && unpaidRecords.length > 0 ?
                                <Button onClick={() => handlePrintButton(true)} color="primary">
                                    พิมพ์วางบิล
                            </Button>
                                : null
                            }
                            <Button onClick={() => handlePrintButton(false)} color="primary">
                                {billing.billingDetail.data.isPrinted ? 'ดูใบวางบิล' : 'พิมพ์วางบิล'}
                            </Button>
                            <Button onClick={handleClose} color="default">
                                ปิด
                            </Button>
                        </>
                    }

                </DialogActions>
                : null
            }
        </Dialog>
    );
}
function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(BillingDetail));