import axios from "axios";
import swal from 'sweetalert'

const
    requestPatientType = 'REQUEST_PATIENT',
    receivePatientType = 'RECIEVE_PATIENT',
    requestPatientExamType = 'REQUEST_PATIENT_EXAM',
    receivePatientExamType = 'RECIEVE_PATIENT_EXAM',
    requestPatientFormUIDataType = 'REQUEST_PATIENT_FORM_UIDATA',
    receivePatientFormUIDataType = 'RECIEVE_PATIENT_FORM_UIDATA',
    requestPatientUIDataType = 'REQUEST_PATIENT_UIDATA',
    recievePatientUIDataType = 'RECIEVE_PATIENT_UIDATA',
    requestCreatePatient = 'REQUEST_CREATE_PATIENT',
    receiveCreatePatient = 'RECIEVE_CREATE_PATIENT',
    requestSearchPatientType = 'REQUEST_SEARCH_PATIENT',
    receiveSearchPatientType = 'RECIEVE_SEARCH_PATIENT',
    recieveGatewayFeatures = 'RECIEVE_GATEWAY_FEATURES',
    recieveCardCheckType = 'RECIEVE_CARD_CHECK',
    recieveCardInfoType = 'RECIEVE_CARD_INFO',
    requestCardInfoType = 'REQUEST_CARD_INFO',
    patentChangeConditionType = 'PATIENT_CHANGE_CONDITION'
    ;

const initialState = {
    patientResult: {
        isLoading: false,
        isLoaded: false,
        patientList: []
    },
    patientForm: {
        isLoading: false,
        isLoaded: false,
        patientInfo: {},
        patientExams: {},
        uiData: {
            isLoading: false,
            isLoaded: false,
            data: {
                relationship: [],
                bloodTypes: [],
                namePrefixes: [],
                namePrefixesEnglish: [],
            },
        }
    },
    patientSearchBox: {
        isLoading: false,
        isLoaded: false,
        conditionUIData: {}
    },
    patientUI: {
        isLoading: false,
        isLoaded: false,
        data: {},
        features: [],
        isSearched: false
    },
    idCardInfo:{
        isLoading: false,
        isLoaded: false,
        data: {}
    },
    condition:{ name: '', phoneNumber: '', nationalNumber: '', hn:'' }
}
axios.interceptors.response.use(
    async (response) => {

        if (response.data && response.data.success == false) {
            swal("", response.data.message || "Something went wrong!", "error");
        }

        return response
    },
    (error) => {
        if (error.response) {
            swal("", error.response.data.message, "error");
        }
    });
export const actionCreators = {
    checkQr:(qrId)=>async(dispatch)=>{
        let response = await axios.get(`/api/patient/image/qr/${qrId}`);
        return response;
    },
    getPhotoQRId:(patientId)=>async(dispatch)=>{
        let response = await axios.post(`/api/patient/image/${patientId}/qr`);
        return response;
    },
    changeCondition:(newCondition)=>async(dispatch)=>{
        dispatch({type:patentChangeConditionType, data: newCondition})
    },
    getPatientRomanName: (thaiText) => async (dispatch) => {
        return await axios.get('/api/patient/romanization?thaiText=' + thaiText);
    },
    search: (condition) => async (dispatch,getState) => {
        dispatch({ type: requestSearchPatientType })
        let hasCondition = false;
        if (condition)
            for (var p in condition) {
                if (condition.hasOwnProperty(p) && condition[p]) {
                    hasCondition = true;
                    break;
                }
            }
        else condition = getState().patient.condition;

        let prom = new Promise(async (resolve,reject)=>{
            let response = await axios.post('/api/patient/search', { ...condition }).then(response => {
                dispatch({ type: receiveSearchPatientType, data: response.data.data, hasCondition })
                resolve(response);
            });
        })
        return prom;
    },
    getPatientUIData: () => async (dispatch, getState) => {
        dispatch({ type: requestPatientUIDataType })
        let response = await axios.get('/api/uidata/patient')
        if (response?.data)
            dispatch({ type: recievePatientUIDataType, data: response.data })
        return response
    },
    getGatewayData: () => async (dispatch, getState) => {
        let statePatientUI = getState().patient.patientUI;
        if (statePatientUI?.data?.gatewayUrl) {
            let gatewayCheckResponse = await axios.get(statePatientUI?.data?.gatewayUrl + 'features')
            if (gatewayCheckResponse && gatewayCheckResponse.data)
                dispatch({ type: recieveGatewayFeatures, data: gatewayCheckResponse.data })
            return gatewayCheckResponse;
        }
    },
    checkCardReader: () => async (dispatch, getState) => {
        let statePatientUI = getState().patient.patientUI;
        if (statePatientUI?.data?.idCardReaderUrl) {
            let cardCheckResponse = await axios.get(statePatientUI?.data?.idCardReaderUrl + 'api/check')
            if (cardCheckResponse && cardCheckResponse.data)
                dispatch({ type: recieveCardCheckType, data: cardCheckResponse.data })
            return cardCheckResponse;
        }
    },
    getCardInfo: () => async (dispatch, getState) => {
        let statePatientUI = getState().patient.patientUI;
        if (statePatientUI?.data?.idCardReaderUrl) {
            dispatch({ type: requestCardInfoType })
            let fetchCardInfoResponse = await axios.get(statePatientUI?.data?.idCardReaderUrl + 'api/fetch')
            if (fetchCardInfoResponse && fetchCardInfoResponse.data)
                dispatch({ type: recieveCardInfoType, data: fetchCardInfoResponse.data })
            return fetchCardInfoResponse;
        }
    },
    findPatientFromGateway: (hn) => async (dispatch, getState) => {
        let statePatientUI = getState().patient.patientUI;
        if (statePatientUI?.data?.gatewayUrl) {
            let gatewayPatientResponse = await axios.get(statePatientUI?.data?.gatewayUrl + 'api/patient/hn/' + hn)
            return gatewayPatientResponse;
        }
    },
    getFormUIData: () => async (dispatch) => {
        dispatch({ type: requestPatientFormUIDataType })
        let response = await axios.get('/api/uidata/patientForm')
        dispatch({ type: receivePatientFormUIDataType, data: response })
    },
    getPatientInfo: (patientId) => async (dispatch) => {
        dispatch({ type: requestPatientType })
        let promise = axios.get(`/api/patient/${patientId}`);
        promise.then(response => {
            dispatch({ type: receivePatientType, data: response.data.data })
        })

        return promise;
    },
    getPatientExams: (patientId) => async (dispatch) => {
        dispatch({ type: requestPatientExamType })
        let promise = axios.get(`/api/patient/${patientId}/exam`);
        promise.then(response => {
            dispatch({ type: receivePatientExamType, data: response.data.data })
        })

        return promise;
    },
    createPatient: (patientInfo) => async (dispatch, getState) => {
        dispatch({ type: requestCreatePatient })
        let response = await axios.post(`/api/patient/`, patientInfo);
        if (response && response.statusText === 'OK' && response.data.success)
            dispatch({ type: receiveCreatePatient, data: response.data.data });
        else
            dispatch({ type: receiveCreatePatient, data: patientInfo })
        return response;
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case patentChangeConditionType:
            return {
                ...state,
                condition: action.data
            }
        case requestCardInfoType:
            return{
                ...state,
                idCardInfo:{
                    ...state.idCardInfo,
                    isLoading: true,
                    isLoaded: false,
                    data: null
                }
            }
        case recieveCardInfoType:
            return{
                ...state,
                idCardInfo:{
                    ...state.idCardInfo,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data.data
                },
                condition:{
                    ...state.condition,
                    nationalNumber: action.data?.data?.nationalNumber || state.condition.nationalNumber
                }
            }
        case recieveGatewayFeatures:
            return {
                ...state,
                patientUI: {
                    ...state.patientUI,
                    isImportAvailable: action.data.indexOf('IMPORT') >= 0,
                    features: action.data
                }
            }
        case requestPatientUIDataType:
            return {
                ...state,
                patientUI: {
                    ...state.patientUI,
                    isLoading: true,
                    isLoaded: false,
                    isImportAvailable: false,
                    data: {}
                }
            }
        case recievePatientUIDataType:
            return {
                ...state,
                patientUI: {
                    ...state.patientUI,
                    isLoading: false,
                    isLoaded: true,
                    data: { ...action.data },
                    enableFindPatientByCardReader: !!action.data?.idCardReaderUrl
                }
            }
        case recieveCardCheckType:
            return {
                ...state,
                patientUI: {
                    ...state.patientUI,
                    isLoading: false,
                    isLoaded: true,
                    isCardReaderReady: action.data.success
                }
            }
        case requestSearchPatientType:
            return {
                ...state,
                patientResult: {
                    ...state.patientResult,
                    isLoading: true,
                    isLoaded: false,
                    patientList: []
                }
            }
        case receiveSearchPatientType:
            return {
                ...state,
                patientResult: {
                    ...state.patientResult,
                    isLoading: false,
                    isLoaded: true,
                    patientList: action.data
                },
                patientUI: {
                    ...state.patientUI,
                    isSearched: action.hasCondition
                }
            }
        case requestPatientFormUIDataType:
            return {
                ...state,
                patientForm: {
                    ...state.patientForm,
                    uiData: {
                        ...state.patientForm.uiData,
                        isLoading: true,
                        isLoaded: false,
                        data: {}
                    }
                }
            }
        case receivePatientFormUIDataType:
            return {
                ...state,
                patientForm: {
                    ...state.patientForm,
                    uiData: {
                        ...state.patientForm.uiData,
                        isLoading: false,
                        isLoaded: true,
                        data: action.data.data
                    }
                }
            }
        case requestPatientType:
            return {
                ...state,
                patientForm: {
                    ...state.patientForm,
                    isLoading: true,
                    isLoaded: false,
                    patientInfo: {}
                }
            }
        case receivePatientType:
            return {
                ...state,
                patientForm: {
                    ...state.patientForm,
                    isLoading: false,
                    isLoaded: true,
                    patientInfo: action.data
                }
            }
            case requestPatientExamType:
                return {
                    ...state,
                    patientForm: {
                        ...state.patientForm,
                        patientExams: {
                            isLoading: true,
                            isLoaded: false,
                            data: []
                        }
                    }
                }
            case receivePatientExamType:
                return {
                    ...state,
                    patientForm: {
                        ...state.patientForm,
                        patientExams: {
                            isLoading: false,
                            isLoaded: true,
                            data: action.data
                        }
                    }
                }
        case requestCreatePatient:
            return {
                ...state,
                patientForm: {
                    ...state.patientForm,
                    isLoading: true,
                    isLoaded: false
                }
            }
        case receiveCreatePatient:
            let { nameTH, phoneNumber, nationalNumber } = action.data;
            const displayName = [nameTH.prefix, nameTH.first, nameTH.middle, nameTH.last, nameTH.suffix].filter(x => x).join(' ');
            return {
                ...state,
                patientResult: {
                    ...state.patientResult,
                    patientList: [{ name: displayName, phoneNumber: phoneNumber, nationalNumber: nationalNumber }, ...state.patientResult.patientList]
                },
                patientForm: {
                    ...state.patientForm,
                    isLoading: false,
                    isLoaded: true,
                    patientInfo: action.data
                }
            }
        default:
            return state;
    }
};
