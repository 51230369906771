import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'

import Billing from './Billing'
import Money from './Money'
import Cost from './Cost'

import {
    Grid,
    List,
    Paper,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    ListItemLink,
    Icon
} from '@material-ui/core'


import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    paper: {
    }
});

const Accounting = ({ history, match, user }) => {
    const classes = useStyles();

    const accountMenus = Object.keys(user.permission).filter(x => x.indexOf('MENU_ACCOUNTING_') == 0).map(x => x.substr('MENU_ACCOUNTING_'.length).toLowerCase());

    const menuUI = {
        billing: { icon: 'receipt', text: 'ใบวางบิล' },
        money: { icon: 'attach_money', text: 'เงินสดย่อย' },
        cost: { icon: 'attach_money', text: 'ค่าตรวจ' }
    }

    useEffect(()=>{
        history.push('/accounting/'+accountMenus[0])
    },[])
    return <div className="Accounting">


        <Grid
            style={{ height: '100%', width: '100%' }}
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="stretch"
        >
            <Grid item xs={2} style={{ height: '100%' }}>
                <Paper className={classes.paper} style={{ overflowY: 'auto', height: '100%' }}>
                    <List component="nav" aria-label="main mailbox folders">
                        {accountMenus.map(x => <ListItem button selected={match.params.page === x} onClick={() => history.push('/accounting/' + x)}>
                            <ListItemIcon>
                                <Icon>{menuUI[x].icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={menuUI[x].text} />
                        </ListItem>
                        )}
                    </List>

                </Paper>
            </Grid>
            <Grid item xs={10} style={{ height: '100%' }}>
                <Route path='/accounting/billing/' component={Billing} />
                <Route path='/accounting/money/' component={Money} />
                <Route path='/accounting/cost/' component={Cost} />
            </Grid>
        </Grid>
    </div>
}
function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(Accounting);
