import React from "react";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import RecordSearchCondition from "./RecordSearchCondition";
import RecordResult from "./RecordResult";

class Records extends React.Component {
    render() {
        return (
            <div>
                <RecordSearchCondition />
                <Divider variant="middle" style={{ margin: "10px 0" }} />
                <RecordResult />
            </div>
        );
    }
}

export default connect()(Records);
