import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from "../../../store/Billing";
import { withRouter } from 'react-router'
import { format } from 'date-fns'
import _ from 'underscore'
import {
    CircularProgress,
    LinearProgress,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
    Button,
    Checkbox
} from '@material-ui/core'
import { Done } from '@material-ui/icons';

const useStyles = makeStyles({
    container: {
        maxHeight: 600,
        overflow:'auto'
    },
});

export function BillingSearchResult({ billing, history, toggleSelectRow, changeCheckedAllRow, createBilling, highlightHistory }) {

    const classes = useStyles();


    const renderBillingResultRow = (data, filter) => {
        return <>
            {data.map(x => <TableRow key={x.requestId + x.paidBillingId}>
                <TableCell>
                    {!x.paidBillingId
                        ? <Checkbox size="small" checked={x.checked} onChange={() => toggleSelectRow(x)} disabled={x.paidBillingId} />
                        : null
                    }
                </TableCell>
                <TableCell>
                    {x.appointmentDate ? format(new Date(x.appointmentDate), 'dd/MM/yyyy HH:mm') : ''}
                </TableCell>
                <TableCell>
                    {x.patientName}
                </TableCell>
                <TableCell>
                    <Link
                        href="javascript:void()"
                        onMouseEnter={() => highlightHistory(x.latestBillingId ? x.latestBillingId : x.latestBillingId, true)}
                        onMouseLeave={() => highlightHistory(x.latestBillingId ? x.latestBillingId : x.latestBillingId, false)}
                        onClick={() => history.push(`/accounting/billing/${x.latestBillingId ? x.latestBillingId : x.latestBillingId}`)}>
                        {x.latestBillingId ? x.latestBillingDocumentId : x.latestBillingDocumentId}
                    </Link>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                    {x.paidBillingId ? <Done /> : '-'}
                </TableCell>
            </TableRow>
            )
            }
        </>
    }

    const createBillingHandler = async () => {
        const response = await createBilling(billing.data.filter(x => x.checked && !x.paidBillingId).map(x => x.requestId));
        if (response.data && response.data.data) {
            const billingId = response.data.data.billingId;
            changeCheckedAllRow(false)
            history.push('/accounting/billing/' + billingId)
            window.open('/billing_report/' + billingId)
        }
    }

    return (
        <>
            {billing.isLoading
                ? <LinearProgress />
                : !billing.isLoaded
                    ? <div style={{ textAlign: 'center' }}>กรุณาเลือกโรงพยาบาล</div>
                    : !billing.data || billing.data.length === 0
                        ? <div style={{ textAlign: 'center' }}>No result.</div>
                        : <>
                            <TableContainer className={classes.container} component={Paper} variant="outlined" >
                                <Table stickyHeader size="small" style={{ minWidth: 800 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={80}><Checkbox size="small" checked={_.all(billing.data.filter(x => !x.paidBillingId), x => x.checked)} onChange={(e) => changeCheckedAllRow(e.target.checked)} /></TableCell>
                                            <TableCell width={220}>วันที่</TableCell>
                                            <TableCell>คนไข้</TableCell>
                                            <TableCell width={120}>เลขที่ใบวางบิล</TableCell>
                                            <TableCell width={120}>การชำระเงิน</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {renderBillingResultRow(billing.data.filter(x => x.paidBillingId == null))}
                                        {renderBillingResultRow(billing.data.filter(x => x.paidBillingId))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Paper style={{ textAlign: 'center', padding: 20 }}>
                                <Button variant="contained" color="primary" onClick={createBillingHandler}>วางบิล</Button>
                            </Paper>
                        </>
            }
        </>
    );
}
function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(BillingSearchResult));