import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";

import { actionCreators } from "../store/Menu";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(5),
    },
}));

const NavContent = ({ menu, section, history }) => {

    const clickMenu = (code) => {
        history.push('/' + code)
    }
    if (!menu || !menu.menu || menu.menu.length == 0)
        return <LinearProgress />

    let updatedMenu = menu.menu;
    let reportMenu = updatedMenu.find(x => x.permissionCode === 'branch_report')
    if (reportMenu)
        reportMenu.subMenu = [{ permissionCode: 'branch_report/daily', title: 'รายวัน' }, { permissionCode: 'branch_report/monthly', title: 'รายเดือน' }]



    return (
        <List>
            {updatedMenu.map(x => <MenuList {...x} clickMenu={clickMenu} section={section} />)}
            <Divider style={{ margin: "12px 0" }} />
            {/* <ListItem button>
                <ListItemIcon>
                    <Icon>settings</Icon>
                </ListItemIcon>
                <ListItemText
                    primary={"Settings & account"}
                    primaryTypographyProps={{ noWrap: true }}
                />
            </ListItem> */}
        </List>
    );
}

const MenuList = ({ title, permissionCode, subMenu, clickMenu,section }) => {

    const classes = useStyles();

    let [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    return <>
        <ListItem
            selected={section && permissionCode.indexOf(section) > 0}
            button
            onClick={() => subMenu ? setIsOpenSubMenu(!isOpenSubMenu) : clickMenu(permissionCode)}
        >
            <ListItemIcon>
                <Icon>folder</Icon>
            </ListItemIcon>
            <ListItemText
                primary={title}
                primaryTypographyProps={{ noWrap: true }}
            />
        </ListItem>
        <Collapse in={isOpenSubMenu} timeout="auto" unmountOnExit>
            {subMenu
                ? subMenu.map(x => <List
                    key={x.permissionCode}
                    component="div"
                    disablePadding
                    onClick={(e) => { clickMenu(x.permissionCode) }}
                >
                    <ListItem button className={classes.nested}>
                        <ListItemIcon>
                            <Icon>subject</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={x.title}
                            primaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                </List>)
                : null
            }
        </Collapse>
    </>
}

NavContent.propTypes = {};
NavContent.defaultProps = {};

function mapStateToProps(state) {
    return {
        menu: state.menu
    }
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(NavContent));