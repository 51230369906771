import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash'
import {
    Grid,
    Radio,
    RadioGroup,
    MenuItem,
    InputAdornment,
    Select
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles';
import { actionCreators } from '../../store/Request';
import { ImageSelectorButton } from './ImageSelectorButton';

export function AdditionalFieldForm({ requestId, additionalFields, additionalFieldValue,changeAdditionalFieldVisibility,handleChangeFormValue, imageList }) {
    let dependencies = additionalFields.map(x => x.dependencies.map(y => { return { ...y, id: x.id } })).flat()
    .reduce((group, product) => {
        const { dependencyFieldId } = product;
        group[dependencyFieldId] = group[dependencyFieldId] ?? [];
        group[dependencyFieldId].push(product);
        return group;
    }, {});;
    useEffect(() => {
        for (let k in additionalFieldValue) {
                if (dependencies[k]) {
                    for (let i = 0; i < dependencies[k].length; i++) {
                        let depen = dependencies[k][i];

                        let trigged = false;
                        switch (depen.trigOperator) {
                            case '=':
                                trigged = additionalFieldValue[k] == depen.trigValue
                                break;
                            case '>':
                                trigged = parseFloat(additionalFieldValue[k]) > parseFloat(depen.trigValue)
                                break;
                            case '<':
                                trigged = parseFloat(additionalFieldValue[k]) < parseFloat(depen.trigValue)
                                break;
                            case '>=':
                                trigged = parseFloat(additionalFieldValue[k]) >= parseFloat(depen.trigValue)
                                break;
                            case '<=':
                                trigged = parseFloat(additionalFieldValue[k]) <= parseFloat(depen.trigValue)
                                break;
                            case 'contains':
                            case 'contain':
                                trigged = additionalFieldValue[k].indexOf(depen.trigValue) > 0
                                break;
                        }

                        if (trigged) {
                            switch (depen.action) {
                                case 'show':
                                    changeAdditionalFieldVisibility(depen.id, 'visible')
                                    break;
                                case 'hide':
                                    changeAdditionalFieldVisibility(depen.id, 'hidden')
                                    break;
                            }
                        }
                    }
                }
        }

    }, [additionalFieldValue])

    return additionalFields.map(z =>
        <Grid xs={(z.width||1) * 4} key={z.id} style={{ padding: 5, visibility: z.visibility, height:((z.height||1)*84) }}>
            <>
                {z.name ?<>
                <span style={{ fontSize: '0.9em', color: 'gray' }}>{z.name}</span>
                <br />
                </>
                :null}
                {
                    z.type == 'Blank'
                    ? <></>
                    : z.type == 'Html'
                    ? <div dangerouslySetInnerHTML={{__html:z.description}}></div>
                    : z.type == 'UploadImage'
                    ? <ImageSelectorButton 
                        id={requestId} 
                        height={(84*(z.height||1))-10-(z.name?16.8:0)} 
                        imageList={imageList} 
                        value={additionalFieldValue[z.id] || ''} 
                        onChange={(e) => handleChangeFormValue('additionalFields', { ...additionalFieldValue, [z.id]: e })} 
                        onUploadNewImage={(fileId)=>{handleChangeFormValue('imageList',[...imageList, {id:fileId,name:'',img:`/api/request/${requestId}/media/${fileId}`}])}}
                    />
                    // ? <div>
                    //     <input type="file" id={`uploadImage_${z.id}`} onChange={(e)=>{
                    //         const file = e.target.files[0];
                    //         const browse = e.target;
                    //         const reader = new FileReader();
                    //         const image = document.getElementById(`img_${z.id}`);
                    //         reader.onload = (event)=> {
                    //             browse.style.display='none';
                    //             image.style.display='block';
                    //             image.src = event.target.result;
                    //         };
                          
                    //         reader.readAsDataURL(file)
                    //     }} accept="image/*"></input>
                    //     <img id={`img_${z.id}`} src={''} className="additional-field-preview-image" onClick={(e)=>{
                    //         e.target.src = '';
                    //         let browse = document.getElementById(`uploadImage_${z.id}`);
                    //         browse.value='';
                    //         browse.style.display='block';
                    //         e.target.style.display='none;'
                    //     }} style={{display:'none', maxWidth:'100%', maxHeight:(84*(z.height||1))-10-(z.name?16.8:0)}} />
                    // </div>
                    : z.options?.length && z.isMultiValue
                        ? z.options.map(a => <>
                            <FormControlLabel
                                label={a}
                                fullWidth
                                control={<Checkbox
                                    label={a}
                                    key={a}
                                    type={'checkbox'}
                                    name={z.id}
                                    value={a}
                                    defaultChecked={additionalFieldValue && (JSON.parse('[' + (additionalFieldValue[z.id] || '') + ']').flat().indexOf(a) >= 0)}
                                    onChange={(e) => {
                                        handleChangeFormValue('additionalFields', {
                                            ...additionalFieldValue, [z.id]:
                                                (e.target.checked
                                                    ? JSON.stringify([...JSON.parse('[' + (additionalFieldValue[z.id] || '') + ']').flat(), a])
                                                    : JSON.stringify([...JSON.parse('[' + (additionalFieldValue[z.id] || '') + ']').flat()].filter(x => x != e.target.value))
                                                )
                                        });
                                    }}
                                />}
                            />
                        </>)
                        : z.options?.length
                            ? (z.options?.length < 3
                                ? <RadioGroup name={z.id} row defaultValue={(additionalFieldValue[z.id] || '')}>
                                    {z.options.map(a => <>
                                        <FormControlLabel label={a} control={<Radio label={a} key={a} type={'radio'} name={z.id} value={a}
                                            onChange={(e) => {
                                                handleChangeFormValue('additionalFields', { ...additionalFieldValue, [z.id]: e.target.checked ? a : '' })
                                            }}
                                        />} />
                                    </>)
                                    }
                                </RadioGroup>
                                : <Select
                                    value={(additionalFieldValue[z.id] || '')}
                                    name={z.id}
                                    onChange={(e) => {
                                        handleChangeFormValue('additionalFields', { ...additionalFieldValue, [z.id]: e.target.value })
                                    }}
                                    fullWidth
                                >
                                    {z.options.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
                                </Select>)
                            : <TextField fullWidth
                                value={additionalFieldValue ? (additionalFieldValue[z.id] || '') : ''}
                                type={z.type}
                                multiline={z.type == 'Textarea'}
                                rows={2*(z.height||1)}
                                style={{height: (84*(z.height||1))-20}}
                                onChange={(e) => handleChangeFormValue('additionalFields', { ...additionalFieldValue, [z.id]: e.target.value })}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{z.suffix}</InputAdornment>,
                                }}
                            />
                }
            </>
        </Grid>
    )
}

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});
function mapStateToProps(state) {
    return {
        request: state.request,
        user: state.user
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(AdditionalFieldForm));
