import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Records';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    LinearProgress,
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    FormHelperText,
    Typography,
    Collapse,
    Input,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
    Checkbox
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});

export function HistoryDialog({ open, handleClose, examId, exam, getRelateExam, historyDialog, recordRenderer }) {


    const handleEnter = async () => {
        await getRelateExam(examId)
    }

    const { isLoading, data } = historyDialog;

    return (
        <Dialog
            open={open}
            onEnter={handleEnter}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>History</DialogTitle>
            <DialogContent>
                {isLoading ? <LinearProgress /> :
                    data && exam &&
                    <>
                        <Paper style={{ margin: 10, padding: 10 }} elevation={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField label="ชื่อ" defaultValue={exam.patientName}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="HN" defaultValue={exam.hn}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <TableContainer component={Paper} variant="outlined" >
                            <Table stickyHeader size="small" style={{ minWidth: 800 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>AccessionNumber</TableCell>
                                        <TableCell>Exam Date</TableCell>
                                        <TableCell>Modality</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Recieve On</TableCell>
                                        <TableCell>Documents</TableCell>
                                        <TableCell>Files</TableCell>
                                        <TableCell colSpan={4}>Image</TableCell>
                                        <TableCell>Report</TableCell>
                                        <TableCell colSpan={2}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.map(x => recordRenderer(x, true))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

function mapStateToProps(state) {
    return {
        historyDialog: state.records.historyDialog,
        user: state.user
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(HistoryDialog));
