import React from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Patient";
import { withRouter } from "react-router";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import LinearProgress from '@material-ui/core/LinearProgress';

export function PatientResult({ history, patient, user }) {
    if (patient.patientResult.isLoading)
        return <LinearProgress />
    return <Table size="small" stickyHeader>
        <TableHead>
            <TableRow>
                <TableCell align="center">ชื่อ - นามสกุล</TableCell>
                <TableCell align="center">Name - Surname</TableCell>
                <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                <TableCell align="center">HN</TableCell>
                <TableCell align="center">หมายเลขบัตรประชาชน</TableCell>
                <TableCell align="center">Edit</TableCell>
                {user.permission['REQUEST_CREATE'] && <TableCell align="center">Exam</TableCell>}
            </TableRow>
        </TableHead>
        <TableBody>
            {patient.patientResult.patientList.map(x =>
                <TableRow hover>
                    <TableCell style={{color: x.hasAllergy ? '#da0808':''}}>{x.name}</TableCell>
                    <TableCell style={{color: x.hasAllergy ? '#da0808':''}}>{x.nameEn}</TableCell>
                    <TableCell align="center">{x.phoneNumber}</TableCell>
                    <TableCell align="center">{x.hn}</TableCell>
                    <TableCell align="center">{x.nationalNumber?x.nationalNumber+"xxxxx":""}</TableCell>
                    <TableCell align="center">
                        <EditIcon style={{ cursor: "pointer" }} onClick={_ => history.push(`/patient/${x.patientId}`)} />
                    </TableCell>
                    {user.permission['REQUEST_CREATE'] && <TableCell align="center">
                        <PlayArrowIcon
                            style={{ cursor: "pointer" }}
                            onClick={_ => history.push(`/request/new/${x.patientId}`)}
                        />
                    </TableCell>
                    }
                </TableRow>
            )}

        </TableBody>
    </Table>
}

function mapStateToProps(state) {
    return {
        patient: state.patient,
        user: state.user
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(PatientResult));