import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function ReportSelector({ reportList, onSelectReport, reportName, finishedReports }) {

  const handleChange = (event, newValue) => {
    onSelectReport(reportList[newValue]);
  };

  const greyOldReport = reportList && reportList.filter(x=>x.indexOf('_PACS_')>0).length>1;

  return (
    <Paper square>
      <Tabs
        value={reportList.indexOf(reportName)}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        {reportList && reportList.map((x,i) =>
          <Tab key={x} style={{color:(i>0&&greyOldReport?'#dddddd':'')}} label={x.replace('.html', '').split('_').slice(-1).join('')
            + ((!finishedReports || finishedReports.filter(y => x == y.reportName).length == 0)
              ? ''
              : ` (${finishedReports.filter(y => x == y.reportName).length})`)}
          />
        )}
      </Tabs>
    </Paper>
  );
}