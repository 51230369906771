import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Records";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    Grid,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Chip,
    FormControl,
    FormLabel,
    Typography
} from "@material-ui/core";
import { format } from "date-fns";
import { Attachment } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Uploader from "../Shared/Uploader";

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});

class UploadDocumentDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            fileName: "",
            file: null,
            fileError: false,
            errorText: null
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.openDocument = this.openDocument.bind(this);
    }

    openDocument(documentId) {
        window.open(`/api/exam/${this.props.examId}/document/${documentId}`);
    }

    async handleEnter() {
        this.props.getDocuments(this.props.examId, this.props.type);
    }

    handleClose() {
        this.setState({
            uploading: false,
            fileName: "",
            file: null,
            fileError: false,
            errorText: null
        });
        this.props.handleClose();
    }

    render() {
        const { open, allowUpload } = this.props;

        return (
            <Dialog
                open={open}
                onEnter={this.handleEnter}
                onClose={this.handleClose}
                maxWidth="sm"
                fullWidth={true}
            // disableBackdropClick={this.state.uploading}
            // disableEscapeKeyDown={this.state.uploading}
            >
                <DialogTitle>{this.props.type === 'document' ? 'Documents' : 'Files'}</DialogTitle>
                <DialogContent>
                    <Uploader
                        handleClose={this.handleClose}
                        uploadDocument={(filename, file) => this.props.uploadDocument(this.props.examId, filename, file, this.props.type)}
                        allowUpload={allowUpload}
                        accept={this.props.type === 'document' ? '.jpg,.tiff,.pdf,.png,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpeg,.txt' : '*.*'}
                    >
                        {this.props.type === 'document'
                            ?
                            <>
                                {this.props.allergy && this.props.allergy.length
                                    ? <div style={{ marginBottom: 15 }}>
                                        <Typography style={{ color: '#777' }}>ประวัติการแพ้</Typography>
                                        {this.props.allergy.map(x => <Chip key={x} label={x} width={'auto'} />)}
                                    </div>
                                    : null
                                }
                                {this.props.remark
                                    ? <TextField value={this.props.remark}
                                        multiline
                                        label="Note"
                                        rows="3"
                                        variant="outlined"
                                        readonly={true}
                                        fullWidth
                                    />
                                    : null
                                }
                            </>
                            : null
                        }
                        {this.props.documentDialog.documents.length ? (
                            <Grid item xs={12}>
                                <Divider />
                                {/* <Typography>Documents</Typography> */}
                                <List dense={false}>
                                    {this.props.documentDialog.documents.map(x => (
                                        <ListItem>
                                            <ListItemIcon>
                                                <Attachment />
                                            </ListItemIcon>
                                            <ListItemText
                                                onClick={e => this.openDocument(x.documentId)}
                                                style={{ cursor: "pointer" }}
                                                primary={x.fileName}
                                                secondary={`Upload by ${x.uploadBy} on ${format(new Date(x.uploadDate), "dd-MM-yyyy HH:mm")}`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        ) : null}
                        {this.props.documentDialog.isLoading ? (
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                        ) : null}
                    </Uploader>
                </DialogContent>
                <DialogActions />
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        documentDialog: state.records.documentDialog
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(UploadDocumentDialog));
