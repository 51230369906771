import axios from "axios";
import swal from 'sweetalert'
import QRCode from 'qrcode'
import { startOfDay, endOfDay } from "date-fns";
const
    requestRequestType = 'REQUEST_SEARCH_REQUEST',
    receiveRequestType = 'RECIEVE_SEARCH_REQUEST',
    // requestRequestEditType = 'a',
    // receiveRequestEditType = 'b',
    requestUIDataType = 'REQUEST_REQUESTUIDATA',
    receiveUIDataType = 'RECIEVE_REQUESTUIDATA',
    requestDocumentUploadType = 'REQUEST_UPLOAD_DOCUMENT',
    receiveDocumentUploadType = 'RECIEVE_UPLOAD_DOCUMENT',
    requestPatientInfoType = 'REQUEST_PATIENT_INFO',
    receivePatientInfoType = 'RECIEVE_PATIENT_INFO',
    requestCreateRequestForm = 'REQUEST_CREATE_REQUEST_FORM',
    receiveCreateRequestForm = 'RECIEVE_CREATE_REQUEST_FORM',
    requestCreateExportLink = 'REQUEST_CREATE_EXPORT_LINK',
    receiveCreateExportLink = 'RECIEVE_CREATE_EXPORT_LINK',
    requestGetExportLink = 'REQUEST_GET_EXPORT_LINK',
    receiveGetExportLink = 'RECIEVE_GET_EXPORT_LINK',
    clearNewFormType = 'REQUEST_CLEAR_NEW_FORM',
    requestDeleteReasonType = 'REQUEST_DELTE_REASON',
    receiveDeleteReasonType = 'RECEIVE_DELETE_REASON',
    requestDeleteFormType = 'REQUEST_DELETE_REQUEST',
    receiveDeleteFormType = 'RECEIVE_DELETE_REQUEST',
    requestPrivilagePrice = 'REQUEST_PRIVILAGE_PRICE',
    receivePrivilagePrice = 'RECIEVE_PRIVILAGE_PRICE',
    changeAdditionalFieldVisibilityAction = 'REQUEST_ADDITIONAL_FIELD_CHANGE_VISIBILITY',
    requestChangePatientImage = 'REQUEST_PATIENT_CHANGE_IMAGE',
    receiveChangePatientImage = 'RECEIVE_PATIENT_CHANGE_IMAGE'
    ;


const initialState = {
    requestResult: {
        isLoading: false,
        isLoaded: false,
        resultList: []
    },
    conditionUIData: {
        isLoading: false,
        isLoaded: false,
        modalityProducts: [],
        hospitalPrivilages: [],
        hospitalDoctors: [],
        allergies: [],
        hospitalBuildingNames:[]
    },
    newRequest: {
        patientInfo: {},
        isLoading: false,
        isLoaded: false,
        createdRequest: [],
        privilagePrices: {}
    },
    linkDialog: {
        isLoading: false,
        isLoaded: false,
        data: { patientName: '', linkId: '', requestId: '' }
    },
    deleteDialog: {
        isLoading: false,
        isLoaded: false,
        data: { reason: '', reasonOptions: [] }
    }
}
axios.interceptors.response.use(async (response) => {

    if (response.data && response.data.success == false) {
        swal("", response.data.message || "Something went wrong!", "error");
    }
    return response

},
(error) => {
    if(error.response.status == 401)
    {
        window.location.href = '/Member/LogOn?ReturnUrl=';
    }
    else if (error.response && error.response.data) {
        swal("", error.response.data.message + (error.data.errors && error.data.errors.length? '\n'+error.data.errors.map(x=>x.defaultMessage).join(', '):''), "error");
    }
});
export const actionCreators = {
    getPrivilagePrice: (hospitalId,privilageId) => async (dispatch) => {
        dispatch({ type: requestPrivilagePrice, privilageId });
        let response = await axios.get(`/api/hospital/${hospitalId}/privilages/${privilageId}/pricing`)
        if (response.data && response.data.success)
            dispatch({ type: receivePrivilagePrice, data: response.data.data, privilageId: privilageId });
        return response;
    },
    changeAdditionalFieldVisibility:(fieldName, visibility) => async (dispatch) => {
        dispatch({ type: changeAdditionalFieldVisibilityAction, fieldName, visibility });
    },
    createInvoice: (requestId) => async (dispatch) => {

    },
    deleteRequest: (requestId, reason) => async (dispatch) => {
        dispatch({ type: requestDeleteFormType });
        let response = await axios.post(`/api/request/${requestId}/delete/`, { remark: reason })
        if (response.data)
            dispatch({ type: receiveDeleteFormType, data: response.data.data, requestId: requestId });
        return response;
    },
    getDeleteReason: (requestId) => async (dispatch) => {
        dispatch({ type: requestDeleteReasonType });
        let response = await axios.get(`/api/request/${requestId}/deleteReason`)
        if (response.data)
            dispatch({ type: receiveDeleteReasonType, data: response.data.data.reasonOptions });
        return response;
    },
    createLink: (requestId) => async (dispatch) => {
        dispatch({ type: requestCreateExportLink });
        let response = await axios.post('/api/request/link/' + requestId)
        let data = response.data.data;
        data.qrcode = await QRCode.toDataURL(data.linkHostUrl + 'link/' + data.linkId)
        dispatch({ type: receiveCreateExportLink, data: data });
    },
    getLink: (requestId) => async (dispatch) => {
        dispatch({ type: requestCreateExportLink });
        let response = await axios.get('/api/request/link/' + requestId)
        dispatch({ type: receiveCreateExportLink, data: response.data.data });
    },
    search: (page, condition) => async (dispatch) => {
        dispatch({ type: requestRequestType })

        if (condition.startDate)
            condition.startDate = startOfDay(condition.startDate);
        if (condition.endDate)
            condition.endDate = endOfDay(condition.endDate);
        let response = await axios.post('api/request/search', condition);

        dispatch({ type: receiveRequestType, data: response.data.data })
    },
    fetchSearchConditionUIData: () => async (dispatch) => {
        dispatch({ type: requestUIDataType })

        let response = await axios.get('api/uidata/requestCondition')

        dispatch({
            type: receiveUIDataType,
            data: response.data
        })

        return response;
    },
    uploadDocument: (fileName, file) => async (dispatch, getState) => {

        dispatch({ type: requestDocumentUploadType })
        var formData = new FormData();
        formData.append("file", file)
        formData.append('fileName', fileName)
        let response = await axios.post(`api/document/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        dispatch({
            type: receiveDocumentUploadType,
            data: response
        });

        return response;
    },
    checkExportExam: (examId) => async () => {
        let response = await axios.get(`dicom/export/${examId}/check`);
        return response
    },
    getPatientById: (patientId) => async (dispatch) => {
        dispatch({ type: requestPatientInfoType })

        let response = await axios.get('/api/request/patient/' + patientId)

        dispatch({ type: receivePatientInfoType, data: response.data.data })

        return response;
    },
    getRequestById: (requestId) => async (dispatch) => {
        // dispatch({ type: requestRequestEditType })
        dispatch({ type: requestPatientInfoType });

        let response = await axios.get('/api/request/' + requestId)

        dispatch({ type: receivePatientInfoType, data: response.data.data.patientInfo })

        return response;
    },
    getDocumentByIds: (documentIds) => async (dispatch) => {
        let response = await axios.get('/api/document/' + documentIds.join(','));

        return response;
    },
    clearNewRequestForm: () => async (dispatch) => {
        dispatch({ type: clearNewFormType })
    },
    updateRequest: (requestId, form) => async (dispatch) => {
        dispatch({ type: requestCreateRequestForm })

        let response = await axios.post(`/api/request/edit/${requestId}`, form)
        if (response && response.data && response.data.data)
            dispatch({ type: receiveCreateRequestForm, data: response.data.data, requestId: requestId })
        else dispatch({ type: receiveCreateRequestForm, data: null, requestId: requestId })
        return response;
    },
    createRequest: (form) => async (dispatch) => {
        dispatch({ type: requestCreateRequestForm })

        let response = await axios.post('/api/request/exam', form)

        if (response && response.data && response.data.data)
            dispatch({ type: receiveCreateRequestForm, data: response.data.data })
        else dispatch({ type: receiveCreateRequestForm, data: null })
        return response;
    },
    approveCustomPrice: (requestId, customPriceId) => async (dispatch) => {

        let response = await axios.post(`/api/request/${requestId}/customPrice/${customPriceId}/approve`)

        if (response && response.data && response.data.data)
            dispatch({ type: receiveCreateRequestForm, data: response.data.data, requestId: requestId })

        return response;
    },
    rejectCustomPrice: (requestId, customPriceId) => async (dispatch) => {

        let response = await axios.post(`/api/request/${requestId}/customPrice/${customPriceId}/reject`)

        if (response && response.data && response.data.data)
            dispatch({ type: receiveCreateRequestForm, data: response.data.data, requestId: requestId })

        return response;
    },
    changePatientImage: (patientId, base64Image) => async(dispatch) =>{
        dispatch({type: requestChangePatientImage});
        let response = await axios.post(`/api/patient/${patientId}/image`, {base64Image:base64Image})
        if (response && response.data && response.data.data)
            dispatch({ type: receiveChangePatientImage, data: response.data.data })

        return response;
    },
    unzipExam: (examId) => async (dispatch, getState) => {
        let unZipresponse = await axios.post(`api/exam/${examId}/unzip`, {});
        return unZipresponse;
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case changeAdditionalFieldVisibilityAction:
            let oldModalityProducts = [...state.conditionUIData.modalityProducts]
            for(let mp in oldModalityProducts){
                for(let p in oldModalityProducts[mp].products){
                    for(let ad in oldModalityProducts[mp].products[p].additionalFields){
                        let additional = oldModalityProducts[mp].products[p].additionalFields[ad];
                        if(additional.id==action.fieldName)
                            additional.visibility = action.visibility;
                    }
                }
            }
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    modalityProducts: [
                        ...oldModalityProducts,
                    ]
                }
            }
        case requestPrivilagePrice:
            return {
                ...state,
                newRequest:{
                    ...state.newRequest,
                    privilagePrices:{
                        ...state.newRequest.privilagePrices,
                        [action.privilageId]: []
                    }
                }
            }
        case receivePrivilagePrice:
            return {
                ...state,
                newRequest:{
                    ...state.newRequest,
                    privilagePrices:{
                        ...state.newRequest.privilagePrices,
                        [action.privilageId]: action.data || []
                    }
                }
            }
        case requestDeleteFormType:
            return {
                ...state,
                deleteDialog: {
                    ...state.deleteDialog,
                    isLoading: true
                }
            }
        case receiveDeleteFormType:
            let deleteRowIndex = -1
            if (action.requestId)
                deleteRowIndex = state.requestResult.resultList.findIndex(x => x.requestId == action.requestId);

            return {
                ...state,
                requestResult: {
                    ...state.requestResult,
                    resultList: deleteRowIndex < 0
                        ? state.requestResult.resultList
                        : [...state.requestResult.resultList.filter((x, i) => i != deleteRowIndex)],
                },
                deleteDialog: {
                    ...state.deleteDialog,
                    isLoading: false,
                    isLoaded: true,
                }
            }
        case requestDeleteReasonType:
            return {
                ...state,
                deleteDialog: {
                    ...state.deleteDialog,
                    isLoading: true
                }
            }
        case receiveDeleteReasonType:
            return {
                ...state,
                deleteDialog: {
                    ...state.deleteDialog,
                    isLoading: false,
                    isLoaded: true,
                    reasonOptions: action.data
                }
            }
        case requestRequestType:
            return {
                ...state,
                requestResult: {
                    ...state.requestResult,
                    resultList: [],
                    isLoading: true,
                    isLoaded: false
                }
            };
        case receiveRequestType:
            return {
                ...state,
                requestResult: {
                    ...state.requestResult,
                    resultList: action.data,
                    isLoading: false,
                    isLoaded: true
                }
            };
        case requestUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: true,
                    isLoaded: false
                }
            };
        case receiveUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    isLoaded: true,
                    ...action.data
                }
            };
        case requestPatientInfoType:
            return {
                ...state,
                newRequest: {
                    ...state.newRequest,
                    isLoading: true,
                    isLoaded: false,
                    patientInfo: {}
                }
            }
        case receivePatientInfoType:
        case receiveChangePatientImage:
            return {
                ...state,
                newRequest: {
                    ...state.newRequest,
                    isLoading: false,
                    isLoaded: true,
                    patientInfo: action.data
                }
            }
        case clearNewFormType:
            return {
                ...state,
                newRequest: {
                    isLoading: false,
                    isLoaded: false,
                    patientInfo: {}
                }
            }
        case requestCreateRequestForm:
            return {
                ...state,
                newRequest: {
                    ...state.newRequest,
                    isLoading: true,
                    isLoaded: false,
                    createdRequest: []
                }
            }
        case receiveCreateRequestForm:

            let rowIndex = -1
            if (action.requestId)
                rowIndex = state.requestResult.resultList.findIndex(x => x.requestId == action.requestId);
            if (!action.data)
                return {
                    ...state,
                    newRequest: {
                        ...state.newRequest,
                        isLoading: false,
                        isLoaded: true
                    }
                };
            return {
                ...state,
                requestResult: {
                    ...state.requestResult,
                    resultList: rowIndex < 0 ? [action.data, ...state.requestResult.resultList] : [
                        ...state.requestResult.resultList.filter((x, i) => i < rowIndex),
                        action.data,
                        ...state.requestResult.resultList.filter((x, i) => i > rowIndex)],
                },
                newRequest: {
                    ...state.newRequest,
                    isLoading: false,
                    isLoaded: true,
                    createdRequest: action.data
                }
            }
        case requestGetExportLink:
        case requestCreateExportLink:
            return {
                ...state,
                linkDialog: {
                    ...state.linkDialog,
                    isLoading: true,
                    isLoaded: false,
                    data: {}
                }
            }
        case receiveCreateExportLink:
        case receiveGetExportLink:
            return {
                ...state,
                linkDialog: {
                    ...state.linkDialog,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data
                }
            }
        default:
            return state;
    }
};
