import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import Divider from "@material-ui/core/Divider";
import { Route, withRouter } from "react-router";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Paper, Container, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { actionCreators } from "../../store/Report";
import { formatDistanceToNow, formatRelative } from "date-fns";
import PDFViewer from "../PDFViewer";
import pdfjs from "../PDFViewer/pdfjs";
import swal from 'sweetalert'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
    }
}));

export function FinishedReportSelector({ report, match, changeFinishedReport, location,sendNotiToGateway  }) {
    const classes = useStyles();

    useEffect(() => {
        if (location.search.indexOf('new') > 0) {
            changeFinishedReport("new")
        }
    }, [])

    const downloadAsDICOM = (reportId) => {
        window.open(`/ReportViewer/finishedReport/${match.params.examId}/${reportId}/dicom`)
    }
    const sendNotiToGatewayFn = async (e)=>{
        e.preventDefault();
        var response = await sendNotiToGateway(match.params.examId,report.reportName)
        if(response.data && response.data.success)
        {
            swal('','Sent','success');
        }
    }
    return (
        <Grid container spacing={1} style={{ height: "100%" }}>
            <Grid item xs={1}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={x => changeFinishedReport("new")}
                            >
                                Edit
                            </Button>
                        </Grid>
                        {/*<Grid item xs={6} style={{textAlign:'right'}}>*/}
                        {/*    <Button*/}
                        {/*        variant="text"*/}
                        {/*        size="small"*/}
                        {/*        startIcon={report.pingGatewayReport.isLoading? <CircularProgress size={8} /> : null}*/}
                        {/*        onClick={sendNotiToGatewayFn}*/}
                        {/*    >*/}
                        {/*        Send*/}
                        {/*    </Button>*/}
                        {/*</Grid>*/}
                    </Grid>
                    {report.finishedReports.filter(y => y.reportName == report.reportName || (y.reportName.indexOf('PACS_Report') > 0 && report.reportName.indexOf('PACS_Report') > 0)).map((x, i) => (
                        <Grid item key={x.finishedReportId}>
                            <Paper
                                elevation={report.currentFinishedReportId == x.finishedReportId ? 3 : 0}
                                className={classes.paper}
                                onClick={e => changeFinishedReport(x.finishedReportId)}
                            >
                                <span>{i == 0 ? `Latest by ${x.reporterName}` : `${formatRelative(new Date(x.createDate), new Date())} by ${x.reporterName}`}</span>
                                {/*match.params.examId ?  <Button onClick={e=>downloadAsDICOM(x.finishedReportId)}>DICOM</Button> : null */}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={11} style={{ height: "100%" }}>
                <PDFViewer
                    backend={pdfjs}
                    src={`/ReportViewer/finishedReport/${match.params.examId || match.params.formId || match.params.billingId}/${report.currentFinishedReportId}`}
                />
            </Grid>
        </Grid>
    );
}
function mapStateToProps(state) {
    return {
        report: state.report
    };
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(
    withRouter(FinishedReportSelector)
);
