import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    ListSubheader,
    CircularProgress,
    LinearProgress
} from "@material-ui/core";
import { actionCreators } from "../../store/DicomViewer";
import { withRouter } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

class DicomViewerDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            examId: null
        };

        this.onClickThumbnail = this.onClickThumbnail.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount() {
        let examId = this.props.examId;
        if (!examId && this.props.match.params.examId) examId = this.props.match.params.examId;
        if (examId) {
            this.setState({ examId: examId });



            this.props.getSeries(examId).then((x, y) => {
                if (this.props.dicomViewer && this.props.dicomViewer.series && this.props.dicomViewer.series.length)
                    this.setState({
                        selected:
                            this.props.dicomViewer.series[0].seriesId +
                            "/" +
                            this.props.dicomViewer.series[0].imageOrientationPatient
                    });
            });
        }
    }

    onClickThumbnail(seriesId, imageOrientationPatient) {
        this.setState({ selected: seriesId + "/" + imageOrientationPatient });
    }

    onClose() {
        // this.props.history.push('/pacs');
        window.close();
    }

    render() {
        return this.state.examId == null ? null : (
            <Dialog fullScreen open={true} onClose={this.onClose}>
                {/* <AppBar style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="Close" onClick={this.onClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">Dicom Viewer</Typography>
                    </Toolbar>
                </AppBar> */}
                <LinearProgress ref={x => (this.loadingBar = x)} id="loading" />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    style={{ overflow: "hidden", height: "100%" }}
                >
                    <Grid item xs={2} spacing={1} style={{ height: "100%", overflowY: "auto", overflowX: "hidden", width:200 }}>
                        <div style={{ }}>
                            <GridList cellHeight={180} cols={1}>
                                <GridListTile key="Subheader" cols={1} style={{ height: "auto" }}>
                                    <ListSubheader component="div">Series</ListSubheader>
                                </GridListTile>
                                {this.props.dicomViewer.isLoading ? (
                                    <LinearProgress />
                                ) : !this.props.dicomViewer.isLoaded ? null : (
                                    this.props.dicomViewer.series.map(x => (
                                        <GridListTile
                                            key={x.seriesId}
                                            onClick={() => this.onClickThumbnail(x.seriesId, x.imageOrientationPatient)}
                                            style={{
                                                cursor: "pointer",
                                                border: this.state.selected === x.seriesId ? "1px solid red" : ""
                                            }}
                                        >
                                            <img
                                                src={x.thumbnail && x.thumbnail.indexOf('http') != 0 ? `data:image/png;base64, ${x.thumbnail}` : x.thumbnail}
                                                alt={x.seriesDescription}
                                            />
                                            <GridListTileBar title={(x.frameNumber ? `[${x.frameNumber}] ` : '') + x.seriesDescription} />
                                        </GridListTile>
                                    ))
                                )}
                            </GridList>
                        </div>
                    </Grid>
                    <Grid item xs={10} style={{ height: "100%" }}>
                        {this.state.selected ? (
                            <>
                                <iframe
                                    title="DicomViewer"
                                    src={`${this.props.dicomViewer.branchCacheUrl}DicomViewer/${this.state.examId}/${this.state.selected || ""}?token=${this.props.user.jwt}`}
                                    frameborder="0"
                                    border="0"
                                    cellspacing="0"
                                    style={{ borderStyle: "none", width: "100%", height: "100%" }}
                                />
                            </>
                        ) : (
                                <CircularProgress />
                            )}
                        {/* <a href="weasis://%24dicom%3Aget%20-w%20https%3A%2F%2Fnroduit.github.io%2Fsamples%2FLumbar%2Fmf.xml" style={{
                            height: 50,
                            width: 50,
                            display: 'block',
                            position: 'absolute',
                            background: 'red',
                            right: 10,
                            bottom: 50
                        }}>+</a> */}
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        dicomViewer: state.dicomViewer,
        user: state.user
    };
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(
    withRouter(DicomViewerDialog)
);
