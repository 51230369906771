import axios from "axios";
import swal from 'sweetalert'
import { endOfDay } from "date-fns";

const requestUIDataType = 'REQUEST_UIDATA';
const receiveUIDataType = 'RECIEVE_UIDATA';
const requestSearchType = 'REQUEST_SEARCH';
const receiveSearchType = 'RECIEVE_SEARCH';
const requestGetExamType = 'REQUEST_GETEXAM';
const receiveGetExamType = 'RECIEVE_GETEXAM';
const requestAssignableDoctorType = 'REQUEST_ASSIGNABLE_DOCTOR';
const receiveAssignableDoctorType = 'RECIEVE_ASSIGNABLE_DOCTOR';
const requestDutyDoctorType = 'REQUEST_DUTY_DOCTOR';
const receiveDutyDoctorType = 'RECIEVE_DUTY_DOCTOR';
const documentUploaded = "DOCUMENT_UPLOADED";
const updateExam = "UPDATE_EXAM";
const requestAssignType = 'REQUEST_ASSIGN'
const receiveAssignType = 'RECIEVE_ASSIGN'
const requestDocumentsType = 'REQUEST_DOCUMENTS'
const receiveDocumentsType = 'RECIEVE_DOCUMENTS'
const requestGetRelateExam = 'RECORDS_REQUEST_RELATE_EXAM'
const recieveGetRelateExam = 'RECORDS_RECIEVE_RELATE_EXAM'
const receiveDeleteExamType = 'RECIEVE_EXAM_DELETE'
const recieveCdCheckType = 'RECIEVE_CD_CHECK'
const recieveCheckDownloadType = 'RECIEVE_CD_DOWNLOAD_CHECK'
const requestDownloadType = 'REQUEST_CD_DOWNLOAD'
const recieveDownloadType = 'RECIEVE_CD_DOWNLOAD'
const clearCdDialogType = 'CLEAR_CD_DOWNLOAD'
const recieveRadiantCheckType = 'RECIEVE_RADIANT_CHECK'
const recieveCheckRadiantDownloadType = 'RECIEVE_RADIANT_DOWNLOAD_CHECK'
const requestRadiantDownloadType = 'REQUEST_RADIANT_DOWNLOAD'
const recieveRadiantDownloadType = 'RECIEVE_RADIANT_DOWNLOAD'
const clearRadiantDialogType = 'CLEAR_RADIANT_DOWNLOAD'

const initialState = {
    recordResult: {
        isLoading: false,
        isLoaded: false,
        records: []
    },
    conditionUIData: {
        isLoading: false,
        modality: []
    },
    dutyDoctor: {
        isLoading: false,
        data: {}
    },
    assignableDoctor: {
        isLoading: false,
        doctors: []
    },
    documentDialog: {
        documents: [],
        isLoading: false
    },
    historyDialog: {
        isLoading: false,
        data: []
    },
    cdDialog: {
        isLoading: false,
        enable: false,
        data: {}
    },
    radiantDialog: {
        isLoading: false,
        enable: false,
        data: {}
    },
    condition: {}
};
axios.interceptors.response.use(async (response) => {

    if (response.data && response.data.success == false) {
        swal("", response.data.message || "Something went wrong!", "error");
    }

    return response

});
export const actionCreators = {
    clearCdDialog: () => async (dispatch, getState) => {
        dispatch({ type: clearCdDialogType })
    },
    startCDDownload: (examId) => async (dispatch, getState) => {
        dispatch({ type: requestDownloadType })
        let startResponse = await axios.post(`https://idcard.ipacsthai.com:18242/api/download?examId=${examId}&token=${getState().user.jwt}`)
        if (startResponse && startResponse.data)
            dispatch({ type: recieveDownloadType, data: startResponse.data })
        return startResponse;
    },
    checkCDDownload: (examId) => async (dispatch, getState) => {
        let checkResponse = await axios.get(`https://idcard.ipacsthai.com:18242/api/download/check?examId=${examId}&token=${getState().user.jwt}`)
        if (checkResponse && checkResponse.data)
            dispatch({ type: recieveCheckDownloadType, data: checkResponse.data })
        return checkResponse;
    },
    checkCDDownloader: () => async (dispatch, getState) => {
        // let statePatientUI = getState().patient.patientUI;
        // if (statePatientUI?.data?.idCardReaderUrl) {
        let cdCheckResponse = await axios.get('https://idcard.ipacsthai.com:18242/api/check')
        if (cdCheckResponse && cdCheckResponse.data)
            dispatch({ type: recieveCdCheckType, data: cdCheckResponse.data.success })
        return cdCheckResponse;
        // }
    },
    clearRadiant: () => async (dispatch, getState) => {
        dispatch({ type: clearRadiantDialogType })
    },
    startRadiantDownload: (examId) => async (dispatch, getState) => {
        dispatch({ type: requestRadiantDownloadType })
        let startResponse = await axios.post(`https://idcard.ipacsthai.com:17459/api/download?examId=${examId}&token=${getState().user.jwt}`)
        if (startResponse && startResponse.data)
            dispatch({ type: recieveRadiantDownloadType, data: startResponse.data })
        return startResponse;
    },
    checkRadiantProgress: (examId) => async (dispatch, getState) => {
        let checkResponse = await axios.get(`https://idcard.ipacsthai.com:17459/api/download/check?examId=${examId}&token=${getState().user.jwt}`)
        if (checkResponse && checkResponse.data)
            dispatch({ type: recieveCheckRadiantDownloadType, data: checkResponse.data })
        return checkResponse;
    },
    checkRadiantInstall: () => async (dispatch, getState) => {
        // let statePatientUI = getState().patient.patientUI;
        // if (statePatientUI?.data?.idCardReaderUrl) {
        let cdCheckResponse = await axios.get('https://idcard.ipacsthai.com:17459/api/check')
        if (cdCheckResponse && cdCheckResponse.data)
            dispatch({ type: recieveRadiantCheckType, data: cdCheckResponse.data.success })
        return cdCheckResponse;
        // }
    },
    approveDicom: (examId, message) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/dicom/approve`, { message });

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    rejectDicom: (examId, message) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/dicom/reject`, { message });

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    approveReport: (examId, message) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/report/approve`, { message });

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    rejectReport: (examId, message) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/report/reject`, { message });

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    fetchSearchConditionUIData: () => async (dispatch, getstate) => {
        dispatch({ type: requestUIDataType })

        let response = await axios.get('api/uidata/recordCondition')

        dispatch({ type: receiveUIDataType, modality: response.data.modality, hospital: response.data.hospital })
    },
    getAssignableDoctor: (modalityCode) => async (dispatch) => {
        dispatch({ type: requestAssignableDoctorType });

        let response = await axios.get(`api/uidata/assignabledoctor/${modalityCode}`);

        dispatch({
            type: receiveAssignableDoctorType,
            doctors: response.data
        });
    },
    getBranchDutyDoctor: (branchId) => async (dispatch) => {
        dispatch({ type: requestDutyDoctorType });

        let response = await axios.get(`api/uidata/branch/${branchId}/dutyDoctor/`);

        dispatch({
            type: receiveDutyDoctorType,
            data: response.data
        });
    },
    searchRecoards: (page, condition) => async (dispatch, getState) => {

        if (!condition)
            condition = getState().records.condition;
        if (!page)
            page = 1;
        dispatch({ type: requestSearchType, condition });
        let response = await axios.post('api/exam/search?page=' + page, {
            page,
            examFromDate: condition.examFromDate,
            examToDate: endOfDay(condition.examToDate),
            accessionNumber: condition.accessionNumber,
            hospitalNumber: condition.hospitalNumber,
            patientName: condition.patientName,
            modalityCode: condition.modalityCode,
            patientId: condition.patientId,
            issuerOfPatientId: condition.issuerOfPatientId,
            referringPhysicianId: condition.referringPhysicianId,
            studyDateFrom: condition.studyDateFrom,
            studyDateTo: condition.studyDateTo,
            studyTimeFrom: condition.studyTimeFrom,
            studyTimeTo: condition.studyTimeTo,
            institutionalDepartment: condition.institutionalDepartment,
            sendingApplication: condition.sendingApplication
        });

        dispatch({
            type: receiveSearchType,
            records: response.data
        });
    },
    getExam: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.get(`api/exam/${examId}`);

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    acceptExam: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/accept`, {});

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    rejectExam: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/reject`, {});

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    deleteExam: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/delete`, {});

        dispatch({
            type: receiveDeleteExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    deleteDicom: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let response = await axios.post(`api/exam/${examId}/dicom/delete`, {});

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    },
    uploadDocument: (examId, fileName, file, type = 'document') => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;

        // Get index of exam in records.
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        var formData = new FormData();
        formData.append("file", file)
        let response = await axios.post(`api/exam/${examId}/${type}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        if (response.data.success && examIndex < records.length) {
            dispatch({
                type: documentUploaded,
                examIndex,
                fileType: type
            });
        }

        return response;
    },
    updateExam: (examId, { firstName, middleName, lastName, prefixName, suffixName, birthDate }) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;

        // Get index of exam in records.
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        let response = await axios.post(`api/exam/${examId}/update`, {
            firstName,
            middleName,
            lastName,
            prefixName,
            suffixName,
            birthDate
        });

        if (response.data.success && response.data.data) {
            dispatch({
                type: updateExam,
                exam: response.data.data,
                examIndex
            });
        }
    },
    assignCase: (examId, doctorId, withInDay, withInHour, isSpecific, isOnDuty, isChainCase, message) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;
        dispatch({ type: requestAssignType });

        let response = await axios.post(`api/exam/${examId}/assign`, { doctorId, withInDay, withInHour, isSpecific,isOnDuty,isChainCase,message });

        if (response.data)
            dispatch({
                type: updateExam,
                exam: response.data,
                examIndex: examIndex
            });
        return response;
    },
    getDocuments: (examId, type = 'document') => async (dispatch) => {
        dispatch({ type: requestDocumentsType });

        let response = await axios.get(`api/exam/${examId}/${type}`);

        dispatch({
            type: receiveDocumentsType,
            result: response.data,
            examId: examId
        });
        return response;
    },
    checkExportExam: (examId) => async () => {
        let response = await axios.get(`dicom/export/${examId}/check`);
        return response
    },
    getRelateExam: (examId) => async (dispatch) => {
        dispatch({ type: requestGetRelateExam });

        let response = await axios.get(`api/exam/${examId}/relate`);

        dispatch({
            type: recieveGetRelateExam,
            data: response.data,
            examId: examId
        });
        return response;
    },
    unzipExam: (examId) => async (dispatch, getState) => {
        const records = getState().records.recordResult.records;
        let examIndex = 0;
        while (examIndex < records.length && records[examIndex].examId !== examId) examIndex++;

        dispatch({ type: requestGetExamType });

        let unZipresponse = await axios.post(`api/exam/${examId}/unzip`, {});

        let response = await axios.get(`api/exam/${examId}`);

        dispatch({
            type: receiveGetExamType,
            exam: response.data,
            examIndex
        });
        return response;
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case clearCdDialogType: {
            return {
                ...state,
                cdDialog: {
                    ...state.cdDialog,
                    isLoading: false,
                    data: {}
                }
            }
        }
        case recieveCdCheckType: {
            return {
                ...state,
                cdDialog: {
                    ...state.cdDialog,
                    enable: action.data
                }
            }
        }
        case recieveCheckDownloadType:
            return {
                ...state,
                cdDialog: {
                    ...state.cdDialog,
                    data: action.data
                }
            }
        case requestDownloadType:
            return {
                ...state,
                cdDialog: {
                    ...state.cdDialog,
                    isLoading: true,
                    data: {}
                }
            }
        case recieveDownloadType:
            return {
                ...state,
                cdDialog: {
                    ...state.cdDialog,
                    isLoading: false,
                    data: action.data
                }
            }
        case clearRadiantDialogType: {
            return {
                ...state,
                radiantDialog: {
                    ...state.radiantDialog,
                    isLoading: false,
                    data: {}
                }
            }
        }
        case recieveRadiantCheckType: {
            return {
                ...state,
                radiantDialog: {
                    ...state.radiantDialog,
                    enable: action.data
                }
            }
        }
        case recieveCheckRadiantDownloadType:
            return {
                ...state,
                radiantDialog: {
                    ...state.radiantDialog,
                    data: action.data
                }
            }
        case requestRadiantDownloadType:
            return {
                ...state,
                radiantDialog: {
                    ...state.radiantDialog,
                    isLoading: true,
                    data: {}
                }
            }
        case recieveRadiantDownloadType:
            return {
                ...state,
                radiantDialog: {
                    ...state.radiantDialog,
                    isLoading: false,
                    data: action.data
                }
            }
        case requestGetRelateExam:
            return {
                ...state,
                historyDialog: {
                    ...state.historyDialog,
                    isLoading: true
                }
            };
        case recieveGetRelateExam:
            return {
                ...state,
                historyDialog: {
                    ...state.historyDialog,
                    isLoading: false,
                    data: action.data
                }
            };
        case requestUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: true
                }
            };
        case receiveUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    modality: action.modality
                }
            };
        case requestSearchType:
            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    isLoading: true,
                    isLoaded: false
                },
                condition: action.condition
            };
        case receiveSearchType:
            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    isLoading: false,
                    isLoaded: true,
                    records: action.records
                }
            };
        case documentUploaded: {
            let records = state.recordResult.records.slice();
            switch (action.fileType) {
                case 'document':
                    records[action.examIndex].hasDocument = true;
                    break;
                case 'file':
                    records[action.examIndex].hasFile = true;
                    break;
            }

            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    records
                }
            };
        }
        case receiveGetExamType:
        case updateExam: {
            let records = state.recordResult.records.slice();
            records[action.examIndex] = action.exam;

            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    records
                }
            };
        }
        case receiveDeleteExamType:
            // let records = state.recordResult.records.slice();
            // records[action.examIndex] = action.exam;

            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    records: state.recordResult.records.filter((x, i) => i != action.examIndex)
                }
            };
        case requestDutyDoctorType:
            return {
                ...state,
                dutyDoctor: {
                    ...state.dutyDoctor,
                    isLoading: true
                }
            };
        case receiveDutyDoctorType:
            return {
                ...state,
                dutyDoctor: {
                    ...state.dutyDoctor,
                    isLoading: false,
                    data: action.data
                }
            };
        case requestAssignableDoctorType:
            return {
                ...state,
                assignableDoctor: {
                    ...state.assignableDoctor,
                    isLoading: true
                }
            };
        case receiveAssignableDoctorType:
            return {
                ...state,
                assignableDoctor: {
                    ...state.assignableDoctor,
                    isLoading: false,
                    doctors: action.doctors
                }
            };
        case receiveGetExamType:
            return {

            }
        case receiveAssignType:
            let examIndex = state.recordResult.records.findIndex(x => x.examId === action.examId);
            if (examIndex < 0)
                return state;

            var exam = state.recordResult.records[examIndex];
            let doctor = state.assignableDoctor.doctors.find(x => x.doctorId === action.doctorId);
            exam.reviewerId = doctor ? doctor.doctorId : null;
            exam.reviewerName = doctor ? doctor.doctorName : null;
            return {
                ...state,
                recordResult: {
                    ...state.recordResult,
                    records: [...state.recordResult.records.slice(0, examIndex), exam, ...state.recordResult.records.slice(examIndex + 1, 9999999999)]
                }
            }
        case requestDocumentsType:
            return {
                ...state,
                documentDialog: {
                    ...state.documentDialog,
                    documents: [],
                    isLoading: true
                }
            };
        case receiveDocumentsType:
            return {
                ...state,
                documentDialog: {
                    ...state.documentDialog,
                    documents: action.result,
                    isLoading: false
                }
            };
        default:
            return state;
    }
};
