import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from "../../store/Request";

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from "@material-ui/core/TextField";
import { withRouter } from 'react-router'
import { format } from 'date-fns'
import { Check } from '@material-ui/icons'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';
import SmsIcon from '@material-ui/icons/Sms';
import MailIcon from '@material-ui/icons/Mail';
import EditIcon from '@material-ui/icons/Edit';
import Icon from '@material-ui/core/Icon';
import WarningIcon from '@material-ui/icons/Warning';
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhotoIcon from "@material-ui/icons/Photo";
import UploadDocumentDialog from "../Records/UploadDocumentDialog";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import swal from 'sweetalert'

export function RequestSearchResult({ request, history, createLink, createInvoice, user, checkExportExam,approveCustomPrice,rejectCustomPrice,unzipExam }) {
    const gotoPacs = (accessionNumber) => {
        history.push('/pacs#' + accessionNumber)
    }
    function findParent(el, tag) {
        let result = el;
        while (result.tagName != tag) {
            if (result.parentElement == null)
                break;
            result = result.parentElement;
        }
        return result;
    }
    const showLoading = (event) => {
        let btn = findParent(event.target, 'TD');
        btn.firstChild.style.display = 'none';
        btn.lastChild.style.display = 'inline-block'
    }
    const hideLoading = (event) => {
        let btn = findParent(event.target, 'TD');
        btn.lastChild.style.display = 'none';
        btn.firstChild.style.display = 'inline-block'
    }
    const onClickInvoice = async (event, requestId) => {
        event.persist()
        showLoading(event);
        await createInvoice(requestId);
        hideLoading(event);
    }

    const onClickExport = async (event, requestId) => {
        event.persist()
        showLoading(event);
        await createLink(requestId);
        hideLoading(event);
        setIsOpenExportDialog(true);
    }

    const downloadDicom = (e, x) => {
        showLoading(e);
        e.persist()
        let busy = false;
        let checkInterval = setInterval(async () => {
            if (busy)
                return;
            busy = true;
            let response = await checkExportExam(x.examId);
            if (response.data.success && response.data.data) {
                clearInterval(checkInterval);
                window.open('/dicom/export/' + x.examId, '_download', 'width=400,height=200')
                // setTimeout(() => hideLoading(e), 1000);
                hideLoading(e)
            } else if (!response.data.success) {
                clearInterval(checkInterval);
                hideLoading(e);
            }
            busy = false;
        }, 1000)
    }

    const handleApproveCustomPrice = (x)=> {
        swal({
            title: "Confirm to Approve custom price",
            icon: "success",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    approveCustomPrice(x.requestId,x.customPriceId)
                } else {

                }
            });
    }

    const handleRejectCustomPrice = (x)=> {
        swal({
            title: "Reject custom price?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (yes) => {
                if (yes) {
                    rejectCustomPrice(x.requestId,x.customPriceId)
                } else {

                }
            });
    }


    let [isOpenExportDialog, setIsOpenExportDialog] = useState(false)
    let [isOpenDdocumentDialog, setIsOpenDdocumentDialog] = useState(false)
    let [examDocumentId, setExamDocumentId] = useState()
    return (
        <Container>
            <iframe name="_download" id="_download" style={{ display: 'none' }} />
            <Dialog id="section-to-print" fullWidth onClose={() => setIsOpenExportDialog(false)} aria-labelledby="simple-dialog-title" open={isOpenExportDialog}>
                <DialogTitle id="simple-dialog-title">Export Film</DialogTitle>
                {request.linkDialog.isLoading
                    ? <CircularProgress />
                    : request.linkDialog.isLoaded && <Grid container style={{ padding: 10 }}>
                        <Grid item container xs={8}>
                            <Grid item xs={12}>
                                <TextField fullWidth disabled margin="normal" label="Name" value={request.linkDialog.data.patientName} InputLabelProps={{ readOnly: true, shrink: true }}></TextField>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                ดูฟิล์มได้ที่ QR Code หรือลิงค์ด้านล่างนี้
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <span>{`${document.location.protocol}//${document.location.host}/link/${request.linkDialog.data.linkId}`}</span>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} style={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <img src={request.linkDialog.data.qrcode} />
                            </Grid>
                            <Grid item xs={12}>
                                <a href="javascript:print();"><PrintIcon /></a>
                                <a href={`mailto:?subject=Link&body=${request.linkDialog.data.linkHostUrl + 'link/' + request.linkDialog.data.linkId}`}>
                                    <MailIcon />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Dialog>

            <UploadDocumentDialog
                open={isOpenDdocumentDialog}
                examId={examDocumentId}
                handleClose={() => setIsOpenDdocumentDialog(false)}
                allowUpload={user.permission['EXAM_DOCUMENT_WRITE']}
            />

            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                {request.requestResult.isLoading
                    ? <CircularProgress />
                    : !request.requestResult.isLoaded
                        ? null
                        : request.requestResult.resultList.length === 0
                            ? <div>No result.</div>
                            : <TableContainer component={Paper}>
                                <Table size="small" style={{ minWidth: 800 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{ width: 165 }}>Date</TableCell>
                                            {user.roleName === 'Hospital' ? <TableCell align="center">HN</TableCell> : null}
                                            <TableCell>Patiet Name</TableCell>
                                            {/* <TableCell align="center">Modality</TableCell> */}
                                            <TableCell align="center">
                                                <Table size="small" style={{ minWidth: 665 }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            {user.permission['EXAM_DICOM'] && <TableCell style={{ width: 200 }}>Accession</TableCell>}
                                                            {user.permission['EXAM_ASSIGN'] && <TableCell align="center" style={{ width: 80 }}>Assign</TableCell>}
                                                            {user.permission['EXAM_DOCUMENT'] && <TableCell align="center" style={{ width: 80 }}>Request</TableCell>}
                                                            {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 75 }}></TableCell>}
                                                            {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 75 }}>DICOM</TableCell>}
                                                            {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 75 }}></TableCell>}
                                                            {user.permission['EXAM_REPORT'] && <TableCell align="center" style={{ width: 80 }}>Report</TableCell>}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>

                                            {user.permission['REQUEST_DOCUMENT'] && <TableCell align="center">Document</TableCell>}
                                            {user.permission['REQUEST_EDIT'] && <TableCell align="center">Edit</TableCell>}
                                            {user.permission['REQUEST_INVOICE'] && <TableCell align="center">Invoice</TableCell>}
                                            {user.permission['REQUEST_INVOICE'] && <TableCell align="center">Sticker</TableCell>}
                                            {user.permission['REQUEST_EXPORT'] && <TableCell align="center">Export</TableCell>}
                                            {user.roleName === 'Administrator'
                                                ? <TableCell align="center">-</TableCell>
                                                : null
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {request.requestResult.resultList.map(row => (
                                            <TableRow key={row.requestId} hover
                                                style={{
                                                    opacity: row.status === 'Hold' ? 0.5 : 1,
                                                    borderLeft: row.status === 'Hold' ? 'orange solid 3px' : '',
                                                    position: 'relative'
                                                }}
                                                title={row.status === 'Hold' ? row.statusRemark : ''}
                                            >
                                                <TableCell>
                                                    {format(new Date(row.appointmentDate), 'dd/MM/yyyy HH:mm')}
                                                </TableCell>
                                                {user.roleName === 'Hospital' ? <TableCell>{row.hn}</TableCell> : null}
                                                <TableCell>{row.patientName}</TableCell>
                                                {/* <TableCell>{row.exams.map(x => x.modalityCode).join(', ')}</TableCell> */}
                                                <TableCell>
                                                    <Table size="small" style={{ minWidth: 665 }}>
                                                        <TableBody>
                                                            {row.exams.map(x =>
                                                                <TableRow key={x.examId}>
                                                                    {user.permission['EXAM_DICOM'] &&
                                                                        <TableCell style={{ width: 200 }}>
                                                                            <Chip
                                                                                avatar={<Avatar>{x.modalityCode}</Avatar>}
                                                                                label={x.accessionNumber}
                                                                                onClick={e => user.permission['MENU_PACS'] && gotoPacs(x.accessionNumber)}
                                                                                variant="outlined"
                                                                            />
                                                                        </TableCell>
                                                                    }
                                                                    {user.permission['EXAM_ASSIGN'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 8 ? <Check /> : null}</TableCell>}
                                                                    {user.permission['EXAM_DOCUMENT'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 1 ? <Check style={{ cursor: 'pointer' }} onClick={() => { setExamDocumentId(x.examId); setIsOpenDdocumentDialog(true); }} /> : null}</TableCell>}
                                                                    {user.permission['EXAM_DICOM'] &&
                                                                        <TableCell align="center" style={{ width: 75 }}>{x.status & 4
                                                                            ? <PhotoIcon style={{ cursor: 'pointer' }} onClick={async (event) => { 
                                                                                if (x.zipStatus == 'Archive') {
                                                                                    function findParent(el, tag) {
                                                                                        let result = el;
                                                                                        while (result.tagName != tag) {
                                                                                            if (result.parentElement == null)
                                                                                                break;
                                                                                            result = result.parentElement;
                                                                                        }
                                                                                        return result;
                                                                                    }
                                                                                    function showLoading(event) {
                                                                                        let btn = findParent(event.target, 'TD');
                                                                                        btn.firstChild.style.display = 'none';
                                                                                        btn.lastChild.style.display = 'inline-block'
                                                                                    }
                                                                                    function hideLoading(event) {
                                                                                        let btn = findParent(event.target, 'TD');
                                                                                        btn.lastChild.style.display = 'none';
                                                                                        btn.firstChild.style.display = 'inline-block'
                                                                                    }
                                                                                    event.persist();
                                                                                    showLoading(event);
                                                                                    await unzipExam(x.examId);
                                                                                    hideLoading(event);
                                                                                } 
                                                                                window.open('https://dicom-viewer.azurewebsites.net/?manifest=' + encodeURIComponent(document.location.protocol + "//" + document.location.host + "/weasis/" + x.examId + `?token=${user.jwt}`)); 
                                                                            }} />
                                                                            : null}
                                                                            <CircularProgress style={{ display: 'none' }} size={18} />
                                                                        </TableCell>}
                                                                    {user.permission['EXAM_DICOM'] &&
                                                                        <TableCell padding="none" style={{ cursor: "pointer", textAlign: 'center', width: 75 }} onClick={(e) => (x.status & 4) && downloadDicom(e, x)} align="center">
                                                                            {x.status & 4
                                                                                ? <i style={{ display: 'inline-block' }} className="gg-software-download"></i>
                                                                                : null
                                                                            }
                                                                            <CircularProgress style={{ display: 'none' }} size={18} />
                                                                        </TableCell>}
                                                                    {user.permission['EXAM_DICOM'] &&
                                                                        <TableCell align="center" style={{ width: 75 }}>{x.status & 4
                                                                            ? <a
                                                                                href={
                                                                                    "weasis://%24dicom%3Aget%20-w%20" +
                                                                                    encodeURIComponent(
                                                                                        document.location.protocol +
                                                                                        "//" +
                                                                                        document.location.host +
                                                                                        "/weasis/" +
                                                                                        x.examId + `?token=${user.jwt}`
                                                                                    )
                                                                                }
                                                                            >
                                                                                <VisibilityIcon />
                                                                            </a>
                                                                            : null}
                                                                        </TableCell>}
                                                                    {user.permission['EXAM_REPORT'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 16 ? <Check style={{ cursor: 'pointer' }} onClick={() => { window.open(`/report/${x.examId}?examBranchId=${x.branchId}`); }} /> : null}</TableCell>}

                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                {user.permission['REQUEST_DOCUMENT'] &&
                                                    <TableCell
                                                        align="center"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={_ => history.push(`/request/upload/${row.requestId}`)}
                                                    >
                                                        {row.missingDocumentNames.length
                                                            ? <Tooltip placement="top" title={<div style={{ fontSize: 15 }}><b>ต้องการเอกสารเพิ่มเติม</b>{row.missingDocumentNames.map(x => <><br />- {x}</>)}</div>}>
                                                                <WarningIcon />
                                                            </Tooltip>
                                                            : <Check />
                                                        }
                                                    </TableCell>
                                                }
                                                {user.permission['REQUEST_EDIT'] &&
                                                    <TableCell align="center">
                                                        <EditIcon
                                                            style={{ cursor: "pointer" }}
                                                            onClick={_ => history.push(`/request/edit/${row.requestId}`)}
                                                        />
                                                    </TableCell>
                                                }
                                                {user.permission['REQUEST_INVOICE'] &&
                                                    <TableCell align="center"
                                                        // onClick={(e) => onClickInvoice(e, row.requestId)}
                                                        onClick={_ => row.allowToClickInvoice && row.status != 'Hold'
                                                            && window.open(`/accounting_report/${row.requestId}`)}
                                                    >
                                                        <Icon
                                                            color={row.allowToClickInvoice && row.status != 'Hold' ? '' : 'disabled'}
                                                            title={!row.allowToClickInvoice ? 'กรุณาระบุเวลาตรวจ' : row.status == 'Hold' ? row.statusRemark : ''}
                                                            style={{ cursor: "pointer", color: row.isCreatedAccountDocument ? 'rgb(255, 193, 7)' : '' }}
                                                        >receipt</Icon>
                                                        <CircularProgress style={{ display: 'none' }} size={18} />
                                                    </TableCell>
                                                }
                                                 {user.permission['REQUEST_INVOICE'] &&
                                                    <TableCell align="center"
                                                        // onClick={(e) => onClickInvoice(e, row.requestId)}
                                                        onClick={_ => row.status != 'Hold' && window.open(`/reportviewer/${row.requestId}/sticker`)}
                                                    >
                                                        <Icon
                                                            
                                                            title={row.status == 'Hold' ? row.statusRemark : ''}
                                                            style={{ cursor: "pointer" }}
                                                        >label</Icon>
                                                        <CircularProgress style={{ display: 'none' }} size={18} />
                                                    </TableCell>
                                                }
                                                {user.permission['REQUEST_EXPORT'] &&
                                                    <TableCell align="center" onClick={(e) => onClickExport(e, row.requestId)}>
                                                        <i style={{ cursor: "pointer", display: 'inline-block' }} className="gg-export"></i>
                                                        <CircularProgress style={{ display: 'none' }} size={18} />
                                                    </TableCell>
                                                }
                                                {user.roleName === 'Administrator'
                                                    ?  <TableCell align="center">
                                                        {row.status === 'Hold' && row.statusRemark ? <>
                                                            <CheckIcon style={{ cursor: "pointer", color: '#009624' }} onClick={() => handleApproveCustomPrice(row)} />
                                                            <ClearIcon style={{ cursor: "pointer", color: '#f50057' }} onClick={() => handleRejectCustomPrice(row)} />
                                                        </>
                                                        :null
                                                        }
                                                    </TableCell>
                                                    : null
                                                }
                                                <TableCell style={{
                                                    position: 'absolute',
                                                    border: 0,
                                                    left: 0,
                                                    right: 0,
                                                    textAlign: 'center',
                                                    lineHeight: '45px',
                                                    zIndex: -1
                                                }}>
                                                    {row.status === 'Hold' ? row.statusRemark : ''}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                }
            </Grid>
        </Container>
    );
}
function mapStateToProps(state) {
    return {
        request: state.request,
        user: state.user
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(RequestSearchResult));