import React from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import LinearProgress from '@material-ui/core/LinearProgress';

import { actionCreators } from "../../store/Document";

export function Document() {
    return (
        <>
            <LinearProgress/>
            <div>Document</div>
        </>
    );
}
function mapStateToProps(state) {
    return {

    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(Document);