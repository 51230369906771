import React, { useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Patient";
import thLocal from "date-fns/locale/th";
import { debounce } from 'lodash';
import { withRouter } from "react-router";
import { format } from 'date-fns'
import {
    Container,
    AppBar,
    Tabs,
    Tab,
    Paper,
    Typography,
    Box,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Checkbox,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Card,
    CardMedia,
    CardActions,
    ListSubheader,
    Tooltip,
    Icon
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import VisibilityIcon from "@material-ui/icons/Visibility";
import Check from "@material-ui/icons/Check";
import PhotoIcon from "@material-ui/icons/Photo";
import EditIcon from "@material-ui/icons/Edit";
import WarningIcon from "@material-ui/icons/Warning";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import PatientAddressInput from './PatientAddressInput'
import ImportByHNDialog from './ImportByHNDialog'
import QRDialog from './QRDialog'
import swal from "sweetalert";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return <Box {...other}>{value === index && children}</Box>;
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            patient: null,
            romanTimeout: null,
            qrDialog: {}
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.setPatientChange = this.setPatientChange.bind(this);
        this.handlePatientChange = this.handlePatientChange.bind(this);
        this.handlePatientChangeCustom = this.handlePatientChangeCustom.bind(this);
        this.handlePatientChangeCheckbox = this.handlePatientChangeCheckbox.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handlePatientContactChange = this.handlePatientContactChange.bind(this);

        this.handleImportByHNDialog = this.handleImportByHNDialog.bind(this);

        this.lazyUpdateRomanize = this.lazyUpdateRomanize.bind(this);

        this.uploadImageHandler = this.uploadImageHandler.bind(this);

        this.handleClickPhoneQR = this.handleClickPhoneQR.bind(this);

        this.handleClickUseImageFromCard = this.handleClickUseImageFromCard.bind(this);

        this.closeQRDialog = this.closeQRDialog.bind(this);

        this.changeImage = this.changeImage.bind(this);

        this.handlePatientPrefixChange = this.handlePatientPrefixChange.bind(this);

        if (!ValidatorForm.hasValidationRule('requiredAge')) {
            ValidatorForm.addValidationRule('requiredAge', (value) => {
                if (!this.state.patient.age && !this.state.patient.birthDate) {
                    return false;
                }
                return true;
            });
        }
    }

    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('requiredAge')) {
            ValidatorForm.removeValidationRule('requiredAge');
        }
    }

    async componentDidMount() {
        this.props.getFormUIData();
        let response = await this.props.getPatientUIData();
        if (response?.data) {
            this.props.checkCardReader();
        }
        
        if (this.props.match.params.patientId === 'importbycard') {
            if (this.props.patient.idCardInfo.isLoaded && this.props.patient.idCardInfo.data) {
                this.setState({ patient: this.props.patient.idCardInfo.data });
                this.props.history.replace('/patient/new')
            }
            else {
                await this.props.getPatientUIData()
                let cardInfoResponse = await this.props.getCardInfo();
                if (cardInfoResponse && cardInfoResponse.data.success) {
                    this.setState({ patient: cardInfoResponse.data.data });
                    this.props.history.replace('/patient/new')
                }
                else
                    this.props.history.replace('/patient')
            }


        }
        else
            this.props.getPatientInfo(this.props.match.params.patientId).then(response => {
                let initPatient = response.data.data;
                if (this.props.match.params.patientId === 'new' && this.props.patient.condition) {
                    initPatient = {
                        ...initPatient,
                        hn: this.props.patient.condition?.hn,
                        nationalNumber: this.props.patient.condition?.nationalNumber,
                        phoneNumber: this.props.patient.condition?.phoneNumber,
                        nameTH: {
                            ...initPatient.nameTH,
                            first: this.props.patient.condition?.name
                        }
                    }
                }
                this.setState({ patient: initPatient });
            });
    }

    handleTabChange(e, newTab) {
        this.setState({
            tab: newTab
        });
        if (newTab == 5) {
            this.props.getPatientExams(this.props.match.params.patientId)
            //get exam by patient
        }
    }

    setPatientChange(name, value, callback) {
        if (!name.includes(".")) {
            this.setState({
                patient: { ...this.state.patient, [name]: value }
            }, callback);
        } else {
            let fields = name.split(".");

            let newPatient = { ...this.state.patient };
            let currentNode = newPatient;
            let i = 0;
            for (; i < fields.length - 1; i++)
                currentNode = currentNode[fields[i]] = currentNode[fields[i]] || {};
            currentNode[fields[i]] = value;
            this.setState({ patient: { ...newPatient } }, callback);
        }
    }

    async lazyUpdateRomanize(field, thaiText) {
        let romanResponse = await this.props.getPatientRomanName(thaiText)
        if (romanResponse.data.success && romanResponse.data.data) {
            switch (field) {
                case 'nameTH.first':
                    this.setPatientChange('nameEN.first', romanResponse.data.data)
                    break;
                case 'nameTH.last':
                    this.setPatientChange('nameEN.last', romanResponse.data.data)
                    break;
            }
        }
    }

    handlePatientPrefixChange(e, re) {
        let titleItem = this.props.patientForm.uiData.data.namePrefixesAll.find(x => x.id == re.props.accessKey)
        if (!titleItem)
            return;
        let opFields = { "nameTH.prefix": "nameEN.prefix", "nameEN.prefix": "nameTH.prefix" }
        let valueFields = { "nameTH.prefix": "th", "nameEN.prefix": "en" }
        let { value, name } = e.target;
        let currentValue = titleItem[valueFields[name]]
        let opValue = titleItem[valueFields[opFields[name]]]

        this.setPatientChange(name, currentValue, () => {
            this.setPatientChange(opFields[name], opValue, () => {
                if (titleItem.gender) {
                    this.setPatientChange('gender', titleItem.gender)
                }
            });
        });
    }

    handlePatientChange(e) {
        let fieldName = e.target.name;
        let fieldValue = e.target.value;

        switch (fieldName) {
            case 'nameTH.first':
            case 'nameTH.last':
                this.setPatientChange(fieldName, fieldValue);
                if (this.state.romanTimeout) {
                    clearTimeout(this.state.romanTimeout);
                }
                if (fieldValue)
                    this.setState({
                        romanTimeout: setTimeout(() => {
                            this.lazyUpdateRomanize(fieldName, fieldValue);
                        }, 1000)
                    });
                break;
            default:
                this.setPatientChange(fieldName, fieldValue)
        }
    }

    handlePatientContactChange(e) {
        let [relationship, fieldName] = e.target.name.split(".", 2);
        let value = e.target.value;
        let newContacts = [...(this.state.patient.contacts || [])];
        let contact = newContacts.find(x => x.relationship === relationship);
        if (!contact) {
            contact = { [fieldName]: value, relationship: relationship }
            newContacts = [...newContacts, contact];
        } else {
            contact[fieldName] = value;
        }
        this.setState({ patient: { ...this.state.patient, contacts: newContacts } })
    }

    handlePatientChangeCustom(value, name) {
        this.setPatientChange(name, value, () => {
            if (name === 'birthDate') {
                if (value && !isNaN(new Date(value))) {
                    let simpleAge = new Date().getFullYear() - value.getFullYear();
                    let age = new Date(value).setFullYear(new Date(value).getFullYear() + simpleAge) > new Date() ? (simpleAge - 1) : simpleAge;
                    this.setPatientChange('age', age);
                }
            }
        })

    }

    handlePatientChangeCheckbox(e) {
        let { name, checked } = e.target;
        switch (name) {
            case 'unknownBirthDate':
                this.setPatientChange(name, checked, () => {
                    if (checked) {
                        this.setPatientChange('birthDate', null);
                    } else {
                        let age = this.state.patient.age || 0;
                        this.setPatientChange('birthDate', new Date(new Date().setFullYear(new Date().getFullYear() - age)));
                    }
                });
                break;
            default:
                this.setPatientChange(e.target.name, e.target.checked);
                break;
        }
    }
    handleSubmitForm(e) {
        e.preventDefault();
        this.props.createPatient(this.state.patient).then(response => {
            if (response && response.data && response.data.success)
                swal("", "Sucess", "success").then(x => {
                    this.props.history.push("/patient")
                })
            //this.setState({ patient: response.data.data });
        });
    }

    handleImportByHNDialog(data) {
        this.setState({ patient: data });
        if (data.nameTH.first && ((!data.nameEN?.first) || (data?.nameTH?.first === data?.nameEN?.first))) {
            this.lazyUpdateRomanize('nameTH.first', data.nameTH.first)
            this.lazyUpdateRomanize('nameTH.last', data.nameTH.last)
        }
        this.props.history.push('/patient/new')
    }

    changeImage(base64) {
        this.setPatientChange('base64Image', base64);
    }

    uploadImageHandler(e) {
        if (e.target.files.length) {
            let ele = e.target;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.changeImage(reader.result.split(',')[1]);
                ele.value = '';
            }, false);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async handleClickPhoneQR() {
        let response = await this.props.getPhotoQRId(this.props.match.params.patientId);
        if (response.data.success)
            this.setState({
                qrDialog: { ...this.state.qrDialog, open: true, qrId: response.data.data }
            })
    }

    async handleClickUseImageFromCard() {
        let cardInfoResponse = await this.props.getCardInfo();
        if (cardInfoResponse && cardInfoResponse.data.success) {
            if(cardInfoResponse.data.success){
                swal({
                    title: "ยืนยันการเปลี่ยนรูปคนไข้",
                    icon: "info",
                    buttons: true,
                    dangerMode: false,
                    content: {
                        element: "img",
                        attributes: {
                          src: `data:image/jpeg;base64,${cardInfoResponse.data.data.base64Image}`
                        },
                      }
                })
                    .then(async (yes) => {
                        if (yes) {
                            this.changeImage(cardInfoResponse.data.data.base64Image);
                            this.props.createPatient(this.state.patient).then(response => {
                                if (response && response.data && response.data.success)
                                    swal("", "Sucess", "success").then(x => {
                                        if(this.props.match.params.patientId==='new'){
                                            let newPatientId = response.data.data.patientId;
                                            this.setPatientChange('patientId', newPatientId)
                                            this.props.history.replace(`/patient/${newPatientId}`)
                                        }
                                    })
                                //this.setState({ patient: response.data.data });
                            });
                        } else {
        
                        }
                    });
            }
        }
    }

    closeQRDialog() {
        this.setState({ qrDialog: { ...this.state.qrDialog, open: false, qrId: '' } });
    }

    render() {
        const { match, patientForm, user, history } = this.props;
        const isNewPatient = match.params.patientId === 'new';

        const groupBy = (list, keyGetter) => {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        }

        let prefixElementTH = []
        for (let [k, v] of groupBy(patientForm?.uiData?.data?.namePrefixesAll ?? [], x => x.group)) {
            prefixElementTH.push(<ListSubheader>{k}</ListSubheader>);
            for (let vv of v.sort((a, b) => a.th.localeCompare(b.th))) {
                prefixElementTH.push(<MenuItem key={vv.id} accessKey={vv.id} value={vv.th}>{vv.th}</MenuItem>);
            }
        }

        let prefixElementEN = []
        for (let [k, v] of groupBy(patientForm?.uiData?.data?.namePrefixesAll ?? [], x => x.groupEn)) {
            prefixElementEN.push(<ListSubheader>{k}</ListSubheader>);
            for (let vv of v.filter((x, i, arr) => i === arr.findIndex(y => y.en === x.en)).sort((a, b) => a.en.localeCompare(b.en))) {
                prefixElementEN.push(<MenuItem key={vv.id} accessKey={vv.id} value={vv.en}>{vv.en}</MenuItem>);
            }
        }

        if (!this.state.patient)
            return <LinearProgress />

        if (patientForm.uiData.isLoading || patientForm.isLoading)
            return <LinearProgress />

        const gotoPacs = (accessionNumber) => {
            history.push('/pacs#' + accessionNumber)
        }
        return (
            <Container>
                {patientForm.isLoading ? <LinearProgress /> : null}
                <QRDialog open={this.state.qrDialog.open} qrId={this.state.qrDialog.qrId} onClose={this.closeQRDialog} onSubmit={this.changeImage} />
                <ImportByHNDialog open={match.params.patientId === 'import'} onClose={() => this.props.history.push('/patient/new')} onSubmit={this.handleImportByHNDialog} />
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tab}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab label="ข้อมูลประวัติคนไข้" />
                        <Tab label="ประวัติด้านครอบครัว" />
                        <Tab label="ข้อมูลผู้ติดต่อฉุกเฉิน" />
                        <Tab label="ที่ทำงาน/ต้นสังกัด" />
                        <Tab label="ประวัดิการแพ้" style={{ color: this.state.patient.allergy && this.state.patient.allergy.length ? 'red' : '' }} />
                        <Tab label={"ประวัติการเข้ารับบริการ" + (this.state.patient.examCount ? ` (${this.state.patient.examCount})` : '')} disabled={isNewPatient} />
                    </Tabs>
                </AppBar>
                <Paper style={{ padding: "1em" }}>
                    <ValidatorForm
                        ref="form"
                        onError={errors => console.log(errors)}
                        onSubmit={this.handleSubmitForm}
                        noValidate autoComplete="off"
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocal}>
                            <TabPanel index={0} value={this.state.tab}>
                                <FormControl component="fieldset" fullWidth style={{ marginBottom: "1em" }}>
                                    <FormLabel component="legend">
                                        <Typography>ข้อมูลส่วนตัว</Typography>
                                    </FormLabel>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={2} >
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    image={this.state.patient.base64Image ? `data:image/jpeg;base64,${this.state.patient.base64Image}` : ("/api/patient/image/" + this.props.match.params.patientId)}
                                                />
                                                <CardActions>
                                                    <Button size="small" color="primary">
                                                        <span style={{ position: 'absolute', left: 0 }}>{this.state.patient.base64Image ? 'เปลี่ยนรูป' : 'อัพโหลด'}</span>
                                                        <input style={{ position: 'absolute', left: 0, opacity: 0 }} type="file" id="file" onChange={this.uploadImageHandler}></input>
                                                    </Button>
                                                    {this.props.patient.patientUI.isCardReaderReady
                                                        ? <Button size="small" color="primary" onClick={this.handleClickUseImageFromCard}>
                                                            บัตรประชาชน
                                                        </Button>
                                                        : null
                                                    }
                                                    <Button size="small" color="primary" onClick={this.handleClickPhoneQR}>
                                                        กล้องโทรศัพท์
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                        <Grid item container xs={10}
                                            alignItems="center"
                                            spacing={2} >
                                            <Grid item xs={2}>
                                                <FormControl fullWidth margin="normal">
                                                    <TextField variant="filled"
                                                        displayEmpty
                                                        label="คำนำหน้าชื่อ"
                                                        select
                                                        value={this.state.patient.nameTH.prefix}
                                                        onChange={this.handlePatientPrefixChange}
                                                        name="nameTH.prefix"
                                                    >
                                                        <MenuItem value={null}>-</MenuItem>
                                                        {prefixElementTH.map(x => x)}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextValidator variant="filled"
                                                    fullWidth
                                                    label="ชื่อ"
                                                    name="nameTH.first"
                                                    value={this.state.patient.nameTH.first}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        required: true
                                                    }}
                                                    validators={['required']}
                                                    errorMessages={['กรุณาระบุ']}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label="นามสกุล"
                                                    name="nameTH.last"
                                                    value={this.state.patient.nameTH.last}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl variant="filled" fullWidth margin="normal">
                                                    <TextField variant="filled"
                                                        displayEmpty
                                                        select
                                                        label="Title"
                                                        value={this.state.patient.nameEN.prefix}
                                                        onChange={this.handlePatientPrefixChange}
                                                        name="nameEN.prefix"
                                                    >
                                                        <MenuItem value={null}>-</MenuItem>
                                                        {prefixElementEN.map(x => x)}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label="Name"
                                                    name="nameEN.first"
                                                    value={this.state.patient.nameEN.first}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label="Surname"
                                                    name="nameEN.last"
                                                    value={this.state.patient.nameEN.last}
                                                    margin="normal"
                                                    onChange={this.handlePatientChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink>เพศ</InputLabel>
                                                <FormControl fullWidth margin="normal">
                                                    <RadioGroup
                                                        row
                                                        name="gender"
                                                        value={this.state.patient?.gender}
                                                        onChange={this.handlePatientChange}
                                                    >
                                                        <FormControlLabel
                                                            value="Male"
                                                            control={<Radio />}
                                                            label="ชาย"
                                                        />
                                                        <FormControlLabel
                                                            value="Female"
                                                            control={<Radio />}
                                                            label="หญิง"
                                                        />
                                                        <FormControlLabel
                                                            value="Unknown"
                                                            control={<Radio />}
                                                            label="ไม่ระบุเพศ"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <KeyboardDatePicker
                                                inputVariant="filled"
                                                format="dd/MM/yyyy"
                                                placeholder="dd/MM/yyyy"
                                                margin="normal"
                                                label="วันเกิด"
                                                value={this.state.patient.birthDate}
                                                onChange={v => this.handlePatientChangeCustom(v, "birthDate")}
                                                validators={['requiredAge']}
                                                errorMessages={['กรุณาระบุวันเกิดหรืออายุ']}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={!!this.state.patient.unknownBirthDate}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.patient.unknownBirthDate}
                                                        name="unknownBirthDate"
                                                        onChange={this.handlePatientChangeCheckbox}
                                                    />
                                                }
                                                label="ไม่ทราบวันเกิด"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextValidator variant="filled"
                                                fullWidth
                                                label="อายุ"
                                                name="age"
                                                value={this.state.patient.age}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                type="number"
                                                validators={['requiredAge']}
                                                errorMessages={['กรุณาระบุวันเกิดหรืออายุ']}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                disabled={!this.state.patient.unknownBirthDate}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                            <Button color="primary" variant="contained" type="button">
                                                คำนวณวันเกิด
                                            </Button>
                                        </Grid> */}
                                        <Grid item xs={2}>
                                            <FormControl variant="filled" fullWidth margin="normal">
                                                <InputLabel shrink variant="filled" htmlFor="bloodGroup">
                                                    กรุ๊ปเลือด
                                                </InputLabel>
                                                <TextField variant="filled"
                                                    displayEmpty
                                                    select
                                                    value={this.state.patient.bloodGroup}
                                                    onChange={this.handlePatientChange}
                                                    name="bloodGroup"
                                                >
                                                    <MenuItem value={null}>-</MenuItem>
                                                    {patientForm.uiData.data.bloodTypes.map(b => (
                                                        <MenuItem key={b.id} value={b.id}>
                                                            {b.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="เลขที่บัตรประชาชน"
                                                name="nationalNumber"
                                                value={this.state.patient.nationalNumber}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="HN"
                                                name="hn"
                                                value={this.state.patient.hn}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="สัญชาติ"
                                                name="nationality"
                                                value={this.state.patient.nationality}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="เชื้อชาติ"
                                                name="race"
                                                value={this.state.patient.race}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="ศาสนา"
                                                name="religion"
                                                value={this.state.patient.religion}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="อาชีพ"
                                                name="occupation"
                                                value={this.state.patient.occupation}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="เลขที่บัตรต่างด้าว"
                                                name="alienNumber"
                                                value={this.state.patient.alienNumber}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="เลขที่หนังสือเดืนทาง"
                                                name="passportNumber"
                                                value={this.state.patient.passportNumber}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="โทรศัพท์"
                                                name="phoneNumber"
                                                value={this.state.patient.phoneNumber}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                <FormControl component="fieldset" fullWidth style={{ marginBottom: "1em", display: 'none' }}>
                                    <FormLabel component="legend">
                                        <Typography>ที่อยู่ปัจจุบัน</Typography>
                                    </FormLabel>
                                    <PatientAddressInput fieldName="currentAddress" address={this.state.patient.currentAddress} onAddressChange={this.handlePatientChange} />
                                </FormControl>
                                <FormControl component="fieldset" fullWidth style={{ display: 'none' }}>
                                    <FormLabel component="legend">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item xs={2}>
                                                <Typography>ที่อยู่ตามทะเบียนบ้าน</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.patient.currentAndRecordAddressIsSame}
                                                            name="currentAndRecordAddressIsSame"
                                                            onChange={this.handlePatientChangeCheckbox}
                                                        />
                                                    }
                                                    label="ตรงกับที่อยู่ปัจจุบัน"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormLabel>
                                    <PatientAddressInput disabled={!!this.state.patient.currentAndRecordAddressIsSame} fieldName="recordAddress" address={!!this.state.patient.currentAndRecordAddressIsSame ? this.state.patient.currentAddress : this.state.patient.recordAddress} onAddressChange={this.handlePatientChange} />
                                </FormControl>
                            </TabPanel>
                            <TabPanel index={1} value={this.state.tab}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    {patientForm.uiData.data.relationship.map(x => {
                                        let z = (this.state.patient.contacts || []).find(y => y.relationship === x.id) || {};
                                        return <React.Fragment key={x.id}>
                                            <Grid item xs={3}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label={`ชื่อ${x.name}`}
                                                    name={`${x.id}.firstName`}
                                                    value={z.firstName}
                                                    margin="normal"
                                                    onChange={this.handlePatientContactChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label="นามสกุล"
                                                    name={`${x.id}.lastName`}
                                                    value={z.lastName}
                                                    margin="normal"
                                                    onChange={this.handlePatientContactChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    label="อาชีพ"
                                                    name={`${x.id}.occupation`}
                                                    value={z.occupation}
                                                    margin="normal"
                                                    onChange={this.handlePatientContactChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    })}
                                </Grid>
                            </TabPanel>
                            <TabPanel index={2} value={this.state.tab}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField variant="filled"
                                            fullWidth
                                            label="ชื่อบุคคลที่ติดต่อได้ในกรณีฉุกเฉิน"
                                            name="emergencyContact.contactName"
                                            value={(this.state.patient.emergencyContact || {}).contactName}
                                            margin="normal"
                                            onChange={this.handlePatientChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField variant="filled"
                                            fullWidth
                                            label="โทรศัพท์"
                                            name="emergencyContact.homePhoneNumber"
                                            value={(this.state.patient.emergencyContact || {}).homePhoneNumber}
                                            margin="normal"
                                            onChange={this.handlePatientChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField variant="filled"
                                            fullWidth
                                            label="โทรศัพท์ที่ทำงาน"
                                            name="emergencyContact.workPhoneNumber"
                                            value={(this.state.patient.emergencyContact || {}).workPhoneNumber}
                                            margin="normal"
                                            onChange={this.handlePatientChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <PatientAddressInput fieldName="emergencyContact.address" address={(this.state.patient.emergencyContact || {}).address} onAddressChange={this.handlePatientChange} />
                                </Grid>
                            </TabPanel>
                            <TabPanel index={3} value={this.state.tab}>
                                <FormControl component="fieldset" fullWidth style={{ marginBottom: "1em" }}>
                                    <FormLabel component="legend">
                                        <Typography>สถานที่ทำงาน</Typography>
                                    </FormLabel>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel shrink>ประเภทงาน</InputLabel>
                                                <FormControl fullWidth margin="normal">
                                                    <RadioGroup
                                                        row
                                                        name="workPlace.workPlaceType"
                                                        value={this.state.patient.workPlace.workPlaceType}
                                                        onChange={this.handlePatientChange}
                                                    >
                                                        <FormControlLabel
                                                            value={null}
                                                            control={<Radio />}
                                                            label="ไม่ระบุ"
                                                        />
                                                        <FormControlLabel
                                                            value="government"
                                                            control={<Radio />}
                                                            label="ข้าราชการ/รัฐวิสาหกิจ"
                                                        />
                                                        <FormControlLabel
                                                            value="company"
                                                            control={<Radio />}
                                                            label="บริษัทเอกชน"
                                                        />
                                                        <FormControlLabel
                                                            value="etc"
                                                            control={<Radio />}
                                                            label="อื่น ๆ"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="ชื่อที่ทำงาน"
                                                name="workPlace.workPlaceName"
                                                value={this.state.patient.workPlace.workPlaceName}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="ตำแหน่งงาน"
                                                name="workPlace.position"
                                                value={this.state.patient.workPlace.position}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField variant="filled"
                                                fullWidth
                                                label="เลนประจำตัวผู้เสียภาษี"
                                                name="workPlace.workPlaceTaxId"
                                                value={this.state.patient.workPlace.workPlaceTaxId}
                                                margin="normal"
                                                onChange={this.handlePatientChange}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel component="legend">
                                        <Typography>ที่อยู่</Typography>
                                    </FormLabel>
                                    <PatientAddressInput fieldName="workPlace.workAddress" address={this.state.patient.workPlace.workAddress} onAddressChange={this.handlePatientChange} />
                                </FormControl>
                            </TabPanel>
                            <TabPanel index={4} value={this.state.tab}>
                                <Autocomplete
                                    value={this.state.patient.allergy || []}
                                    onChange={(event, newValue) => {
                                        this.setPatientChange('allergy', newValue);
                                    }}
                                    multiple
                                    options={patientForm.uiData.data.allergies.map(x => x.id)}
                                    freeSolo
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </React.Fragment>
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField variant="filled"
                                            {...params}
                                            label="ประวัติการแพ้"
                                            placeholder="ระบุเพิ่มเติม"
                                            fullWidth
                                        />
                                    )}
                                />
                            </TabPanel>
                            <TabPanel index={5} value={this.state.tab}>
                                {patientForm.patientExams.isLoading
                                    ? <LinearProgress />
                                    : patientForm.patientExams.isLoaded
                                        ? patientForm.patientExams.data.length == 0
                                            ? <div>-</div>
                                            : <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>สาขา</TableCell>
                                                        <TableCell align="center">วัน/เดือน/ปี</TableCell>
                                                        <TableCell align="center">
                                                            <Table size="small" style={{ minWidth: 555 }}>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 150 }}>Accession</TableCell>}
                                                                        {user.permission['EXAM_ASSIGN'] && <TableCell align="center" style={{ width: 80 }}>Assign</TableCell>}
                                                                        {user.permission['EXAM_DOCUMENT'] && <TableCell align="center" style={{ width: 80 }}>Request</TableCell>}
                                                                        {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 45 }}></TableCell>}
                                                                        {user.permission['EXAM_DICOM'] && <TableCell align="center" style={{ width: 45 }}></TableCell>}
                                                                        {user.permission['EXAM_REPORT'] && <TableCell align="center" style={{ width: 80 }}>Report</TableCell>}
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>

                                                        {user.permission['REQUEST_DOCUMENT'] && <TableCell align="center">Document</TableCell>}
                                                        {user.permission['REQUEST_EDIT'] && <TableCell align="center">Edit</TableCell>}
                                                        {user.permission['REQUEST_INVOICE'] && <TableCell align="center">Invoice</TableCell>}
                                                        {/* {user.permission['REQUEST_EXPORT'] && <TableCell align="center">Export</TableCell>} */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {patientForm.patientExams.data.map(row =>
                                                        <TableRow key={row.requestId} hover>
                                                            <TableCell>{row.branchName}</TableCell>
                                                            <TableCell>{format(new Date(row.appointmentDate), 'dd/MM/yyyy HH:mm')}</TableCell>
                                                            <TableCell><Table size="small" style={{ minWidth: 555 }}>
                                                                <TableBody>
                                                                    {row.exams.map(x =>
                                                                        <TableRow key={x.examId}>
                                                                            {user.permission['EXAM_DICOM'] &&
                                                                                <TableCell style={{ width: 150 }}>
                                                                                    <Chip
                                                                                        avatar={<Avatar>{x.modalityCode}</Avatar>}
                                                                                        label={x.accessionNumber}
                                                                                        onClick={e => user.permission['MENU_PACS'] && gotoPacs(x.accessionNumber)}
                                                                                        variant="outlined"
                                                                                    />
                                                                                </TableCell>
                                                                            }
                                                                            {user.permission['EXAM_ASSIGN'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 8 ? <Check /> : null}</TableCell>}
                                                                            {user.permission['EXAM_DOCUMENT'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 1 ? <Check style={{ cursor: 'pointer' }} onClick={() => { }} /> : null}</TableCell>}
                                                                            {user.permission['EXAM_DICOM'] &&
                                                                                <TableCell padding="none" style={{ cursor: x.status & 4 ? "pointer" : "", width: 55 }} onClick={() => x.status & 4 && window.open('https://dicom-viewer.azurewebsites.net/?manifest=' + encodeURIComponent(document.location.protocol + "//" + document.location.host + "/weasis/" + x.examId + `?token=${this.props.user.jwt}`))} align="center">
                                                                                    {x.status & 4 ? <PhotoIcon /> : null}
                                                                                </TableCell>}
                                                                            {user.permission['EXAM_DICOM'] &&
                                                                                <TableCell align="center" style={{ width: 55 }}>{x.status & 4
                                                                                    ? <a
                                                                                        href={
                                                                                            "weasis://%24dicom%3Aget%20-w%20" +
                                                                                            encodeURIComponent(
                                                                                                document.location.protocol +
                                                                                                "//" +
                                                                                                document.location.host +
                                                                                                "/weasis/" +
                                                                                                x.examId + `?token=${user.jwt}`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <VisibilityIcon />
                                                                                    </a>
                                                                                    : null}
                                                                                </TableCell>}
                                                                            {user.permission['EXAM_REPORT'] && <TableCell align="center" style={{ width: 80 }}>{x.status & 16 ? <Check style={{ cursor: 'pointer' }} onClick={() => { window.open(`/report/${x.examId}?examBranchId=${x.branchId}`); }} /> : null}</TableCell>}                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                            </TableCell>
                                                            {user.permission['REQUEST_DOCUMENT'] &&
                                                                <TableCell
                                                                    align="center"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={_ => history.push(`/request/upload/${row.requestId}`)}
                                                                >
                                                                    {row.missingDocumentNames.length
                                                                        ? <Tooltip placement="top" title={<div style={{ fontSize: 15 }}><b>ต้องการเอกสารเพิ่มเติม</b>{row.missingDocumentNames.map(x => <><br />- {x}</>)}</div>}>
                                                                            <WarningIcon />
                                                                        </Tooltip>
                                                                        : <Check />
                                                                    }
                                                                </TableCell>
                                                            }
                                                            {user.permission['REQUEST_EDIT'] &&
                                                                <TableCell align="center">
                                                                    <EditIcon
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={_ => history.push(`/request/edit/${row.requestId}`)}
                                                                    />
                                                                </TableCell>
                                                            }
                                                            {user.permission['REQUEST_INVOICE'] &&
                                                                <TableCell align="center"
                                                                    // onClick={(e) => onClickInvoice(e, row.requestId)}
                                                                    onClick={_ => row.allowToClickInvoice && row.status != 'Hold'
                                                                        && window.open(`/accounting_report/${row.requestId}`)}
                                                                >
                                                                    <Icon
                                                                        color={row.allowToClickInvoice && row.status != 'Hold' ? '' : 'disabled'}
                                                                        title={!row.allowToClickInvoice ? 'กรุณาระบุเวลาตรวจ' : row.status == 'Hold' ? row.statusRemark : ''}
                                                                        style={{ cursor: "pointer", color: row.isCreatedAccountDocument ? 'rgb(255, 193, 7)' : '' }}
                                                                    >receipt</Icon>
                                                                    <CircularProgress style={{ display: 'none' }} size={18} />
                                                                </TableCell>
                                                            }
                                                            {user.permission['REQUEST_EXPORT'] &&
                                                                <TableCell align="center">

                                                                </TableCell>
                                                            }
                                                            <TableCell style={{
                                                                position: 'absolute',
                                                                border: 0,
                                                                left: 0,
                                                                right: 0,
                                                                textAlign: 'center',
                                                                lineHeight: '45px',
                                                                zIndex: -1
                                                            }}>
                                                                {row.status === 'Hold' ? row.statusRemark : ''}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                </TableBody>
                                            </Table>
                                        : null
                                }
                            </TabPanel>

                            {['importbycard', 'new'].indexOf(this.props.match.params.patientId) < 0
                                ? <>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                        style={{ marginTop: "1em" }}
                                    >
                                        <Grid item xs={4}>
                                            <input type="text" style={{ opacity: 0, height: 0, margin: 0, padding: 0, width: 0 }} />

                                            <span>กรุณากรอกรหัสผ่านของคุณอีกครั้งเพื่อยืนยันการเปลี่ยนแปลงข้อมูลคนไข้</span>
                                            <TextValidator
                                                type="password"
                                                variant="filled"
                                                fullWidth
                                                label="รหัสผ่าน"
                                                name="password"
                                                value={this.state.patient.password}
                                                margin="normal"
                                                onChange={this.handlePatientChange}

                                                InputLabelProps={{
                                                    shrink: true,
                                                    required: true
                                                }}
                                                validators={['required']}
                                                errorMessages={['กรุณาระบุ']}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                                : null}
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                                style={{ marginTop: "1em" }}
                            >
                                <Grid item xs={2}>
                                    <Button fullWidth color="secondary" variant="outlined" onClick={this.props.history.goBack}>
                                        ยกเลิก
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button fullWidth color="primary" variant="contained" type="submit">
                                        บันทึก
                                    </Button>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </ValidatorForm>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        patientForm: state.patient.patientForm,
        patient: state.patient,
        user: state.user
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(Form));
