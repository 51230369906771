import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, withRouter, Switch } from "react-router";
import Layout from "./components/Layout";
import Records from "./components/Records/";
import DicomViewerDialog from "./components/Records/DicomViewerDialog";
import Report from "./components/Reports/";
import Patient from "./components/Patient/";
import Menu from "./components/Menu/";
import Request from "./components/Request/";
import Document from "./components/Document/";
import { actionCreators } from "./store/Menu";
import PatientForm from "./components/Patient/PatientForm";
import Accounting from "./components/Accounting";

export function App({ match, fetchMenu }) {
    useEffect(() => {
        fetchMenu();
    }, []);

    let section = match.params.section;
    return (
        <Layout section={section}>
            <Route path="/viewer/:examId" component={DicomViewerDialog} />
            <Route path={[
                "/report/:examId/:reportName?",
                "/accounting_report/:formId/:reportName?",
                "/branch_report/:branchMode(daily|monthly)/:reportName?",
                "/billing_report/:billingId/:reportName?",
                "/:reportFor(center|doctor)_report/:reportName?"
            ]}
                component={Report}
            />
            <Route path={["/record", "/pacs"]} component={Records} />
            <Route path="/document/" component={Document} />
            <Route path="/patient" exact component={Patient} />
            <Route path="/patient/:patientId" exact component={PatientForm} />
            <Route path="/request/" component={Request} />
            <Route path="/accounting/:page?" component={Accounting} />
            <Route path="/" exact component={Menu} />
        </Layout>
    );
}

export default connect(null, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(App));
