import axios from "axios";

const
    requestUIDataType = 'REQUEST_MONEY_UIDATA',
    receiveUIDataType = 'RECIEVE_MONEY_UIDATA',
    requestAdjustMoneyType = 'REQUEST_MONEY_ADJUST',
    receiveAdjustMoneyType = 'RECEIVE_MONEY_ADJUST'
    ;

export const actionCreators = {
    fetchUIData: () => async (dispatch) => {
        dispatch({ type: requestUIDataType })
        let response = await axios.get('api/uidata/accounting_money');
        dispatch({ type: receiveUIDataType, data: response.data })
    },
    adjustMoney: (remark, items, file) => async (dispatch) => {
        dispatch({ type: requestAdjustMoneyType })
        let form = new FormData()
        form.append('remark', remark)
        for (let i = 0; i < items.length; i++) {
            form.append(`items[${i}].name`, items[i].name)
            form.append(`items[${i}].amount`, items[i].amount)
        }
        form.append('file', file)
        let response = await axios.post('api/accounting/adjustMoney', form);
        dispatch({ type: receiveAdjustMoneyType, data: response.data })
        return response;
    }
}

const initialState = {
    conditionUIData: {
        isLoading: true,
        data: {}
    },
    result:{
        isLoading: false
    }
}

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case requestUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: true,
                    data: {}
                }
            }

        case receiveUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    data: action.data
                }
            }
        case requestAdjustMoneyType:
            return {
                ...state,
                result:{
                    ...state.result,
                    isLoading: true
                }
            }
        case receiveAdjustMoneyType:
            return {
                ...state,
                result:{
                    ...state.result,
                    isLoading: false,
                    ...action.data
                }
            }
    }
    return state;
}