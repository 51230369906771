import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from "../../../store/Billing";
import { format } from 'date-fns'
import { withRouter } from 'react-router'
import {
    CircularProgress,
    LinearProgress,
    Button,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell
} from '@material-ui/core'

import { Done } from '@material-ui/icons'

export function BillingHistory({ billing, fetchHistory, history, match }) {
    useEffect(() => {
    }, []);

    const handleClickHistory = (billingId) => {
        history.push('/accounting/billing/' + billingId)
    }

    const openReceipt = (billingId,receiptId) => {
        window.open(`/api/billing/${billingId}/receipt/${receiptId}/file`)
    }

    if (!billing.billingHistory.isLoading && !billing.billingHistory.isLoaded)
        return null;
    return <Paper style={{ maxHeight: 900, overflowY: 'auto', overflowX: 'hidden' }}>
        {billing.billingHistory.isLoading
            ? <LinearProgress />
            : billing.billingHistory.isLoaded
                ? billing.billingHistory.data.length == 0 ? <div style={{ textAlign: 'center' }}>ไม่พบข้อมูล</div>
                    : billing.billingHistory.data.map(x => <Paper key={x.documentId} style={{ marginBottom: 5 }}>
                        <Button onClick={() => handleClickHistory(x.billingId)} variant="outlined" color="default" fullWidth style={{ padding: 5, margin: 2, backgroundColor: x.isHighlight ? '#ddd' : '', color: x.isAllPaid?'#555':null }}>
                            {x.documentId}<br />
                            {x.privilageName ? <>{x.privilageName}<br /></> : null}
                            {`${format(new Date(x.startDate), 'dd/MM/yyyy')} - ${format(new Date(x.endDate), 'dd/MM/yyyy')}`}
                        </Button>
                        {x.receipts.map(y => <Button key={y.receiptId} style={{fontSize:'small'}} color="secondary" variant="text" onClick={() => openReceipt(x.billingId, y.receiptId)}>{y.receiptDocumentId}</Button>)}
                    </Paper>)
                : null//<div style={{textAlign:'center'}}>กรุณาเลือกโรงพยาบาล</div>
        }
    </Paper>
}
function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(BillingHistory));