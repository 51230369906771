import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Records';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    LinearProgress,
    Grid
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});

class EditDicomDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            informationLoaded: false,
            examInformation: {
                examId: '',
                firstName: '',
                middleName: '',
                lastName: '',
                prefixName: '',
                suffixName: '',
                birthDate: null
            }
        };

        this.handleEnter = this.handleEnter.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCustom = this.handleChangeCustom.bind(this);
    }

    async handleEnter() {
        // Get update exam information.
        let response = await axios.get(`api/exam/${this.props.examId}/getUpdateInformation`);

        if (response.data.success && response.data.data) {
            this.setState({
                informationLoaded: true,
                examInformation: response.data.data
            });
        }
    }

    handleClose() {
        this.setState({
            disabled: false,
            informationLoaded: false,
            examInformation: {
                examId: '',
                firstName: '',
                middleName: '',
                lastName: '',
                prefixName: '',
                suffixName: '',
                birthDate: null
            }
        });
        this.props.handleClose();
    }

    async handleSave() {
        this.setState({ disabled: true });
        await this.props.updateExam(this.props.examId, this.state.examInformation);
        this.handleClose();
    }

    handleChange(e) {
        this.setState({ examInformation: Object.assign({}, this.state.examInformation, { [e.target.name]: e.target.value }) });
    }

    handleChangeCustom(value, conditionName) {
        this.setState({ examInformation: Object.assign({}, this.state.examInformation, { [conditionName]: value }) });
    }

    render() {
        const {
            classes,
            open,
            examId
        } = this.props;

        return (
            <Dialog
                open={open}
                onEnter={this.handleEnter}
                onClose={this.handleClose}
                maxWidth="xs"
                fullWidth={true}
                disableBackdropClick={this.state.disabled}
                disableEscapeKeyDown={this.state.disabled}
            >
                <DialogTitle>Edit Exam Information</DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                label="Exam Id"
                                value={examId}
                                margin="normal"
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="firstName"
                                label="First Name"
                                value={this.state.examInformation.firstName}
                                margin="normal"
                                onChange={this.handleChange}
                                fullWidth={true}
                                disabled={this.state.disabled || !this.state.informationLoaded}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="middleName"
                                label="Middle Name"
                                value={this.state.examInformation.middleName}
                                margin="normal"
                                onChange={this.handleChange}
                                fullWidth={true}
                                disabled={this.state.disabled || !this.state.informationLoaded}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="lastName"
                                label="Last Name"
                                value={this.state.examInformation.lastName}
                                margin="normal"
                                onChange={this.handleChange}
                                fullWidth={true}
                                disabled={this.state.disabled || !this.state.informationLoaded}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="prefixName"
                                label="Prefix Name"
                                value={this.state.examInformation.prefixName}
                                margin="normal"
                                onChange={this.handleChange}
                                fullWidth={true}
                                disabled={this.state.disabled || !this.state.informationLoaded}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="suffixName"
                                label="Suffix Name"
                                value={this.state.examInformation.suffixName}
                                margin="normal"
                                onChange={this.handleChange}
                                fullWidth={true}
                                disabled={this.state.disabled || !this.state.informationLoaded}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    label="Birth Date"
                                    value={this.state.examInformation.birthDate}
                                    onChange={(v) => this.handleChangeCustom(v, "birthDate")}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    fullWidth={true}
                                    disabled={this.state.disabled || !this.state.informationLoaded}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {(this.state.disabled || !this.state.informationLoaded) &&
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} disabled={this.state.disabled || !this.state.informationLoaded}>Cancel</Button>
                    <Button
                        onClick={this.handleSave}
                        disabled={this.state.disabled || !this.state.informationLoaded}
                        color="primary"
                    >
                        Save
                        <SaveIcon color="primary" className={classes.rightIcon} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withStyles(styles)(EditDicomDialog));
