import React,{useState} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import swal from 'sweetalert'
import {
    Grid,
    Button,
    ListSubheader,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from '@material-ui/core';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";


export function ImageSelectorButton({ id,height, imageList, value, onChange,onUploadNewImage }) {

    let [openGallery, setOpenGallery] = useState(false);

        const handleClickGrid = () => {
            setOpenGallery(true);
        }

        const selectImage = (img) => {
            setOpenGallery(false);
            onChange && onChange(img ? img.id : null);
        }
        const currentImgId = imageList.find(x => x.id === value)

        function GalleryDialog(props) {
            const { onClose, value, open, imageList, ...other } = props;
            const radioGroupRef = React.useRef(null);

            const handleCancel = () => {
                onClose();
            };

            const selectImage = (img) => {
                onClose(img)
            }

            const handleUploadImageFromClient = ()=>{
                var input = document.createElement('input');
				input.type = 'file';

				input.onchange = e => {
					var file = e.target.files[0];
					if (file) {
						var formData = new FormData();
						formData.append("file", file);
						axios.post(`/api/request/${id}/reportAttachment`, formData, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
							.then((res) => {
								if (res.data.data)
									onUploadNewImage(res.data.data)
								else swal('', res.data.message, 'info', {
									buttons: false,
									timer: 1000,
								});
							})
							.catch((error) => {
								console.error(error)
							})
					}
				}
				input.click();
            }

            return (
                <Dialog
                    maxWidth="md"
                    open={open}
                    {...other}
                >
                    <DialogTitle>Gallery</DialogTitle>
                    <DialogContent>
                        <ImageList rowHeight={180} cols={3}>
                            <ImageListItem key="Subheader" cols={3} style={{ height: 'auto' }}>
                                <ListSubheader component="div">Select the picture</ListSubheader>
                            </ImageListItem>
                            {imageList.map((item) => (
                                <ImageListItem key={item.img} cols={1}>
                                    <Button style={{ minHeight: 180, height: 180, width: '100%' }} onClick={() => selectImage(item)}>
                                        <img src={item.img} alt={item.title} style={{ height: 180, maxWidth: 200 }} />
                                        <ImageListItemBar
                                            title={item.title}
                                        />
                                    </Button>
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <span style={{cursor:'pointer'}} onClick={handleUploadImageFromClient}><CloudUploadIcon /> Upload from computer</span>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCancel} color="secondary">
                            Remove
                        </Button>
                        <Button autoFocus onClick={() => selectImage(value)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return (<Grid item xs={12} style={{ border: '1px solid #aaaaaa', padding: 0 }}>
            <GalleryDialog open={openGallery} imageList={imageList} onClose={selectImage} value={currentImgId} />
            {currentImgId
                ? <img src={currentImgId.img} style={{ minHeight: height, maxHeight: height, width: '100%' }} onClick={handleClickGrid} />
                : <div style={{ width: '100%', height: height, backgroundColor: '#cccccc' }} onClick={handleClickGrid} />
            }

        </Grid>)
}

export default connect(() => { })(ImageSelectorButton);