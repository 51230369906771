import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { actionCreators } from "../store/Site";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import loginButton from '../resources/line/btn_login_base.png'
import loginButtonHover from '../resources/line/btn_login_hover.png'
import loginButtonPress from '../resources/line/btn_login_press.png'
import lineLogo from '../resources/line/btn_base.png'

class NavHeaderContent extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.fetchSiteConfig();
    }
    render() {
        let { collapsed, user, site } = this.props;
        return (<React.Fragment>
            <div style={{ padding: collapsed ? 8 : 16, transition: "0.3s" }}>
                <div style={{ paddingBottom: 16 }} />
                <Typography variant={"h6"} noWrap>
                    {user.displayName}
                </Typography>
                <Typography color={"textSecondary"} noWrap gutterBottom>
                    {user.emailAddress}
                </Typography>
                <Typography>
                    {user.lineName
                        ? <div>
                            <img src={lineLogo} /> <span>{user.lineName}</span>
                        </div>
                        : site.config.lineLoginDialogUrl
                        ? <img src={loginButton} onClick={() => window.open(site.config.lineLoginDialogUrl)} style={{ cursor: 'pointer' }} onMouseEnter={(e) => e.target.src = loginButtonHover} onMouseOut={(e) => e.target.src = loginButton} onMouseDown={(e) => e.target.src = loginButtonPress} />
                        : null
                    }
                </Typography>
                <Typography color={"textSecondary"} align="right" noWrap gutterBottom>
                    <a href="/Member/LogOn" onClick={(e) => {
                        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";domain=."+window.location.host); });
                        e.target.href = '/Member/LogOn?returnUrl=' + window.location.pathname
                    }}>Log out</a>
                </Typography>
            </div>
            <Divider />
        </React.Fragment>);
    }
}
function mapStateToProps(state) {
    return {
        user: state.user,
        site: state.site
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(NavHeaderContent);
