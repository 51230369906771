import axios from "axios";

const
    requestUIDataType = 'REQUEST_COST_UIDATA',
    receiveUIDataType = 'RECIEVE_COST_UIDATA',
    requestCostSumitType = 'REQUEST_COST_SUBMIT',
    receiveCostSumitType = 'RECEIVE_COST_SUBMIT',
    requestGetReceiptType = 'REQUEST_GET_RECEIPT',
    receiveGetReceiptType = 'RECEIVE_GET_RECEIPT',
    receiveFailResponse = 'COST_RECIEVE_FAIL'
    ;

export const actionCreators = {
    fetchUIData: () => async (dispatch) => {
        dispatch({ type: requestUIDataType })
        let response = await axios.get('api/uidata/accounting_cost');
        dispatch({ type: receiveUIDataType, data: response.data })
        return response
    },
    submitCost: (form, file, date) => async (dispatch) => {
        dispatch({ type: requestCostSumitType })
        let formData = new FormData()
        for(let key of Object.keys(form)){
            formData.append(key, form[key])
        }
        date && formData.append('date', date)
        file && formData.append('file', file)
        let response = await axios.post('api/accounting/submitCost', formData);
        dispatch({ type: receiveCostSumitType, data: response.data })
        return response;
    },
    getTodayCost: (methodId) => async (dispatch) => {
        let response = await axios.get('api/accounting/todayCost/' + methodId);
        return response;
    },
    getReceiptById: (receiptId) => async (dispatch) => {
        dispatch({ type: requestGetReceiptType })
        let response = await axios.get('api/accounting/receipt/' + receiptId);

        if(response?.data?.success)
            dispatch({ type: receiveGetReceiptType, data: response.data.data })
        else dispatch({type:receiveFailResponse})
        return response;
    },
    clearReceipt:() => async (dispatch) => {
        dispatch({ type: receiveGetReceiptType, data: null })
    }
}

const initialState = {
    conditionUIData: {
        isLoading: true,
        data: {
            paymentMethods: []
        }
    },
    result: {
        isLoading: false
    },
    receipt: {
        isLoading: false,
        amount: null
    }
}

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case requestUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: true,
                    data: {}
                }
            }

        case receiveUIDataType:
            return {
                ...state,
                conditionUIData: {
                    ...state.conditionUIData,
                    isLoading: false,
                    data: action.data
                }
            }
        case requestCostSumitType:
            return {
                ...state,
                result: {
                    ...state.result,
                    isLoading: true
                }
            }
        case receiveCostSumitType:
            return {
                ...state,
                result: {
                    ...state.result,
                    isLoading: false,
                    ...action.data
                }
            }
        case requestGetReceiptType:
            return {
                ...state,
                receipt: {
                    ...state.result,
                    isLoading: true,
                    amount: null
                }
            }
        case receiveGetReceiptType:
            return {
                ...state,
                receipt: {
                    ...state.result,
                    isLoading: false,
                    amount: action.data
                }
            }
        case receiveFailResponse:
            return {
                ...state,
                receipt: {
                    ...state.result,
                    isLoading: false,
                }
            }
    }
    return state;
}