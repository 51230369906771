export default class PDFJs {
    init = (source, element) => {
        const iframe = document.createElement('iframe');

        iframe.src = `/ext/pdfjs/pdfjs-2.2.228-dist/web/viewer.html?file=${source}`;
        iframe.width = '100%';
        iframe.height = '100%';

        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
        element.appendChild(iframe);
    }
}