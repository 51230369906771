import axios from "axios";
const requestStatus = 'REQUEST_STATUS';
const receiveStatus = 'RECIEVE_STATUS';
const requestScan = 'REQUEST_SCAN';
const completeScan = 'COMPLETE_SCAN'
const initialState = {
    allowScan: false,
    stationUrl: '',
    isScanning: false
}

export const actionCreators = {
    checkScanner: () => async (dispatch) => {
        dispatch({ type: requestStatus })

        let response = await axios.get('api/uidata/uploader')

        if (response.data.allowScanner && !!response.data.scannerStationUrl) {
            let url = response.data.scannerStationUrl;
            url = url + (url[url.length - 1] == '/' ? '' : '/');
            let stationResponse = await axios.get(`${url}status`);
            if (stationResponse && stationResponse.data && stationResponse.data.ready) {
                dispatch({ type: receiveStatus, data: { allowScan: true, stationUrl: url } });
            }
        }
        else dispatch({ type: receiveStatus, data: { allowScan: false } });
    },
    scan: () => async (dispatch, getState) => {
        dispatch({ type: requestScan })
        let stationResponse = await axios.post(`${getState().uploader.stationUrl}scan`);
        return stationResponse.data.scanId;
    },
    check: (scanId) => async (dispatch, getState) => {
        let stationResponse = await axios.get(`${getState().uploader.stationUrl}check?scanId=${scanId}`);
        if (stationResponse.data.status === 'Completed')
            dispatch({ type: completeScan });
        return stationResponse.data;
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case receiveStatus:
            return { ...state, ...action.data };
        case requestScan:
            return {
                ...state,
                isScanning: true
            };
        case completeScan:
            return {
                ...state,
                isScanning: false
            }
        default:
            return state;
    }
};
