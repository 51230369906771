import React from 'react';
import {bindActionCreators} from 'redux'
import Divider from "@material-ui/core/Divider";
import {Route} from 'react-router'
import {connect} from 'react-redux'

import { Container } from '@material-ui/core';

import { actionCreators } from "../../store/Request";

import RequestSearchCondition from './RequestSearchCondition'
import RequestSearchResult from './RequestSearchResult'
import NewRequest from './NewRequest'
export function Request({request, history}) {
    return (
        <>
            <Route path='/request/:page(new)/:patientId?' component={NewRequest} />
            <Route path='/request/:page(edit|upload)/:requestId' component={NewRequest} />
            <Container>
                {/* <Button color="primary" variant="contained" onClick={()=>history.push('/request/new')} >เพิ่มรายการ</Button> */}
            </Container>
            <RequestSearchCondition />
            <Divider variant="middle" style={{ margin: '10px 0' }} />
            <RequestSearchResult />
        </>
    );
}
function mapStateToProps(state) {
    return {
        request: state.request
    }
}
export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(Request);