import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse'

import { actionCreators } from "../../store/Menu";

const useStyles = makeStyles({
    card: {
        width: '100%'
    },
    media: {
        height: 0,
        paddingTop: '80%'
    }
});

export function Menu({ menu, history }) {

    if (!menu || !menu.menu || menu.menu.length == 0)
        return <LinearProgress />

    let updatedMenu = menu.menu;
    let reportMenu = updatedMenu.find(x => x.permissionCode === 'branch_report')
    if (reportMenu)
        reportMenu.subMenu = [{ permissionCode: 'branch_report/daily', title: 'รายวัน' }, { permissionCode: 'branch_report/monthly', title: 'รายเดือน' }]

    return <MenuItem menu={updatedMenu} history={history} />
}

export function MenuItem({ history, menu }) {

    const clickMenu = (code) => {
        if (code.indexOf('branch_report') >= 0)
            window.open('/' + code)
        else history.push('/' + code)
    }

    if (!menu || menu.length == 0)
        return <LinearProgress />

    return (
        <Box display="flex" justifyContent="space-around" flexWrap="wrap" alignItems="top" spacing={2}>
            <Box display="flex" style={{ width: '100%' }} justifyContent="center">
                <img src="/static/images/logo.png" style={{ width: '30%', maxHeight: 250, padding: 20 }} />
            </Box>

            {menu.map(x => <MenuBox key={x.permissionCode} x={x} onClick={clickMenu} />)}
        </Box>
    );
}

export function MenuBox({ x, onClick }) {

    const classes = useStyles();

    let [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    return <Box
        item key={x.id}
        style={{ width: '21%' }}
    >
        <Card className={classes.card}>
            <CardActionArea onClick={(e) => { x.subMenu ? setIsOpenSubMenu(!isOpenSubMenu) : onClick(x.permissionCode) }}>
                <CardMedia
                    className={classes.media}
                    image={`/static/images/${x.permissionCode}.jpg`}
                    title={x.title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', color: '#0072d2' }}>
                        {x.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Collapse in={isOpenSubMenu} timeout="auto" unmountOnExit>
                <CardContent>
                    <CardActions style={{ justifyContent: 'center', padding:0 }}>
                        {x.subMenu ?
                            x.subMenu.map(y => <Button style={{width:'50%'}} key={y.permissionCode} onClick={(e) => { onClick(y.permissionCode) }} variant="contained" color="primary">
                                {y.title}
                            </Button>)
                            : null}
                    </CardActions>
                </CardContent>
            </Collapse>
        </Card>
    </Box>;
}

function mapStateToProps(state) {
    return {
        menu: state.menu
    }
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(withRouter(Menu));