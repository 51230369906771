import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Patient";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import swal from 'sweetalert'

import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import PatientSearchBox from './PatientSearchBox'
import PatientResult from './PatientResult'
import { CircularProgress, DialogActions, DialogContent, LinearProgress, TextField } from "@material-ui/core";
import { FormatColorReset } from "@material-ui/icons";
const styles = () => ({
    linkButton: {
        "&:hover": {
            color: "white"
        }
    }
});


class Patient extends React.Component {

    async componentDidMount() {
        this.props.changeCondition({ name: '', phoneNumber: '', nationalNumber: '', hn:'' })
        let response = await this.props.getPatientUIData();
        if (response?.data) {
            this.props.getGatewayData();
            this.props.checkCardReader();
        }
        this.searchByCardReader = this.searchByCardReader.bind(this);
    }

    async searchByCardReader() {
        if (!this.props.patient.patientUI.isCardReaderReady) {
            swal({
                title: "ติดตั้งโปรแกรมเพื่ออ่านข้อมูลจากบัตรประชาชน",
                icon: "info",
                buttons: true,
                dangerMode: false,
            })
            .then(async (confirm) => {
                if (confirm) {
                    window.location.href= 'https://ideainvention.blob.core.windows.net/setup/idcardreader/IPAC.IDCardReader.application';
                    let cardCheckInt = setInterval(()=>{
                        if(!this.props.patient.patientUI.isCardReaderReady)
                            this.props.checkCardReader();
                        else
                            clearInterval(cardCheckInt)
                    },1000)
                } else {

                }
            });
            return;
        }
        let cardInfoResponse = await this.props.getCardInfo();
        if (cardInfoResponse && cardInfoResponse.data.success) {
            this.props.search().then(response=>{
                if(response.data.success && response.data.data.length == 0){
                    this.props.history.push('/patient/importbycard')
                }
            })

        }
        // if(cardInfoResponse&& cardInfoResponse.data.success){
        //     let patient = await findPatientByExactNationalId(cardInfoResponse.data.data);
        //     if(patient==null)
        //         ;//goto importbycard page
        //     else
        //         ;//show on table
        // }else{
        //     swal('', patient?.data?.message || 'ไม่สามารถอ่านข้อมูลบนบัตรได้', 'info')
        // }
    }

    render() {
        const { classes, history, patient } = this.props;

        return (
            <Container>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { }}
                    style={{
                        margin: "1em 0px"
                    }}
                    className={classes.linkButton}
                    component={Link}
                    to="/patient/new"
                    disabled={!patient.patientUI.isSearched}
                    title={!patient.patientUI.isSearched ? 'กรุณาค้นหาก่อนสร้างคนไข้' : ''}
                >
                    {!patient.patientUI.isSearched ? 'กรุณาค้นหาก่อนสร้างคนไข้' : 'เพิ่มคนไข้ใหม่'}
                </Button>
                {patient.patientUI.isImportAvailable
                    ? <Button
                        variant="contained"
                        color="default"
                        onClick={() => { }}
                        style={{
                            margin: "1em 5px"
                        }}
                        hidden={false}
                        className={classes.linkButton}
                        component={Link}
                        to="/patient/import"
                        disabled={!patient.patientUI.isSearched}
                        title={!patient.patientUI.isSearched ? 'กรุณาค้นหาก่อนสร้างคนไข้' : ''}
                    >
                        Import
                    </Button>
                    : null
                }
                {patient.patientUI.enableFindPatientByCardReader
                    ? <Button
                        variant="contained"
                        color={ patient.patientUI.isCardReaderReady?'primary':''}
                        onClick={this.searchByCardReader}
                        style={{
                            margin: "1em 5px",
                            color: patient.patientUI.isCardReaderReady?'':'#777'
                        }}
                        hidden={false}
                        className={classes.linkButton}
                        component={Link}
                        // disabled={!patient.patientUI.isCardReaderReady}
                        title={'ค้นหาข้อมูลจากบัตรประชาชน'}
                    >
                        {/* {patient.idCardInfo.isLoading
                        ? <CircularProgress color='secondary' size={16}/>
                        : null
                        } */}
                        <span> ค้นหาข้อมูลจากบัตรประชาชน</span>
                    </Button>
                    : null
                }
                <Paper style={{ padding: "1em" }}>
                    <PatientSearchBox />
                </Paper>

                <Divider variant="middle" style={{ margin: "1em 0px" }} />

                <Paper>
                    <PatientResult />
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return { patient: state.patient };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(
    withStyles(styles)(withRouter(Patient))
);
