import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Collapse from '@material-ui/core/Collapse';
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { startOfDay, endOfToday, subDays } from "date-fns";

import { actionCreators } from "../../store/Records";

const IntervalTime = 300;

class SearchCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                examFromDate: startOfDay(subDays(new Date(), 3)),
                examToDate: startOfDay(new Date()),
                accessionNumber: "",
                hospitalNumber: "",
                patientName: "",
                modalityCode: undefined,
                patientId: "",
                issuerOfPatientId: "",
                referringPhysicianId: "",
                studyDateFrom: null,
                studyDateTo: null,
                studyTimeFrom: null,
                studyTimeTo: null,
                institutionalDepartment: "",
                studyCreated: null,
                sendingApplication: ""
            },
            open: false,
            isAutoRefresh: false,
            timerId: null,
            refreshIn: 10
        };
        this.handleChangeConditionCustom = this.handleChangeConditionCustom.bind(this);
        this.handleChangeCondition = this.handleChangeCondition.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLastNDayClick = this.handleLastNDayClick.bind(this);
        this.onChangeAutoRefreshCheckbox = this.onChangeAutoRefreshCheckbox.bind(this);
    }

    componentDidMount() {
        this.props.fetchSearchConditionUIData();
        this.search(1, this.state.condition);
        // this.onChangeAutoRefreshCheckbox({target:{checked:true}});
        this.props.checkCDDownloader();
        this.props.checkRadiantInstall();
    }

    onChangeAutoRefreshCheckbox(event) {
        this.setState({ isAutoRefresh: event.target.checked });
        if (event.target.checked)
            this.setState({
                timerId: setInterval(() => {
                    this.setState({ refreshIn: this.state.refreshIn - 1 });
                    if (this.state.refreshIn <= 0) {
                        this.search(1, this.state.condition);
                        this.setState({ refreshIn: IntervalTime })
                    }
                }, 1000), refreshIn: IntervalTime, timerStartOn: new Date()
            });
        else if (this.state.timerId) { clearInterval(this.state.timerId); this.setState({ timerId: null }) };
    }

    search(page, condition) {
        this.setState({ refreshIn: IntervalTime })
        this.props.searchRecoards(page, condition);
    }

    handleChangeConditionCustom(value, conditionName) {
        this.setState({
            condition: Object.assign({}, this.state.condition, {
                [conditionName]: value
            })
        });
    }

    handleChangeCondition(e) {
        this.setState({
            condition: Object.assign({}, this.state.condition, {
                [e.target.name]: e.target.value
            })
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.search(1, this.state.condition);
    }

    handleLastNDayClick(day) {
        var newVal = {
            examFromDate: startOfDay(subDays(new Date(), day)),
            examToDate: endOfToday()
        };
        this.setState((state, props) => ({
            condition: { ...state.condition, ...newVal }
        }));
        this.search(1, { ...this.state.condition, ...newVal });
    }

    render() {
        return (
            <Container className="Records">
                <Paper style={{ padding: 10 }}>
                    <form onSubmit={this.handleSubmit}>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel component="legend">
                                <Typography>Search</Typography>
                            </FormLabel>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={6} md={6} lg={2}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            inputVariant="filled"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label="From Date"
                                            value={this.state.condition.examFromDate}
                                            onChange={v => this.handleChangeConditionCustom(v, "examFromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={2}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            inputVariant="filled"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label="To Date"
                                            value={this.state.condition.examToDate}
                                            onChange={v => this.handleChangeConditionCustom(v, "examToDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            autoOk={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <TextField variant="filled"
                                            fullWidth
                                            name="accessionNumber"
                                            label="Accession Number"
                                            value={this.state.condition.accessionNumber}
                                            margin="normal"
                                            onChange={this.handleChangeCondition}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <TextField variant="filled"
                                            fullWidth
                                            name="hospitalNumber"
                                            label="Hospital Number"
                                            value={this.state.condition.hospitalNumber}
                                            margin="normal"
                                            onChange={this.handleChangeCondition}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <TextField variant="filled"
                                            fullWidth
                                            name="patientName"
                                            label="Patient Name"
                                            value={this.state.condition.patientName}
                                            onChange={this.handleChangeCondition}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={2}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel shrink variant="filled" htmlFor="modalityCode">
                                                Modality
                                            </InputLabel>
                                            <TextField variant="filled"
                                                displayEmpty
                                                select
                                                value={this.state.condition.modalityCode}
                                                onChange={this.handleChangeCondition}
                                                name="modalityCode"
                                            >
                                                <MenuItem value={undefined}>
                                                    <em>All</em>
                                                </MenuItem>
                                                {this.props.records.conditionUIData.modality &&
                                                    this.props.records.conditionUIData.modality.map(x => (
                                                        <MenuItem key={x.key} value={x.key}>
                                                            {x.key}: {x.value}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Collapse in={this.state.open} timeout="auto">
                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    name="patientId"
                                                    label="Patient ID"
                                                    value={this.state.condition.patientId}
                                                    onChange={this.handleChangeCondition}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    name="issuerOfPatientId"
                                                    label="Issuer of Patient ID"
                                                    value={this.state.condition.issuerOfPatientId}
                                                    onChange={this.handleChangeCondition}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    name="referringPhysicianId"
                                                    label="Referring Physician ID"
                                                    value={this.state.condition.referringPhysicianId}
                                                    onChange={this.handleChangeCondition}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <KeyboardDatePicker
                                                    inputVariant="filled"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Study Date From"
                                                    value={this.state.condition.studyDateFrom}
                                                    onChange={v => this.handleChangeConditionCustom(v, "studyDateFrom")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <KeyboardDatePicker
                                                    inputVariant="filled"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    label="Study Date To"
                                                    value={this.state.condition.studyDateTo}
                                                    onChange={v => this.handleChangeConditionCustom(v, "studyDateTo")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date"
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <KeyboardTimePicker
                                                    inputVariant="filled"
                                                    margin="normal"
                                                    label="Study Time From"
                                                    value={this.state.condition.studyTimeFrom}
                                                    onChange={v => this.handleChangeConditionCustom(v, "studyTimeFrom")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change time"
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <KeyboardTimePicker
                                                    inputVariant="filled"
                                                    margin="normal"
                                                    label="Study Time To"
                                                    value={this.state.condition.studyTimeTo}
                                                    onChange={v => this.handleChangeConditionCustom(v, "studyTimeTo")}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change time"
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    name="institutionalDepartment"
                                                    label="Institutional Department"
                                                    value={this.state.condition.institutionalDepartment}
                                                    onChange={this.handleChangeCondition}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <TextField variant="filled"
                                                    fullWidth
                                                    name="sendingApplication"
                                                    label="Sending Application"
                                                    value={this.state.condition.sendingApplication}
                                                    onChange={this.handleChangeCondition}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                    <Grid item container xs={12} justifyContent="left">
                                    <Button
                                        variant="text"
                                        color="default"
                                        startIcon={this.state.open ? <ExpandLess /> : <ExpandMore />}
                                        onClick={() => { this.setState({ open: !this.state.open }); }}
                                        style={{fontSize:12}}
                                    >
                                        {this.state.open ? 'Simple Search' : 'Advance Search'}
                                    </Button>
                                    </Grid>
                                    <Grid item container xs={4} justifyContent="left">
                                    <FormControl  component="fieldset" margin="dense" fullWidth>
                                        <FormLabel component="legend">
                                            <Typography>Search for</Typography>
                                        </FormLabel>
                                        <Grid conotainer xs={12} direction="row">
                                            <Button xs={4} color="default" variant="outlined" type="button" onClick={()=>this.handleLastNDayClick(7)}>
                                                7 Days
                                            </Button>
                                            &nbsp;
                                            <Button xs={4} color="default" variant="outlined" type="button" onClick={()=>this.handleLastNDayClick(14)}>
                                                14 Days
                                            </Button>
                                        </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item container xs={4} justifyContent="center">
                                        <Button color="primary" variant="contained" type="submit">
                                            Search
                                        </Button>
                                    </Grid>
                                    <Grid item container xs={4} justifyContent="right">
                                        
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </FormControl>
                    </form>
                    <div style={{ height: 32, textAlign: 'right' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.isAutoRefresh}
                                onChange={this.onChangeAutoRefreshCheckbox}
                                color="primary"
                            />
                        }
                        label={"Auto Refresh?" + (this.state.isAutoRefresh ? ` (${this.state.refreshIn})` : '')}
                    />
                </div>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.records
    };
}

export default connect(mapStateToProps, dispatch => bindActionCreators(actionCreators, dispatch))(SearchCondition);
